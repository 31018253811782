import React from "react";
import PropTypes from "prop-types";
import { BoxField } from "../../../component/box";
import { Typography } from "@material-ui/core";
import { dateTimeOptions } from "../../../util/dates";
import { useLocale, useTranslate } from "react-admin";

const BookingCardPayment = ({ classes, currencyFormatter, record }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const { cancellation, checkIn = {}, paymentCardNumber, paymentMethod, purchasedAt, purchaseTotal } = record;
    const { amount, amountWithCancellation } = checkIn;
    const total = purchaseTotal || (cancellation ? amountWithCancellation : amount);
    const purchased = new Date(Date.parse(purchasedAt));
    const formatter = new Intl.DateTimeFormat(locale, dateTimeOptions);

    return (
        <BoxField fullWidth label={
            <Typography color="textSecondary" variant="subtitle2">
                {translate("booking.message.paymentData")}
            </Typography>
        }>
            {
                paymentMethod === "card" ? (
                    <Typography className={classes.bold} variant="body1">
                        {translate("booking.message.cardNumber", {
                            ...record,
                            paymentCardNumber: paymentCardNumber.substr(0, 4)
                                + " **** **** " + paymentCardNumber.substr(-4),
                        })}
                    </Typography>
                ) : paymentMethod === "voucher" ? (
                    <Typography className={classes.bold} variant="body1">
                        {translate("booking.message.voucherNumber", { ...record })}
                    </Typography>
                ) : (
                    <Typography className={classes.bold} variant="body1">
                        {translate("booking.message.cash", { ...record })}
                    </Typography>
                )
            }
            {
                purchasedAt && (
                    <Typography gutterBottom variant="body2">
                        {translate("booking.message.purchasedAt", {
                            date: formatter.format(purchased),
                        })}
                    </Typography>
                )
            }
            <Typography variant="h5">
                {currencyFormatter.format(total)}
            </Typography>
        </BoxField>
    );
};

BookingCardPayment.propTypes = {
    classes: PropTypes.object,
    currencyFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardPayment.defaultProps = {
    classes: {},
};

export default BookingCardPayment;
