import React from "react";
import PropTypes from "prop-types";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import get from "lodash/get";
import { Tooltip } from "@material-ui/core";
import { useRecordContext, useTranslate } from "react-admin";

const WarningField = ({ source, tooltip }) => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (! record) {
        return null;
    }

    const value = get(record, source);
    const warning = get(record, tooltip);

    if (! value) {
        return null;
    }

    return (
        <Tooltip title={translate(warning)}>
            <WarningIcon color={"error"} fontSize={"small"} />
        </Tooltip>
    );
};

WarningField.propTypes = {
    addLabel: PropTypes.bool,
    label: PropTypes.string,
    source: PropTypes.string,
    tooltip: PropTypes.string,
};

WarningField.defaultProps = {
    addLabel: true,
    tooltip: "",
};

export default WarningField;
