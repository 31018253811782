import React from "react";
import OrderStatus from "../../order/OrderStatus";
import PropTypes from "prop-types";
import { BookingCheckInButton, BookingCheckOutButton } from "../button";
import { Box, CardContent, Typography } from "@material-ui/core";
import { useLocale, useTranslate } from "react-admin";
import { dateTimeOptions } from "../../../util/dates";
import { isCheckInPossible, isCheckOutPossible } from "../bookingUtils";

const BookingCardState = ({ classes, record }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const {
        cancelledAt,
        checkInExpiresAt,
        checkOutAt,
        checkedOutAt,
        droppedAt,
        expiredAt,
        refundedAt,
        status,
        updatedAt,
    } = record;

    const renderState = (prop, primaryText, secondaryText) => {
        const date = dateTimeFormatter.format(new Date(Date.parse(prop)));

        return (
            <CardContent>
                <Box alignItems="center" className={classes.warning} display="flex">
                    <Typography className={classes.warning} variant="body2">
                        <span className={classes.bold}>
                            {translate(primaryText, { date })}
                        </span>
                        {secondaryText && "—"}
                        {secondaryText && translate(secondaryText, { date })}
                    </Typography>
                </Box>
            </CardContent>
        );
    };

    if (status === OrderStatus.CHECKED_OUT) {
        return renderState(checkedOutAt,
            "booking.state.checkedOut",
            "booking.message.noMoreActionsAvailable"
        );
    }

    if (status === OrderStatus.EXPIRED) {
        return renderState(expiredAt,
            "booking.state.expired",
            "booking.message.noMoreActionsAvailable"
        );
    }

    if (status === OrderStatus.REFUNDED) {
        return renderState(refundedAt,
            "booking.state.refunded",
            "booking.message.noMoreActionsAvailable"
        );
    }

    if (status === OrderStatus.CANCELLED) {
        return renderState(cancelledAt,
            "booking.state.cancelled",
            "booking.message.noMoreActionsAvailable"
        );
    }

    if (status === OrderStatus.DROPPED) {
        if (isCheckInPossible(record)) {
            // Dropped, but check-in still possible
            const dropped = new Date(Date.parse(droppedAt));
            const checkInLimit = new Date(Date.parse(checkInExpiresAt));

            return (
                <CardContent>
                    <Box alignItems="center" className={classes.warning} display="flex">
                        <Typography className={classes.warning} variant="body2">
                            <span className={classes.bold}>
                                {translate("booking.state.dropped", {
                                    date: dateTimeFormatter.format(dropped),
                                })}
                            </span>
                            {"—"}
                            {translate("booking.message.stillCheckable", {
                                limit: dateTimeFormatter.format(checkInLimit),
                            })}
                        </Typography>
                        <BookingCheckInButton
                            record={record}
                            style={{ marginLeft: "auto" }}
                            variant="contained"
                        />
                    </Box>
                </CardContent>
            );
        }

        return renderState(droppedAt,
            "booking.state.dropped",
            "booking.message.noMoreActionsAvailable"
        );
    }

    if (isCheckOutPossible(record)) {
        const checkOutLimit = new Date(Date.parse(checkOutAt) + 3600000);

        return (
            <CardContent>
                <Box alignItems="center" className={classes.warning} display="flex">
                    <Typography className={classes.warning} variant="body2">
                        <span className={classes.bold}>
                            {translate("booking.state.pendingCheckOut")}
                        </span>
                        {"—"}
                        {translate("booking.message.checkOutUntil", {
                            date: dateTimeFormatter.format(checkOutLimit),
                        })}
                    </Typography>
                    <BookingCheckOutButton
                        record={record}
                        style={{ marginLeft: "auto" }}
                        variant="contained"
                    />
                </Box>
            </CardContent>
        );
    }

    if (isCheckInPossible(record)) {
        const checkInLimit = new Date(Date.parse(checkInExpiresAt));

        return (
            <CardContent>
                <Box alignItems="center" className={classes.warning} display="flex">
                    <Typography className={classes.warning} variant="body2">
                        <span className={classes.bold}>
                            {translate("booking.state.pendingCheckIn")}
                        </span>
                        {"—"}
                        {translate("booking.message.checkInUntil", {
                            date: dateTimeFormatter.format(checkInLimit),
                        })}
                    </Typography>
                    <BookingCheckInButton
                        record={record}
                        style={{ marginLeft: "auto" }}
                        variant="contained"
                    />
                </Box>
            </CardContent>
        );
    }

    return renderState(updatedAt,
        "booking.state.unknown",
        "booking.message.noMoreActionsAvailable"
    );
};

BookingCardState.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardState.defaultProps = {
    classes: {},
};

export default BookingCardState;
