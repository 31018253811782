import React from "react";
import BookingShowActions from "./BookingShowActions";
import { BookingCard, BookingCardSkeleton } from "./card";
import { ShowBase, useShowContext } from "react-admin";

const BookingShowContent = props => {
    const { record, loaded } = useShowContext();

    return (
        <>
            <BookingShowActions {...props} />
            {
                loaded ? (
                    <BookingCard record={record} {...props} />
                ) : (
                    <BookingCardSkeleton {...props} />
                )
            }
        </>
    );
};

const BookingShow = props => (
    <ShowBase
        {...props}
        basePath="/booking"
        resource="booking"
    >
        <BookingShowContent />
    </ShowBase>
);

export default BookingShow;
