import React from "react";
import ProfileShowButton from "./ProfileShowButton";
import PropTypes from "prop-types";
import { TopToolbar } from "react-admin";

const ProfileEditActions = ({ basePath }) => (
    <TopToolbar>
        <ProfileShowButton basePath={basePath}  />
    </TopToolbar>
);

ProfileEditActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

ProfileEditActions.defaultProps = {
    data: {},
};

export default ProfileEditActions;
