import ProfileIcon from '@material-ui/icons/Person';
import ProfileShow from "./ProfileShow";
import ProfileEdit from "./ProfileEdit";

const profile = {
    edit: ProfileEdit,
    show: ProfileShow,
    icon: ProfileIcon
}

export default profile;
