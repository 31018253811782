import React from "react";
import IncidentSeverityField from "./IncidentSeverityField";
import { SimpleList, useLocale } from "react-admin";
import { dateTimeOptions } from "../../util/dates";

const ProductSimpleList = props => {
    const locale = useLocale();
    const formatter = new Intl.DateTimeFormat(locale, dateTimeOptions);

    return (
        <SimpleList
            primaryText={record => record.message}
            secondaryText={record => (<IncidentSeverityField record={record} />)}
            tertiaryText={record => formatter.format(new Date(Date.parse(record.occurredAt))) }
            {...props}
        />
    );
};

export default ProductSimpleList;
