const message = {
    ra: {
        action: {
            add: "Add",
            add_accomodation: "Add accomodation",
            add_filter: "Add filter",
            add_passenger: "Add passenger",
            back: "Back",
            bulk_actions: "1 item selected |||| %{smart_count} items selected",
            call: "Call",
            callTo: "Call (%{carrier}, %{country})",
            cancel: "Cancel",
            change_view: "View",
            clear_input_value: "Clear value",
            clone: "Clone",
            close: "Close",
            close_menu: "Close menu",
            confirm: "Confirm",
            create: "Create",
            delete: "Delete",
            edit: "Edit",
            expand: "Expand",
            export: "Export",
            import: "Import",
            lock: "Lock",
            link: "Link",
            list: "List",
            move: "Move",
            next: "Next",
            open_menu: "Open menu",
            prev: "Previous",
            profile: "Profile",
            refresh: "Refresh",
            remove: "Remove",
            remove_filter: "Remove this filter",
            renew: "Renew",
            report_incident: "Report an incident",
            save: "Save",
            save_and_add: "Save and add",
            search: "Search",
            settings: "Settings",
            show: "Show",
            signIn: "Sign-in",
            signUp: "Sign-Up",
            sort: "Sort",
            submit: "Submit",
            today: "Today",
            toggle_theme: "Toggle theme",
            undo: "Undo",
            unselect: "Unselect",
        },
        auth: {
            auth_check_error: "Please sign-in to continue",
            check: "Please sign-in to continue",
            error: "Authentication failed, please retry",
            logout: "Sign-Out",
            password: "Password",
            sign_in: "Sign-In",
            sign_in_error: "Authentication failed, please retry",
            user_menu: "Profile",
            username: "Username",
        },
        boolean: {
            true: "Yes",
            false: "No",
            null: " ",
        },
        group: {
            customer: "Customers",
            management: "Management",
            warehouse: "Warehouse",
        },
        input: {
            file: {
                upload_several: "Drop some files to upload, or click to select one.",
                upload_single: "Drop a file to upload, or click to select it.",
            },
            image: {
                upload_several: "Drop some pictures to upload, or click to select one.",
                upload_single: "Drop a picture to upload, or click to select it.",
            },
            references: {
                all_missing: "Unable to find references data.",
                many_missing: "At least one of the associated references no longer appears to be available.",
                single_missing: "Associated reference no longer appears to be available.",
            },
            password: {
                toggle_visible: "Hide password",
                toggle_hidden: "Show password",
            },
        },
        menu: {
            booking: "Booking",
            calendar: "Calendars",
            customer: "Customers",
            identity: "Identities",
            incident: "Incidents",
            order: "Orders",
            product: "Products",
            settings: "Settings",
            stock: "Stock",
        },
        message: {
            about: "About",
            are_you_sure: "Are you sure?",
            bulk_delete_content: "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
            bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
            darkTheme: "Dark",
            delete_content: "Are you sure you want to delete this item?",
            delete_title: "Delete %{name} #%{id}",
            details: "Details",
            error: "A client error occurred and your request couldn't be completed.",
            invalid_form: "The form is not valid. Please check for errors",
            lightTheme: "Light",
            locale: "Locale",
            loading: "The page is loading, just a moment please",
            never: "Never",
            no: "No",
            not_found: "Either you typed a wrong URL, or you followed a bad link",
            theme: "Theme",
            unsaved_changes: "Some of your changes weren't saved. Are you sure you want to ignore them?",
            yes: "Yes",
        },
        navigation: {
            no_results: "No results found",
            no_more_results: "The page number %{page} is out of boundaries. Try the previous page.",
            page_out_of_boundaries: "Page number %{page} out of boundaries",
            page_out_from_end: "Cannot go after last page",
            page_out_from_begin: "Cannot go before page 1",
            page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
            page_rows_per_page: "Rows per page:",
            next: "Next",
            prev: "Prev",
            skip_nav: "Skip to content",
        },
        notification: {
            updated: "Element updated |||| %{smart_count} elements updated",
            created: "Element created",
            deleted: "Element deleted |||| %{smart_count} elements deleted",
            bad_item: "Incorrect element",
            item_doesnt_exist: "Element does not exist",
            http_error: "Server communication error",
            data_provider_error: "dataProvider error. Check the console for details.",
            i18n_error: "Cannot load the translations for the specified language",
            canceled: "Action cancelled",
            logged_out: "Your session has ended, please reconnect.",
        },
        page: {
            create: "Create %{name}",
            dashboard: "Dashboard",
            edit: "%{name} #%{id}",
            empty: "No %{name} yet.",
            error: "Something went wrong",
            invite: "Do you want to add one?",
            list: "%{name}",
            loading: "Loading",
            not_found: "Not Found",
            settings: "Settings",
            show: "%{name} #%{id}",
        },
        signIn: {
            invalid: "Invalid username/password",
            missingToken: "Missing session token",
            password: "Password",
            success: "Hello again, %{sub}!",
            username: "Username",
        },
        sort: {
            sort_by: "Sort by %{field} %{order}",
            ASC: "ascending",
            DESC: "descending",
        },
        validation: {
            email: "This is not a valid email address",
            expiry: "This is not a valid expiration date",
            idCard: "This is not a valid card number",
            maxLength: "Maximum length is %{min} characters",
            maxPassengers: "Maximum number of passengers allowed is %{max}",
            minLength: "Minimum length is %{min} characters",
            minAge: "Minimum age is %{min}",
            minPassengers: "Minimum number of passengers is %{min}",
            missingInstructor: "Missing instructor accomodation",
            phoneNumber: "This is not a valid phone number",
            required: "This field is required",
            securityCode: "This is not a valid CVV/CVV2 (security code)",
            unavailability: "Invalid range",
            voucherNumber: "This is not a valid voucher number",
        },
        wizard: {
            failure: "Failed to process form",
            step: "Step %{step}",
            success: "Form processed successfully",
        },
    },
    accomodation: {
        label: {
            companion: "Companion",
            driver: "Driver",
            instructor: "This ski-jet is reserved for the instructor",
            stock: "Select asset",
            driverType: "Driver type",
        },
    },
    booking: {
        action: {
            back: "Calendar",
            cancel: "Cancel",
            change: "Change",
            checkin: "Check-in",
            checkout: "Check-out",
            copyFromCustomer: "Copy from customer",
            drop: "Drop",
            list: "List",
        },
        drop: {
            customerRequest: "Dropped at customer request",
            customerDidNotShowUp: "Customer did not show up",
            other: "Other",
        },
        cancel: {
            customerRequest: "Cancelled at customer request",
            other: "Other",
        },
        change: {
            customerRequest: "Changed at customer request",
            weather: "Changed by weather conditions",
            stock: "Stock not available",
            other: "Other",
        },
        events: {
            cancelled: "Booking was cancelled: %{cancellationCause}",
            checkInEmailSent: "Check-in confirmation email sent",
            customerCheckIn: "Customer check-in: %{numPassengers} passengers",
            confirmationEmailSent: "Confirmation email sent",
            changed: "Booking was changed to %{checkInAtFormatted} due to %{changeCause}. Previous check-in was %{previousCheckInAtFormatted}",
            checkedIn: "Booking checked-in",
            checkedOut: "Booking checked-out",
            dropped: "Booking was dropped: %{dropCause}",
            expired: "Booking expired",
            invoiced: "Invoice %{invoiceNumber} generated",
            purchased: "Booking purchased: %{paymentMethod}",
            refunded: "Booking refunded",
            staffCheckIn: "Order was checked-in by %{checkedInBy}: %{numPassengers} passengers",
            staffCheckOut: "Order was checked-out by %{checkedInBy}",
            started: "Booking process started",
        },
        label: {
            cancelledAt: "Cancellation date and time",
            cancellation: "Cancellation",
            cancellationCause: "Cancellation cause",
            cancellationNotes: "Cancellation notes",
            changeTo: "Change to",
            changeToDate: "Change to date",
            changeToTime: "Change to time",
            changeCause: "Change cause",
            changeNotes: "Change notes",
            checkInAt: "Check-in at",
            checkOutNotes: "Notes",
            date: "Date",
            droppedAt: "Drop date and time",
            dropCause: "Drop cause",
            dropNotes: "Drop notes",
            invoice: "Invoice",
            product: "Product",
        },
        message: {
            accomodationData: "Accomodations",
            accomodationSaved: "Accomodation saved",
            age: "Aged %{age}",
            cancelFailure: "Failed to cancel: %{message}",
            cancelModal: "Cancellation details",
            cancelSuccess: "Cancelled successfully",
            cancellableUntil: "Cancellable until %{date}",
            cancellation: "Cancellation Policy",
            cardNumber: "Card %{paymentCardIssuer} %{paymentCardNumber}",
            cash: "Cash",
            changeFailure: "Failed to change: %{message}",
            changeModal: "Change details",
            changeSuccess: "Changed successfully",
            changeableUntil: "Changeable until %{date}",
            changes: "Change Policy",
            checkedInAt: "Checked-in at %{date} by %{actor}",
            checkInClosed: "Check-in is closed",
            checkInUntil: "Check-in closes at %{date}",
            checkinFailure: "Failed to check-in: %{message}",
            checkinModal: "Check-in details",
            checkinSuccess: "Checked-in successfully",
            checkOutUntil: "Check-out closes at %{date}",
            checkoutFailure: "Failed to check-out: %{message}",
            checkoutModal: "Check-out details",
            checkoutSuccess: "Checked-out successfully",
            conditions: "Booking Conditions",
            copyFromCustomer: "Copy data from customer",
            customerData: "Customer Data",
            downloadInvoice: "Download invoice",
            dropFailure: "Failed to drop: %{message}",
            dropModal: "Drop details",
            dropSuccess: "Dropped successfully",
            dropUntil: "Dropable until %{date}",
            duration: "%{duration} min",
            durationRange: "%{minDuration}-%{maxDuration} min",
            history: "History",
            holder: "Booking holder",
            instructor: "Reserved for instructor",
            nextEmpty: "No bookings scheduled at short-term. Click to create a new booking",
            nextFailure: "Failed to retrieve next bookings",
            nextTitle: "Next bookings",
            noCheckInAvailable: "No bookings available on %{date}",
            noMoreActionsAvailable: "No more actions available",
            noProductsAvailable: "No products available on %{date}. Maybe no calendar defined?",
            notCancellable: "Cancellation not allowed",
            notChangeable: "Changes not allowed",
            numPassengers: "%{numPassengers} passenger(s)",
            paymentData: "Payment Data",
            passengerData: "Passenger Data",
            passengerRemoved: "Passenger removed",
            passengerSaved: "Passenger saved",
            pendingCheckIn: "Pending check-in. Check-in limit is %{date}",
            productData: "Product Data",
            productsError: "Could not retrieve products",
            purchasedAt: "Purchased at %{date}",
            queryError: "Could not retrieve check-ins",
            requiresInstructor: "Booking requires instructor",
            stillCheckable: "Still may be checked until %{limit}",
            stockNotAvailable: "Cannot fetch stock: %{message}",
            voucherNumber: "Voucher %{voucherNumber}",
            withCancellation: "w/cancellation",
        },
        state: {
            cancelled: "Cancelled at %{date}",
            checkedOut: "Checked-out at %{date}",
            dropped: "Dropped at %{date}",
            expired: "Expired at %{date}",
            pendingCheckIn: "Pending check-in",
            pendingCheckOut: "Pending check-out",
            refunded: "Cancelled and refunded at %{date}",
        },
        step: {
            one: "Booking Data",
            two: "Customer Data",
            three: "Payment Data",
            four: "Confirmation",
        },
    },
    calendar: {
        empty: {
            holidays: "No days marked as holidays. All days available for booking.",
        },
        error: {
            fetchEvents: "Could not load calendar events",
        },
        help: {
            closed: "If calendar is marked as closed, no further bookings will be accepted this month. This does not affect previous existing bookings, if any.",
            expirationTime: "Bookings are automatically expired once this time has passed, counting from the check-in time.",
            holidays: "No further bookings will be accepted these days. This does not affect previous existing bookings, if any.",
            interServiceTime: "Time to keep between consecutive bookings, in minutes. This is mainly used to prepare assets for next booking.",
            workday: "No bookings will be accepted before first check-in time, nor after last check-in time.",
        },
        label: {
            closed: "Closed",
            expirationTime: "Expiration time (min)",
            firstCheckInAt: "First check-in at",
            holidays: "Holidays",
            interServiceTime: "Time between services (min)",
            ist: "Rest time",
            lastCheckInAt: "Last check-in at",
            month: "Month",
            products: "Available products",
            title: "Booking Calendar",
            year: "Year",
        },
        message: {
            booking: "Bookings",
            calendar: "Calendar",
            closed: "Service is closed",
            instance: "Calendar",
            list: "Calendar List",
            new: "New Calendar",
            open: "Service is open",
            products: "Products",
            schedule: "Schedule",
            workday: "Workday",
        },
        view: {
            dayGridDay: "Grid daily",
            dayGridMonth: "Grid monthly",
            dayGridWeek: "Grid weekly",
            listDay: "List daily",
            listMonth: "List monthly",
            listWeek: "List weekly",
            timeGridDay: "Time grid daily",
            timeGridWeek: "Time grid weekly",
        },
    },
    credentials: {
        label: {
            bindToDevice: "Bind to device",
            expireAt: "Expires at",
            notBefore: "Not before",
            source: "Source",
            type: "Type",
            uuid: "UUID",
        },
        type: {
            accessKey: "Access key",
            biometric: "Biometric",
            oneTimePassword: "One-time password",
            password: "Password",
            publicKey: "Public key",
            secretKey: "Secret key",
        },
    },
    customer: {
        label: {
            condition: "Condition",
            dateOfBirth: "Date of birth",
            emailAddress: "Email address",
            firstName: "First name",
            idCardNumber: "ID card number",
            idCardType: "ID card type",
            lastName: "Last name",
            lastOrderAt: "Last order",
            licenseNumber: "License number",
            notes: "Notes",
            numOrders: "Orders",
            numOrdersCancelled: "Cancelled",
            numOrdersChanged: "Changed",
            numOrdersChecked: "Checked",
            numOrdersDropped: "Dropped",
            orders: "Orders",
            phoneNumbers: "Phone numbers",
            stats: "Stats",
        },
        message: {
            list: "Customers",
            instance: "%{firstName} %{lastName}",
            lastUsedAt: "Last used at %{date}",
        },
    },
    event: {
        label: {
            date: "Date",
            notes: "Notes",
            unavailable: "Stock is unavailable",
            unavailableFrom: "Unavailable from",
            unavailableUntil: "Unavailable until",
        },
        message: {
            period: "Stock won't be available from %{unavailableFrom} to %{unavailableUntil}.",
            unavailability: "Stock won't be available for further bookings during the unavailability period.",
        },
    },
    identity: {
        action: {
            credentials: "Credentials",
        },
        label: {
            active: "Active",
            canonicalEmail: "Canonical Email Address",
            canonicalIdentifier: "Canonical Identifier",
            disabled: "Disabled",
            email: "Email Address",
            identifier: "Identifier",
            lastSignInAt: "Last sign-in",
            roles: "Roles",
        },
        message: {
            credentialsFailure: "Failed to generate new credentials",
            credentialsModal: "Generate new credentials",
            credentialsSuccess: "New credentialss generated successfully. A link was sent by email",
            instance: "%{identity}",
            list: "Identities",
        },
        tab: {
            credentials: "Credentials",
            devices: "Devices",
            profile: "Profile",
        },
    },
    incident: {
        label: {
            actor: "Actor",
            app: "App ID",
            clientInfo: "Client Info",
            data: "Data",
            date: "Date",
            did: "Device Identifier",
            emailAddress: "Email Address",
            fp: "Fingerprint",
            ip: "IP Address",
            ips: "All IP addresses (including forwards)",
            locator: "Locator",
            message: "Message",
            number: "Number",
            o: "Web Origin",
            occurredAt: "Occurred At",
            offendingOrigin: "Offending Origin",
            offendingToken: "Offending Token",
            severity: "Severity",
            status: "Status",
            title: "Type",
            ua: "User-Agent",
        },
        message: {
            instance: "Incident %{uuid}",
            list: "Incidents",
            new: "New Incident",
            noData: "No data recorded",
        },
        severity: {
            critical: "Critical",
            high: "High",
            low: "Low",
            medium: "Medium",
        },
    },
    order: {
        label: {
            checkInAt: "Check-in",
            emailAddress: "Email",
            locator: "Locator",
            phoneNumber: "Phone Number",
            warning: "Warning",
        },
        message: {
            calendar: "May be out of the calendar range",
            list: "Orders",
            unavailable: "May be affected by stock unavailability",
        },
        status: {
            cancelled: "Cancelled",
            checkedIn: "Checked",
            checkedOut: "Complete",
            dropped: "Dropped",
            expired: "Expired",
            pendingCheckIn: "Pending",
            refunded: "Refunded",
        },
        warning: {
            "stock-unavailable": "Stock unavailable",
            "calendar-changed": "Calendar changed",
        },
    },
    passenger: {
        condition: {
            domestic: "Domestic",
            foreign: "Foreign",
            resident: "Resident",
        },
        help: {
            condition: "Customer must present its ID card to verify claimed condition",
            emailAddress: "Please, check the email address, as is the main communication channel with the customer.",
        },
        label: {
            address: "Address",
            age: "Age",
            condition: "Condition",
            dateOfBirth: "Date of birth",
            emailAddress: "Email address",
            firstName: "First name",
            idCardNumber: "ID Card Number",
            idCardType: "ID Card Type",
            instructor: "Instructor",
            lastName: "Last name",
            licenseNumber: "License number",
            locality: "Locality",
            phoneNumber: "Phone Number",
            postalCode: "Postal Code",
            type: "Type",
        },
        message: {
            driver: "Add driver",
            companion: "Add companion",
        },
        type: {
            instructor: "Instructor",
            licensed: "Licensed",
            minor: "Minor",
            unlicensed: "Unlicensed",
        },
    },
    payment: {
        label: {
            cardExpiry: "Payment card expiry",
            cardExpiryMonth: "Payment card expiry month",
            cardExpiryYear: "Payment card expiry year",
            cardIssuer: "Payment card issuer",
            cardNumber: "Payment card number",
            cardSecurityCode: "Payment card security code (CVV/CVV2)",
            method: "Payment method",
            voucherNumber: "Voucher number",
        },
        help: {
            allowRedeemVoucher: "Selected product does allow to redeem a voucher",
            disallowRedeemVoucher: "Selected product does not allow to redeem a voucher",
        },
        method: {
            card: "Card",
            cash: "Cash",
            voucher: "Voucher",
        },
    },
    product: {
        help: {
            additionalTaxes: "Additional taxes",
            cancellationFee: "This amount is not refundable",
            cancellationLimit: "Hours before check-in time. Cancellation won't be allowed after this point",
            changeLimit: "Hours before check-in time. No changes will be allowed after this point",
            drop: "Drop a file or click to select one",
            onlyIfFavorableWeather: "Booking requires favorable weather conditions",
            onlyOnDemand: "Booking requires pre-arrangement by phone",
            optimize: "Please consider using an image optimizer",
            portTaxes: "Per passenger and course",
            poster: "Up to %{maxSize}. Accepted image types are %{accept}",
        },
        label: {
            additionalTaxes: "Additional taxes",
            allowRedeemVoucher: "Voucher",
            cancellable: "Cancellable",
            cancellationFee: "Cancellation fee",
            cancellationLimit: "Cancellation limit",
            changeable: "Changeable",
            changeLimit: "Change limit",
            distance: "Distance (approx.)",
            domesticFee: "Domestic",
            foreignFee: "Foreign",
            includesLifeJacket: "Life-jackets",
            includesBasicNavigation: "Navigation briefing",
            includesLockerRoom: "Locker room",
            instructorRequired: "Instructor required",
            label: "Label",
            language: "Language",
            maxDuration: "Max. duration (min)",
            maxPassengers: "Max. passengers",
            minDuration: "Min. duration (min)",
            minPassengers: "Min. passengers",
            numPaddles: "Number of paddles",
            numSkiJets: "Number of ski-jets",
            onlyIfFavorableWeather: "Only if favorable weather",
            onlyOnDemand: "Only on demand",
            picturesFee: "Pictures",
            portTaxes: "Port taxes",
            posterHiRes: "Poster (high resolution, to be used on tablet/desktop)",
            posterLoRes: "Poster (low resolution, to be used on mobile devices)",
            ref: "Reference",
            refillRequired: "Re-fill required",
            residentFee: "Resident",
            taxes: "Taxes",
        },
        message: {
            cancellation: "Booking Cancellation",
            changes: "Booking Changes",
            conditions: "Booking Conditions",
            descriptions: "Internationalized product descriptions",
            details: "Booking Details",
            extras: "Booking Extras",
            fees: "Booking Fees",
            instance: "Product %{ref}",
            labels: "Internationalized product labels",
            list: "Products list",
            new: "New Product",
            passengers: "Booking Passengers",
            posters: "Product images",
            taxes: "Taxes",
            vouchers: "Vouchers",
        },
        tab: {
            conditions: "Conditions",
            cancellation: "Cancellation",
            changes: "Changes",
            descriptions: "Description",
            fees: "Fees",
            labels: "Labels",
            poster: "Poster",
        },
    },
    profile: {
        label: {
            avatar: "Avatar",
            dateOfBirth: "Date of birth",
            firstName: "Fist name",
            lastName: "Last name",
            title: "Profile",
        },
        message: {
            avatar: "Avatar",
            details: "Personal Details",
        },
    },
    stock: {
        action: {
            delete: "Delete event",
            event: "Add event",
        },
        label: {
            available: "Available",
            brand: "Brand",
            minAge: "Min. age",
            model: "Model",
            plateNumber: "Plate number",
            requiresDrivingLicense: "Requires driving license",
            sku: "SKU",
            type: "Type",
        },
        message: {
            available: "Stock is currently available",
            details: "Details",
            eventDeleteFaileure: "Failed to delete event: %{message}",
            eventDeleteSuccess: "Event removed successfully",
            eventFailed: "Failed to add event: %{message}",
            eventModal: "Event details",
            eventSuccess: "Event added successfully",
            instance: "Stock %{sku}",
            list: "Stock List",
            new: "New Stock",
            noEvents: "No events registered",
            sku: "SKU",
            unavailable: "Stock is currently not available",
            unavailableShort: "Currently not available",
        },
        tab: {
            details: "Details",
            events: "Events",
        },
        type: {
            paddle: "Paddle",
            skiJet: "Ski-jet",
        },
    },
};

export default message;
