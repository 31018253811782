import React from "react";
import ProductForm from "./ProductForm";
import ProductTitle from "./ProductTitle";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {
    active: true,
    allowRedeemVoucher: true,
    cancellable: true,
    changeable: true,
    includesBasicNavigation: true,
    includesLifeJacket: true,
    includesLockerRoom: true,
    instructorRequired: true,
    taxes: 5,
    portTaxes: 1,
    additionalTaxes: 0,
    distance: 20,
    ref: "ref",
    residentFee: 80,
    domesticFee: 90,
    foreignFee: 100,
    picturesFee: 5,
    maxDuration: 90,
    maxPassengers: 2,
    minDuration: 60,
    minPassengers: 1,
    numPaddles: 0,
    numSkiJets: 1,
    onlyIfFavorableWeather: true,
    labels: [
        { lang: "de", label: "DE" },
        { lang: "en", label: "EN" },
        { lang: "es", label: "ES" },
        { lang: "fr", label: "FR" },
        { lang: "it", label: "IT" },
        { lang: "pt", label: "PT" },
        { lang: "ru", label: "RU" },
    ],
    descriptions: [
        { lang: "de", label: "DE" },
        { lang: "en", label: "EN" },
        { lang: "es", label: "ES" },
        { lang: "fr", label: "FR" },
        { lang: "it", label: "IT" },
        { lang: "pt", label: "PT" },
        { lang: "ru", label: "RU" },
    ],
};

const ProductCreate = (props) => (
    <Create
        actions={<CreateActions />}
        title={<ProductTitle />}
        {...props}
    >
        <ProductForm initialValues={initialValues} />
    </Create>
);

export default ProductCreate;
