import CustomerIcon from '@material-ui/icons/Person';
import CustomerCreate from './CustomerCreate';
import CustomerList from "./CustomerList";
import CustomerShow from "./CustomerShow";
import CustomerEdit from "./CustomerEdit";

const customer = {
    create: CustomerCreate,
    edit: CustomerEdit,
    icon: CustomerIcon,
    list: CustomerList,
    show: CustomerShow,
}

export default customer;
