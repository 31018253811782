import config from "../../config";
import fingerprintProvider from "../fingerprintProvider";
import { HttpError } from "react-admin";

const KEY_JWT = "adminSessionToken";

const replaceUnauthorizedStatus = status => status === 401 ? 403 : status;

const customDataProvider = () => {
    const { serviceURL, accessToken, negotiation: { accept } } = config;

    const isFile = item => item && typeof item === "object" && typeof item.rawFile === "object";

    const ingest = async (file) => {
        const body = new FormData();
        body.append("file", file);

        const options = {};
        await buildHeaders(options);
        const { headers } = options;

        // DO NOT set the Content-Type request header.
        // User-agent will take care
        const response = await fetch(`${serviceURL}/ingest/`, {
            body,
            headers,
            method: "PUT",
            mode: "cors",
        });

        if ([200, 201, 202, 204].indexOf(response.status) === -1) {
            // Ingest operation failed
            throw new HttpError(response.statusText, response.status, await response.json());
        }

        const src = response.headers.get("Content-Location")
                 || response.headers.get("Location");

        if (! src) {
            // Could not retrieve the location for the ingested resource
            throw new HttpError("Could not retrieve the location for the ingested resource", response.status, await response.json());
        }

        return serviceURL + src;
    };

    const ingestAnyFile = async (data) => {
        for (const key of Object.keys(data)) {
            const item = data[key];

            if (isFile(item)) {
                data[key] = await ingest(item.rawFile);
            } else if (item && typeof item === "object") {
                await ingestAnyFile(item);
            }
        }
    };

    const serialize = async (data) => {
        await ingestAnyFile(data);

        return JSON.stringify(data);
    };

    const buildQuery = params => {
        const { extra, filter, ids, pagination, sort } = params;
        const filters = [];
        const q = new URLSearchParams();

        if (filter) {
            for (const key of Object.keys(filter)) {
                const f = filter[key];

                if (Array.isArray(f)) {
                    filters.push(`eq:${key},${f.join(",")}`);
                } else if (typeof f === "object") {
                    for (const op of Object.keys(f)) {
                        const s = f[op];

                        if (Array.isArray(f)) {
                            filters.push(`${op}:${key},${s.join(",")}`);
                        } else {
                            filters.push(`${op}:${key},${s.toString()}`);
                        }
                    }
                } else {
                    filters.push(`eq:${key},${f}`);
                }
            }
        }

        if (ids) {
            filters.push(`in:id,${ids.join(",")}`);
        }

        if (filters.length) {
            q.set("filter", filters.join(";"));
        }

        if (pagination) {
            const { page, perPage } = pagination;

            if (1 < page && perPage) {
                q.set("cursor", (page - 1) * perPage + "," + perPage);
            } else if (perPage) {
                q.set("cursor", "," + perPage);
            }
        }

        if (extra) {
            for (const key of Object.keys(extra)) {
                const val = extra[key];

                if (typeof val !== "undefined") {
                    q.set(key, val.toString());
                }
            }
        }

        if (sort) {
            const { field, order } = sort;

            q.set("sort", (order && order.toUpperCase() === "DESC" ? "-" : "") + field);
        }

        const query = q.toString();

        if (! query) {
            return "";
        }

        return "?" + btoa(query);
    };

    const buildURL = (resource, id, path, params) => {
        const parts = [serviceURL, resource];
        
        if (id) {
            parts.push(id);
        } else if (! path) {
            parts.push("");
        }
        
        if (path) {
            parts.push(path);
            
            if (! params) {
                parts.push("");
            }
        }

        let url = parts.join("/");
        
        if (params) {
            url += buildQuery(params);
        }
        
        return url;
    };

    const buildHeaders = async (options) => {
        const jwt = localStorage.getItem(KEY_JWT);
        const fingerprint = await fingerprintProvider.getFingerprint();
        const headers = {
            "Origin-Token": accessToken,
        };

        if (jwt) {
            headers.Authorization = `Bearer ${jwt}`;
        }

        if (fingerprint) {
            headers.Fingerprint = fingerprint;
        }

        if (! options.headers) {
            options.headers = headers;
        } else {
            options.headers = { ...options.headers, ...headers };
        }
    };

    const parseLink = link => {
        const token = link.split(";").shift();
        const tokens = token.substr(1, token.length - 2).split("/");

        const id = tokens.pop();
        const resource = tokens.pop();

        return { id, resource };
    };

    const request = async (url, options) => {
        await buildHeaders(options);

        return await fetch(url, options);
    };

    const getOne = async (resource, params) => {
        const { id, path } = params;
        const response = await request(buildURL(resource, id, path), {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": accept,
            },
        });

        if (200 !== response.status) {
            throw new HttpError(response.statusText, response.status);
        }

        let data = null;

        try {
            data = await response.json();
        } catch (e) {
        }

        return { data };
    };

    const getList = async (resource, params) => {
        const { path } = params;
        const response = await request(buildURL(resource, null, path, params), {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": accept,
            },
        });

        let data = null;

        try {
            data = await response.json();
        } catch (e) {
        }

        if (200 !== response.status &&
            204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status), data);
        }

        if (204 === response.status) {
            return { data: [], total : 0 };
        }

        return data;
    };

    const getMany = async (resource, params) => {
        const { path } = params;
        const response = await request(buildURL(resource, null, path, params), {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": accept,
            },
        });

        let data = null;

        try {
            data = await response.json();
        } catch (e) {
        }

        if (200 !== response.status &&
            204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status), data);
        }

        return data;
    };

    const getManyReference = async (resource, params) => {
        const { id, path, target } = params;
        const response = await request(buildURL(resource, null, path, { ...params, filter: { [target]: id } }), {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": accept,
            },
        });

        let data = null;

        try {
            data = await response.json();
        } catch (e) {
        }

        if (200 !== response.status &&
            204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status), data);
        }

        return data;
    };

    const create = async (resource, params) => {
        const { data, path } = params;
        const body = await serialize(data);
        const response = await request(buildURL(resource === "order" ? "booking" : resource, null, path), {
            body,
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (200 !== response.status &&
            204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status));
        }

        const link = response.headers.get("Content-Location");

        if (! link) {
            // Missing Content-Location response header
            throw new HttpError("Missing Content-Location response header", response.status, data);
        }

        const { resource: newResource, id } = parseLink(link);

        return await getOne(newResource, { id });
    };

    const update = async (resource, params) => {
        const { id, data, path, skipGet } = params;
        const body = await serialize(data);
        const response = await request(buildURL(resource, id, path, params), {
            body,
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (200 !== response.status &&
            202 !== response.status &&
            204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status));
        }

        const link = response.headers.get("Location");

        if (! link) {
            // Missing Location response header
            throw new HttpError("Missing Location response header", replaceUnauthorizedStatus(response.status));
        }

        if (skipGet) {
            return { ...data };
        }

        const { id: newId, resource: newResource } = parseLink(link);

        return await getOne(newResource, { id: newId });
    };

    const updateMany = async (resource, params) => {
        const { ids } = params;

        for (const id of ids) {
            await update(resource, { id, skipGet: true });
        }
    };

    const _delete = async (resource, params) => {
        const { id, path } = params;
        const response = await request(buildURL(resource, id, path, params), {
            method: "DELETE",
            mode: "cors",
        });

        if (204 !== response.status) {
            throw new HttpError(response.statusText, replaceUnauthorizedStatus(response.status));
        }

        return {};
    };
    
    const deleteMany = async (resource, params) => {
        const { ids } = params;

        for (const id of ids) {
            await _delete(resource, { id });
        }

        return {};
    };

    return {
        getOne,
        getList,
        getMany,
        getManyReference,
        create,
        update,
        updateMany,
        delete: _delete,
        deleteMany,
    };
};

export default customDataProvider;
