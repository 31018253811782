const localizedLabel = (labels, locale) => {
    if (! labels || ! Array.isArray(labels) || ! labels.length) {
        return "";
    }

    for (const label of labels) {
        if (locale === label.lang) {
            return label.label;
        }
    }

    return labels[0].label;
};

const getProductDescription = (record, locale) => record ? localizedLabel(record.descriptions, locale) : null;
const getProductLabel = (record, locale) => record ? localizedLabel(record.labels, locale) : null;

export {
    getProductDescription,
    getProductLabel,
}
