import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";

const useStyles = makeStyles({
    label: {
        paddingLeft: "0.5em",
    },
});

const ProfileEditButton = ({ basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const to = basePath + "/edit";

    return (
        <Button
            component={Link}
            color="primary"
            size="small"
            to={to}
        >
            <EditIcon />
            <span className={classes.label}>
                {translate("ra.action.edit")}
            </span>
        </Button>
    );
};

ProfileEditButton.propTypes = {
    basePath: PropTypes.string,
};

ProfileEditButton.defaultProps = {
    basePath: "/profile",
};

export default ProfileEditButton;
