import React from "react";
import { BooleanField, Datagrid, TextField, useTranslate } from "react-admin";

const StockDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid {...props}>
            <BooleanField
                label={translate("stock.label.available")}
                source="available"
            />
            <TextField
                label={translate("stock.label.plateNumber")}
                source="plateNumber"
            />
            <TextField
                label={translate("stock.label.sku")}
                source="sku"
            />
            <TextField
                label={translate("stock.label.type")}
                source="type"
            />
            <TextField
                label={translate("stock.label.brand")}
                source="brand"
            />
            <TextField
                label={translate("stock.label.model")}
                source="model"
            />
        </Datagrid>
    );
};

export default StockDatagrid;
