import React from "react";
import PropTypes from "prop-types";
import { SelectInput } from "react-admin";
import { paymentChoices, paymentNoVoucherChoices } from "./paymentChoices";

const PaymentMethodInput = ({ allowRedeemVoucher, label, source, ...props }) => (
    <SelectInput
        label={label}
        source={source}
        choices={allowRedeemVoucher ? paymentChoices : paymentNoVoucherChoices}
        {...props}
    />
);

PaymentMethodInput.propTypes = {
    allowRedeemVoucher: PropTypes.bool,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
};

PaymentMethodInput.defaultProps = {
    allowRedeemVoucher: false,
    source: "paymentMethod",
};

export default PaymentMethodInput;
