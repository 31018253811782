import React, { cloneElement } from "react";
import CalendarDateDisplay from "./CalendarDateDisplay";
import CalendarNextButton from "./CalendarNextButton";
import CalendarPrevButton from "./CalendarPrevButton";
import CalendarTodayButton from "./CalendarTodayButton";
import CalendarViewButton from "./CalendarViewButton";
import PropTypes from "prop-types";
import { calendarViews } from "./calendarUtil";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
    CreateButton,
    ExportButton,
    RefreshButton,
    TopToolbar,
    sanitizeListRestProps,
    useListContext,
    useTranslate,
} from "react-admin";

const useStyles = makeStyles(theme => ({
    navigation: {
        alignItems: "center",
        display: "flex",
        marginRight: "auto",
    },
    create: {
        marginLeft: theme.spacing(2),
    },
}));

const CalendarActions = props => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();

    const {
        calendar,
        className,
        date,
        exporter,
        filters,
        locale,
        maxResults,
        save,
        views,
        ...rest
    } = props;

    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        //selectedIds, (unused)
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar
            className={className}
            {...sanitizeListRestProps(rest)}
        >
            <div className={classes.navigation}>
                {
                    ! isSmall && (
                        <CalendarTodayButton
                            calendar={calendar}
                            label={translate("ra.action.today")}
                        />
                    )
                }
                <CalendarPrevButton
                    calendar={calendar}
                    label={translate("ra.action.prev")}
                />
                <CalendarNextButton
                    calendar={calendar}
                    label={translate("ra.action.next")}
                />
                {
                    isSmall ? null : (
                        <CalendarDateDisplay
                            calendar={calendar}
                            date={date}
                            locale={locale}
                            total={total}
                        />
                    )
                }
            </div>
            <CalendarViewButton
                calendar={calendar}
                label={translate("ra.action.change_view")}
                save={save}
                views={views}
            />
            {
                ! isSmall && (
                    <RefreshButton/>
                )
            }
            {
                filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })
            }
            <ExportButton
                disabled={total === 0}
                filterValues={filterValues}
                maxResults={maxResults}
                resource={resource}
                sort={currentSort}
            />
            {
                hasCreate && <CreateButton
                    className={classes.create}
                    color="primary"
                    basePath={basePath}
                    variant="contained"
                />
            }
        </TopToolbar>
    );
};

CalendarActions.propTypes = {
    basePath: PropTypes.string,
    calendar: PropTypes.object,
    data: PropTypes.object,
    date: PropTypes.object,
    save: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["session", "local"])]),
    views: PropTypes.arrayOf(PropTypes.oneOf(calendarViews)),
};

CalendarActions.defaultProps = {
    data: {},
};

export default CalendarActions;
