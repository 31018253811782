import React from "react";
import PropTypes from "prop-types";
import { BoxField } from "../../component/box";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useRecordContext, useTranslate } from "react-admin";

const IncidentDataField = ({ classes }) => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (! record) {
        return null;
    }

    const { data } = record;
    const tokens = JSON.parse(data);

    if (! data) {
        return null;
    }

    return (
        <>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" variant="subtitle2">
                    {translate("incident.label.data")}
                </Typography>
            </Grid>
            {
                Object.keys(tokens).map(key => {
                    const value = tokens[key];

                    return (
                        <Grid key={key} item xs={12} md={6}>
                            <BoxField fullWidth label={
                                <Typography color="textSecondary" variant="body2">
                                    {translate("incident.label." + key)}
                                </Typography>
                            }>
                                <Typography color="textPrimary" variant="body2">
                                    {typeof value === "object" ? JSON.stringify(value) : value}
                                </Typography>
                            </BoxField>
                        </Grid>
                    );
                })
            }
        </>
    )
};

IncidentDataField.propTypes = {
    classes: PropTypes.object,
};

IncidentDataField.defaultProps = {
    classes: {},
};

export default IncidentDataField;
