import React from "react";
import ProfileShowActions from "./ProfileShowActions";
import ProfileShowToolbar from "./ProfileShowToolbar";
import ProfileTitle from "./ProfileTitle";
import { Show, SimpleShowLayout, ImageField, TextField, useTranslate } from "react-admin";

const ProfileShow = ({ staticContext, ...props }) => {
    const translate = useTranslate();

    // Note: As this component isn't loaded from a route generated by a
    // React-Admin <Resource>, you have to provide the id, resource, and
    // basepath props yourself (They're hardcoded here).

    // Note: the provided "id" prop should actually match your user id.

    return (
        <Show
            id=""
            resource="profile"
            basePath="/profile"
            actions={<ProfileShowActions />}
            title={<ProfileTitle />}
            {...props}
        >
            <SimpleShowLayout
                toolbar={<ProfileShowToolbar />}
            >
                <ImageField
                    source="avatar"
                    label={translate("profile.label.avatar")}
                    title={translate("profile.label.avatar")}
                />
                <TextField
                    source="firstName"
                    label={translate("profile.label.firstName")}
                />
                <TextField
                    source="lastName"
                    label={translate("profile.label.lastName")}
                />
            </SimpleShowLayout>
        </Show>
    );
};

export default ProfileShow;
