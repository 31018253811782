import { CHANGE_THEME } from "./customActions";

const themeReducer = (previousState = "light", { type, payload }) => {
    if (type === CHANGE_THEME) {
        return payload;
    }

    return previousState;
};

export default themeReducer;
