import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Chip, Typography, makeStyles } from "@material-ui/core";
import { useLocale, useRecordContext } from "react-admin";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5),
        cursor: "inherit",
    },
}));

const CalendarHolidaysField = ({ className, emptyText, ...props }) => {
    const classes = useStyles();
    const locale = useLocale();
    const record = useRecordContext();
    const formatter = new Intl.DateTimeFormat(locale, { day: "numeric", weekday: "short" });

    if (! record) {
        return null;
    }

    const { month, year, holidays } = record;

    return record && Array.isArray(holidays) && holidays.length ? (
        <div>
            {
                holidays.map(holiday => (
                    <Chip
                        key={holiday}
                        className={classnames(classes.chip, className)}
                        label={formatter.format(new Date(year, month, holiday))}
                    />
                ))
            }
        </div>
    ) : (
        <Typography color="textPrimary" component="span" variant="body2" {...props}>
            {emptyText}
        </Typography>
    );
};

CalendarHolidaysField.propTypes = {
    addLabel: PropTypes.bool,
    className: PropTypes.string,
    emptyText: PropTypes.string,
    label: PropTypes.string,
};

CalendarHolidaysField.defaultProps = {
    addLabel: true,
};

export default CalendarHolidaysField;
