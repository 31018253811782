import React from "react";
import { useTranslate } from "react-admin";

const ProfileTitle = ({ record }) => {
    const translate = useTranslate();

    return (
        <span>{translate("profile.label.title")}</span>
    );
}

export default ProfileTitle;
