import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    wrap: {
        display: "block",
    },
});

const WizardFormStep = ({ children }) => {
    const classes = useStyles();

    if (! children) {
        return null;
    }

    return (
        React.Children.map(children, (child, i) => (
            <div className={classnames("ra-input", classes.wrap)} key={i}>
                {child}
            </div>
        ))
    );
}

WizardFormStep.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    values: PropTypes.any,
};

export default WizardFormStep;
