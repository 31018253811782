import OrderStatus from "../order/OrderStatus";

const getColorForStatus = status => {
    switch (status) {
        case OrderStatus.CANCELLED:
        case OrderStatus.REFUNDED:
            return "#ed6c02";
        case OrderStatus.DROPPED:
        case OrderStatus.EXPIRED:
            return "#d32f2f";
        case OrderStatus.CHECKED_IN:
            return "#2e7d32";
        case OrderStatus.CHECKED_OUT:
            return "rgba(208,208,208)";
        case OrderStatus.PENDING_CHECK_IN:
        default:
            return "#3788d8";
    }
};

const getTextColorForStatus = status => status === OrderStatus.CHECKED_OUT ? "#222" : "#fff";

export {
    getColorForStatus,
    getTextColorForStatus,
}
