import React from "react";
import { Grid } from "@material-ui/core";
import { DateInput, SimpleForm, TextInput, required, email, maxLength, useTranslate } from "react-admin";
import { PassengerConditionInput } from "../passenger";
import { dateOptions } from "../../util/dates";
import { idCard, minAge } from "../../util/validation";

const validate = [required()];
const validateName = [required(), maxLength(128)];
const validateEmailAddress = [required(), email(), maxLength(128)];
const validateIdCard = [required(), idCard()];
const validateAge = [required(), minAge(18)];

const CustomerForm = props => {
    const translate = useTranslate();

    return (
        <SimpleForm {...props}>
            <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={translate("customer.label.emailAddress")}
                        source="emailAddress"
                        validate={validateEmailAddress}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("customer.label.firstName")}
                        source="firstName"
                        validate={validateName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("customer.label.lastName")}
                        source="lastName"
                        validate={validateName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("customer.label.idCardNumber")}
                        source="idCardNumber"
                        validate={validateIdCard}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PassengerConditionInput
                        fullWidth
                        label={translate("customer.label.condition")}
                        source="condition"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateInput
                        fullWidth
                        label={translate("customer.label.dateOfBirth")}
                        source="dateOfBirth"
                        options={dateOptions}
                        validate={validateAge}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("customer.label.licenseNumber")}
                        source="licenseNumber"
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default CustomerForm;
