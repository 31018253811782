import React from "react";
import StockShowActions from "./StockShowActions";
import StockTitle from "./StockTitle";
import StockTypeField from "./StockTypeField";
import { BooleanField, FunctionField, Labeled, Show, Tab, TabbedShowLayout, TextField, useTranslate } from "react-admin";
import { Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import StockEventsField from "./StockEventsField";

const useStyles = makeStyles(theme => ({
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const CalendarShow = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Show
            actions={<StockShowActions {...props} />}
            title={<StockTitle />}
            {...props}
        >
            <TabbedShowLayout syncWithLocation>
                <Tab label={translate("stock.tab.details")}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <Typography color="textSecondary" variant="subtitle2">
                                {translate("stock.message.sku")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <Typography color="textSecondary" variant="subtitle2">
                                {translate("stock.label.type")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField component="span" source="sku" variant="h5" />
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <StockTypeField component="span" source="type" variant="h5" />
                        </Grid>
                        <Grid item xs={12}>
                            <FunctionField render={
                                (record) => record && (
                                    <Box display="flex" alignItems="center">
                                        {
                                            record.currentlyAvailable ? (
                                                <Typography component="p" gutterBottom variant="body2">
                                                    {translate("stock.message.available")}
                                                </Typography>
                                            ) : (
                                                <Typography component="p" gutterBottom variant="body2">
                                                    <Box
                                                        bgcolor="#c70000"
                                                        borderRadius={8}
                                                        marginRight={1}
                                                        component="span"
                                                        display="inline-block"
                                                        height={12}
                                                        width={12}
                                                    />
                                                    {translate("stock.message.unavailable")}
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                )
                            } />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" variant="subtitle2">
                                {translate("stock.message.details")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Labeled label={translate("stock.label.brand")}>
                                <TextField source="brand" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Labeled label={translate("stock.label.model")}>
                                <TextField source="model" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Labeled label={translate("stock.label.plateNumber")}>
                                <TextField source="plateNumber" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Labeled label={translate("stock.label.minAge")}>
                                <TextField source="minAge" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12}>
                            <Labeled label={translate("stock.label.requiresDrivingLicense")}>
                                <BooleanField source="requiresDrivingLicense" />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Tab>
                <Tab label={translate("stock.tab.events")}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StockEventsField emptyText={translate("stock.message.noEvents")} />
                        </Grid>
                    </Grid>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default CalendarShow;
