import React from "react";
import { Admin, Resource } from "react-admin";

// Dashboard
import Dashboard from "./component/Dashboard";
import CustomLayout from "./component/CustomLayout";
import SignOn from "./component/SignOn";
import { GeoProvider } from "./provider/geo";
import { FingerprintProvider } from "./provider/fingerprint";

// Providers
import authProvider from "./provider/authProvider";
import dataProvider from "./provider/dataProvider";
import fingerprintProvider from "./provider/fingerprintProvider";
import i18nProvider from "./provider/i18nProvider";

// Resources
import booking from "./resource/booking";
import calendar from "./resource/calendar";
import customer from "./resource/customer";
import identity from "./resource/identity";
import incident from "./resource/incident";
import order from "./resource/order";
import product from "./resource/product";
import stock from "./resource/stock";

// Custom routes
import customRoutes from "./config/customRoutes";
import themeReducer from "./config/themeReducer";

const App = () => (
    <FingerprintProvider fingerprintProvider={fingerprintProvider}>
        <GeoProvider>
            <Admin
                layout={CustomLayout}
                dashboard={Dashboard}
                authProvider={authProvider}
                dataProvider={dataProvider}
                i18nProvider={i18nProvider}
                customReducers={{ theme: themeReducer }}
                customRoutes={customRoutes}
                loginPage={SignOn}
            >
                <Resource name="booking" {...booking} />
                <Resource name="calendar" {...calendar} />
                <Resource name="customer" {...customer} />
                <Resource name="identity" {...identity} />
                <Resource name="incident" {...incident} />
                <Resource name="order" {...order} />
                <Resource name="product" {...product} />
                <Resource name="stock" {...stock} />
                <Resource name="profile" />
            </Admin>
        </GeoProvider>
    </FingerprintProvider>
);

export default App;
