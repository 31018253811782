import IdentityCreate from './IdentityCreate';
import IdentityIcon from '@material-ui/icons/AccountCircle';
import IdentityList from "./IdentityList";
import IdentityShow from "./IdentityShow";
import IdentityEdit from "./IdentityEdit";

const identity = {
    create: IdentityCreate,
    edit: IdentityEdit,
    list: IdentityList,
    show: IdentityShow,
    icon: IdentityIcon,
}

export default identity;
