import React from "react";
import IncidentClientInfoField from "./InclidentClientInfoField";
import IncidentDataField from "./InclidentDataField";
import IncidentSeverityField from "./IncidentSeverityField";
import IncidentShowActions from "./IncidentShowActions";
import { Avatar, Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { BoxField } from "../../component/box";
import { DateField, FunctionField, Show, SimpleShowLayout, TextField, useTranslate } from "react-admin";
import { dateTimeOptions } from "../../util/dates";

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%",
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const IncidentShow = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Show
            actions={<IncidentShowActions {...props} />}
            {...props}
        >
            <SimpleShowLayout>
                <Grid className={classes.grid} container spacing={1}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <FunctionField render={
                                record => record.severity && (
                                    <Avatar className={classes.avatar}>
                                        {record.severity.substr(0, 2).toUpperCase()}
                                    </Avatar>
                                )
                            } />
                            <div>
                                <TextField
                                    component="p"
                                    source="title"
                                    variant="h5"
                                />
                                <DateField
                                    color="textSecondary"
                                    gutterBottom
                                    source="occurredAt"
                                    options={dateTimeOptions}
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <BoxField
                            label={
                                <Typography color="textSecondary" variant="subtitle2">
                                    {translate("incident.label.message")}
                                </Typography>}
                        >
                            <TextField component="p" source="message" />
                        </BoxField>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <BoxField
                            label={
                                <Typography color="textSecondary" variant="subtitle2">
                                    {translate("incident.label.severity")}
                                </Typography>}
                        >
                            <IncidentSeverityField component="p" source="severity" />
                        </BoxField>
                    </Grid>
                    <IncidentDataField />
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("incident.label.clientInfo")}
                        </Typography>
                    </Grid>
                    <IncidentClientInfoField />
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default IncidentShow;
