import PropTypes from "prop-types";
import React, { createElement } from "react";
import { Box, Card, Divider, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    card: {
        flex: "1",
        marginTop: theme.spacing(1),
        minHeight: theme.spacing(7),
        "& a": {
            color: "inherit",
            textDecoration: "none",
        },
    },
    main: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        overflow: "inherit",
        padding: theme.spacing(2),
        "& .icon": {
            color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
        },
    },
    title: {},
}));

const CardWithIcon = ({ children, icon, subtitle, title, to }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {
                            createElement(icon, { fontSize: "large" })
                        }
                    </Box>
                    <Box textAlign="right">
                        <Typography className={classes.title} color="textSecondary">
                            {title}
                        </Typography>
                        <Typography variant="h5" component="h3">
                            {subtitle || " "}
                        </Typography>
                    </Box>
                </div>
            </Link>
            {children && <Divider />}
            {children}
        </Card>
    );
};

CardWithIcon.propTypes = {
    children: PropTypes.any,
    icon: PropTypes.any,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
};

CardWithIcon.defaultProps = {};

export default CardWithIcon;
