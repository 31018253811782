import React, {useCallback, useState} from "react";
import DefaultIcon from "@material-ui/icons/Work";
import PropTypes from "prop-types";
import { Button, useCreate, useNotify, useRedirect, useTranslate } from "react-admin";
import { ModalForm } from "../form";

const CreateWithFormButton = ({
    action,
    button,
    children,
    failure,
    icon,
    initialValues,
    path,
    redirectTo,
    redirectView,
    resource,
    success,
    title,
    validate,
    onFailure,
    onSuccess,
    ...rest
}) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const buttonLabel  = button  || `${resource}.action.${action}`;
    const failureLabel = failure || `${resource}.message.${action}Failure`;
    const successLabel = success || `${resource}.message.${action}Success`;
    const titleLabel   = title   || `${resource}.message.${action}Modal`;

    const [ open, setOpen ] = useState(false);
    const [ create, { loading, error }] = useCreate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSubmit = useCallback(
        values => {
            create(resource, values, {
                path,
                onFailure: error => {
                    notify(failureLabel, 'warning', { ...error });
                    onFailure(error);
                },
                onSuccess: response => {
                    const { id } = response;

                    notify(successLabel, "info", { id });
                    setOpen(false);
                    onSuccess();

                    if (redirectTo) {
                        redirect(redirectView, redirectTo, id);
                    }
                },
            })
        },
        [
            create,
            failureLabel,
            notify,
            path,
            redirect,
            redirectTo,
            redirectView,
            resource,
            successLabel,
            onFailure,
            onSuccess,
        ]
    );

    return (
        <React.Fragment>
            <Button
                label={translate(buttonLabel)}
                disabled={loading || error}
                onClick={handleOpen}
                {...rest}
            >
                {icon}
            </Button>
            <ModalForm
                initialValues={initialValues}
                loading={loading}
                open={open}
                title={translate(titleLabel)}
                validate={validate}
                onClose={handleClose}
                onSubmit={handleSubmit}
            >
                {children}
            </ModalForm>
        </React.Fragment>
    );
};

CreateWithFormButton.propTypes = {
    action: PropTypes.string.isRequired,
    button: PropTypes.string,
    children: PropTypes.node,
    failure: PropTypes.string,
    icon: PropTypes.node,
    initialValues: PropTypes.object,
    path: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    redirectTo: PropTypes.string,
    redirectView: PropTypes.string,
    success: PropTypes.string,
    title: PropTypes.string,
    validate: PropTypes.func,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
};

CreateWithFormButton.defaultProps = {
    record: {},
    redirectView: "show",
    icon: (<DefaultIcon />),
    initialValues: {},
    onFailure: () => {},
    onSuccess: () => {},
};

export default CreateWithFormButton;
