import React from "react";
import PropTypes from "prop-types";
import { DeleteButton, SaveButton, Toolbar, useTranslate } from "react-admin";

const EditToolbar = ({ redirect, ...props}) => {
    const translate = useTranslate();

    return (
        <Toolbar {...props} >
            <SaveButton
                label={translate("ra.action.save")}
                redirect={redirect}
                submitOnEnter={true}
            />
            <DeleteButton
                label={translate("ra.action.delete")}
            />
        </Toolbar>
    )
};

EditToolbar.propTypes = {
    redirect: PropTypes.string,
};

EditToolbar.defaultProps = {
    redirect: "show",
};

export default EditToolbar;
