import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import { useLocale, useRecordContext } from "react-admin";

const PercentageField = ({ source, options }) => {
    const locale = useLocale();
    const record = useRecordContext();
    const formatter = new Intl.NumberFormat(locale, options);

    return (
        <Typography color="textPrimary" component="span" variant="body2">
            {formatter.format(100 * get(record, source))}
            {"%"}
        </Typography>
    )
};

PercentageField.propTypes = {
    options: PropTypes.object,
    source: PropTypes.string,
};

PercentageField.defaultProps = {
    options: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
};

export default PercentageField;
