import { createContext } from "react";

const GeoContext = createContext({
});

GeoContext.displayName = "GeoContext";

export {
    GeoContext,
}
