const DAY_GRID_DAY = "dayGridDay";
const DAY_GRID_MONTH = "dayGridMonth";
const DAY_GRID_WEEK = "dayGridWeek";
const LIST_DAY = "listDay";
const LIST_MONTH = "listMonth";
const LIST_WEEK = "listWeek";
const TIME_GRID_DAY = "timeGridDay";
const TIME_GRID_WEEK = "timeGridWeek";

const standardCalendarViews = [
    DAY_GRID_DAY,
    DAY_GRID_MONTH,
    DAY_GRID_WEEK,
];

const listCalendarViews = [
    LIST_DAY,
    LIST_WEEK,
    LIST_MONTH,
];

const timeGridCalendarViews = [
    TIME_GRID_DAY,
    TIME_GRID_WEEK,
];

const smallCalendarViews = [
    DAY_GRID_DAY,
    LIST_DAY,
    TIME_GRID_DAY,
];

const calendarViews = [
    ...standardCalendarViews,
    ...listCalendarViews,
    ...timeGridCalendarViews,
];

const KEY_PREFERRED_VIEW = 'adminCalendarPreferredView';
const KEY_PREFERRED_VIEW_SMALL = 'adminSmallCalendarPreferredView';

const readInitialView = (save, views, isSmall) => {
    const storage = save === "session" ? sessionStorage : localStorage;

    try {
        const data = storage.getItem(isSmall ? KEY_PREFERRED_VIEW_SMALL : KEY_PREFERRED_VIEW);

        if (data && views.indexOf(data) > -1) {
            return data;
        }
    } catch (e) {
        // Fallthrough
    }

    return null;
};

const saveInitialView = (save, view, isSmall) => {
    const storage = save === "session" ? sessionStorage : localStorage;

    try {
        storage.setItem(isSmall ? KEY_PREFERRED_VIEW_SMALL : KEY_PREFERRED_VIEW, view);
    } catch (e) {
        // Noop
    }
};

const filterViews = (views, isSmall) => isSmall? views.filter(view => smallCalendarViews.indexOf(view) > -1) : views;
const selectViews = isSmall => isSmall ? smallCalendarViews : calendarViews;
const selectInitialView = (save, views, isSmall) => {
    let preferred = readInitialView(save, views, isSmall);

    if (preferred) {
        return preferred;
    }

    if (isSmall) {
        if (views.indexOf(LIST_DAY) > -1) {
            return LIST_DAY;
        }

        if (views.indexOf(TIME_GRID_DAY) > -1) {
            return TIME_GRID_DAY;
        }

        if (views.indexOf(DAY_GRID_DAY) > -1) {
            return DAY_GRID_DAY;
        }
    } else {
        if (views.indexOf(LIST_MONTH) > -1) {
            return LIST_MONTH;
        }

        if (views.indexOf(DAY_GRID_MONTH) > -1) {
            return DAY_GRID_MONTH;
        }
    }

    return views[0];
};

export {
    KEY_PREFERRED_VIEW,
    KEY_PREFERRED_VIEW_SMALL,
    DAY_GRID_DAY,
    DAY_GRID_MONTH,
    DAY_GRID_WEEK,
    LIST_DAY,
    LIST_MONTH,
    LIST_WEEK,
    TIME_GRID_DAY,
    TIME_GRID_WEEK,
    calendarViews,
    filterViews,
    readInitialView,
    saveInitialView,
    selectViews,
    selectInitialView,
    smallCalendarViews,
    standardCalendarViews,
    timeGridCalendarViews,
}
