import React, {forwardRef} from "react";
import ProfileIcon from "@material-ui/icons/Face";
import SettingsIcon from "@material-ui/icons/Settings";
import { UserMenu, MenuItemLink, useTranslate } from "react-admin";

const ProfileMenuItemLink = forwardRef(({ onClick }, ref) => {
    const translate = useTranslate();

    return (
        <MenuItemLink
            to="/profile"
            ref={ref}
            primaryText={translate("ra.action.profile")}
            leftIcon={<ProfileIcon />}
            onClick={onClick} // Close on click
        />
    );
});

const SettingsMenuItemLink = forwardRef(({ onClick }, ref) => {
    const translate = useTranslate();

    return (
        <MenuItemLink
            to="/settings"
            ref={ref}
            primaryText={translate("ra.action.settings")}
            leftIcon={<SettingsIcon />}
            onClick={onClick} // Close on click
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ProfileMenuItemLink />
        <SettingsMenuItemLink />
    </UserMenu>
);

export default CustomUserMenu;
