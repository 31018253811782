import React from "react";
import { MonthInput, YearInput } from "../../component/input";
import { CardContent, Grid, Divider, Typography, makeStyles } from "@material-ui/core";
import { CreateToolbar } from "../../component/toolbar";
import {
    BooleanInput,
    FormDataConsumer,
    FormWithRedirect,
    NumberInput,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
    required,
    useLocale,
    useTranslate,
} from "react-admin";

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%",
    },
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    }
}));

const validate = [required()];

const labelFor = (record, locale) => {
    if (! record) {
        return "";
    }

    const { labels } = record;

    if (! labels || ! labels.length) {
        return "";
    }

    for (const label of labels) {
        if (locale === label.lang) {
            return label.label;
        }
    }

    return labels[0].label;
};

const CalendarForm = props => {
    const classes = useStyles();
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            render={
                formProps => (
                    <form>
                        <CardContent>
                            <Grid className={classes.grid} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {translate("calendar.message.calendar")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <YearInput
                                        fullWidth
                                        label={translate("calendar.label.year")}
                                        length={5}
                                        source="year"
                                        validate={validate}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <MonthInput
                                        fullWidth
                                        label={translate("calendar.label.month")}
                                        source="month"
                                        validate={validate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {translate("calendar.message.schedule")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <BooleanInput
                                        fullWidth
                                        helperText={translate("calendar.help.closed")}
                                        label={translate("calendar.label.closed")}
                                        source="closed"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormDataConsumer>
                                        {
                                            ({ formData }) => {
                                                if (formData.closed) {
                                                    return null;
                                                }

                                                const { month, year } = formData;
                                                const daysInMonth = new Date(year, month, 0).getDate();

                                                return (
                                                    <SelectArrayInput
                                                        fullWidth
                                                        helperText={translate("calendar.help.holidays")}
                                                        label={translate("calendar.label.holidays")}
                                                        source="holidays"
                                                        translateChoice={false}
                                                        choices={
                                                            Array.from({ length: daysInMonth }, (_, i) => (
                                                                { id: i + 1, name: i + 1 }
                                                            ))
                                                        }
                                                    />
                                                );
                                            }
                                        }
                                    </FormDataConsumer>
                                </Grid>
                                <FormDataConsumer>
                                    {
                                        ({ formData }) => {
                                            if (formData.closed) {
                                                return null;
                                            }

                                            return (
                                                <>

                                                    <Grid item xs={12}>
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            {translate("calendar.message.workday")}
                                                        </Typography>
                                                        <Typography color="textSecondary" gutterBottom variant="body2">
                                                            {translate("calendar.help.workday")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextInput
                                                            fullWidth
                                                            label={translate("calendar.label.firstCheckInAt")}
                                                            source="firstCheckInAt"
                                                            validate={validate}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextInput
                                                            fullWidth
                                                            label={translate("calendar.label.lastCheckInAt")}
                                                            source="lastCheckInAt"
                                                            validate={validate}
                                                        />
                                                    </Grid>
                                                </>
                                            );
                                        }
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {
                                        ({ formData }) => {
                                            if (formData.closed) {
                                                return null;
                                            }

                                            return (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            {translate("calendar.message.booking")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <NumberInput
                                                            fullWidth
                                                            helperText={translate("calendar.help.expirationTime")}
                                                            label={translate("calendar.label.expirationTime")}
                                                            min={0}
                                                            source="expirationTime"
                                                            step={1}
                                                            validate={validate}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <NumberInput
                                                            fullWidth
                                                            helperText={translate("calendar.help.interServiceTime")}
                                                            label={translate("calendar.label.interServiceTime")}
                                                            min={0}
                                                            source="interServiceTime"
                                                            step={1}
                                                            validate={validate}
                                                        />
                                                    </Grid>
                                                </>
                                            );
                                        }
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {
                                        ({ formData }) => {
                                            if (formData.closed) {
                                                return null;
                                            }

                                            return (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            {translate("calendar.message.products")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ReferenceArrayInput
                                                            fullWidth
                                                            label={translate("calendar.label.products")}
                                                            reference="product"
                                                            source="products"
                                                        >
                                                            <SelectArrayInput
                                                                optionText={record => `${labelFor(record, locale)} (${record.ref})`}
                                                                translateChoice={false}
                                                            />
                                                        </ReferenceArrayInput>
                                                    </Grid>
                                                </>
                                            );
                                        }
                                    }
                                </FormDataConsumer>
                            </Grid>
                        </CardContent>
                        <CreateToolbar
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            resource={props.resource}
                            saving={formProps.saving}
                        />
                    </form>
                )
            }
        />
    );
};

export default CalendarForm;
