import React from "react";
import { Grid } from "@material-ui/core";
import { NextOrders } from "./card";

const Dashboard = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <NextOrders />
        </Grid>
    </Grid>
);

export default Dashboard;
