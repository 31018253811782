import React from "react";
import CalendarDateField from "./CalendarDateField";
import CalendarHolidaysField from "./CalendarHolidaysField";
import CalendarTitle from "./CalendarTitle";
import ProductDatagrid from "../product/ProductDatagrid";
import { Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { DurationField } from "../../component/field";
import { FunctionField, Labeled, ReferenceArrayField, Show, SimpleShowLayout, TextField, useTranslate } from "react-admin";
import { ShowActions } from "../../component/action";

const useStyles = makeStyles(theme => ({
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const CalendarShow = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const closed = false;

    return (
        <Show
            actions={<ShowActions {...props} />}
            title={<CalendarTitle />}
            {...props}
        >
            <SimpleShowLayout>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("calendar.message.calendar")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <CalendarDateField />
                    </Grid>
                    <Grid item xs={12}>
                        <FunctionField render={
                            (record) => record && (
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2">
                                        {translate(record.closed ?
                                            "calendar.message.closed" :
                                            "calendar.message.open"
                                        )}
                                    </Typography>
                                </Box>
                            )
                        } />
                    </Grid>
                    {
                        ! closed && (
                            <>
                                <Grid item xs={12}>
                                    <Labeled label={translate("calendar.label.holidays")}>
                                        <CalendarHolidaysField
                                            emptyText={translate("calendar.empty.holidays")}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {translate("calendar.message.workday")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Labeled label={translate("calendar.label.firstCheckInAt")}>
                                        <TextField source="firstCheckInAt" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Labeled label={translate("calendar.label.lastCheckInAt")}>
                                        <TextField source="lastCheckInAt" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {translate("calendar.message.booking")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Labeled label={translate("calendar.label.expirationTime")}>
                                        <DurationField source="expirationTime" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Labeled label={translate("calendar.label.interServiceTime")}>
                                        <DurationField source="interServiceTime" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        {translate("calendar.message.products")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceArrayField
                                        addLabel={false}
                                        reference="product"
                                        source="products"
                                        sortBy="ref"
                                    >
                                        <ProductDatagrid />
                                    </ReferenceArrayField>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CalendarShow;
