import React from "react";
import PropTypes from "prop-types";
import { CloneButton, CreateButton, ListButton, ShowButton, TopToolbar } from "react-admin";

const EditActions = ({ basePath, data, id }) => (
    <TopToolbar>
        <ShowButton basePath={basePath} record={{id, ...data}} />
        <CloneButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} variant="contained" style={{ marginLeft: "1em" }} />
    </TopToolbar>
);

EditActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

EditActions.defaultProps = {
    data: {},
};

export default EditActions;
