import React from "react";
import { BooleanField, Datagrid, DateField, EmailField, NumberField, TextField, useTranslate } from "react-admin";
import { useMediaQuery } from "@material-ui/core";

const IdentityDatagrid = props => {
    const translate = useTranslate();
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"));

    return (
        <Datagrid {...props}>
            <TextField
                label={translate("identity.label.identifier")}
                source="identity"
            />
            <EmailField
                label={translate("identity.label.email")}
                source="email"
            />
            <BooleanField
                label={translate("identity.label.active")}
                source="active"
            />
            <BooleanField
                label={translate("identity.label.disabled")}
                source="disabled"
            />
            <DateField
                label={translate("identity.label.lastSignInAt")}
                source="lastSignInAt"
            />
            {
                isLarge ? (
                    <NumberField
                        emptyText="0"
                        label="Sign-In Attempts"
                        source="numSignInAttempts"
                    />
                ) : null
            }
        </Datagrid>
    );
};

export default IdentityDatagrid;
