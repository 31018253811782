import { createContext } from "react";

const FingerprintContext = createContext({
});

FingerprintContext.displayName = "FingerprintContext";

export {
    FingerprintContext,
}
