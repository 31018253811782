import React from "react";
import { DateField, Datagrid, TextField, useTranslate } from "react-admin";
import { dateTimeMinOptions } from "../../util/dates";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    datagrid: {
        marginBottom: theme.spacing(4),
        width: "100%",
    },
}));

const CredentialsDatagrid = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Datagrid className={classes.datagrid} {...props}>
            <TextField
                label={translate("credentials.label.type")}
                source="type"
            />
            <DateField
                label={translate("credentials.label.notBefore")}
                source="notBefore"
                options={dateTimeMinOptions}
            />
            <DateField
                emptyText={translate("ra.message.never")}
                label={translate("credentials.label.expireAt")}
                source="expireAt"
                options={dateTimeMinOptions}
            />
            <TextField
                label={translate("credentials.label.uuid")}
                source="uuid"
            />
        </Datagrid>
    );
}

export default CredentialsDatagrid;
