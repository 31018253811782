import { Children, useCallback, useState } from "react";
import { GeoContext } from "./GeoContext";

const defaultOptions = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
};

const fetchPosition = async (options = {}) => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(() => resolve, () => reject, { ...options, ...defaultOptions });
});

const GeoProvider = (props) => {
    const { children } = props;

    const [state, setState] = useState({
        position: null,
        error: null,
    });

    useCallback(async () => {
        try {
            const position = await fetchPosition();
            setState({ ...state, position });
        } catch (error) {
            setState({ ...state, error });
        }
    }, [state]);

    return (
        <GeoContext.Provider value={state}>
            {Children.only(children)}
        </GeoContext.Provider>
    )
};

export default GeoProvider;
