import React from "react";
import IdentityTitle from "./IdentityTitle";
import { ArrayField, Show, Tab, TabbedShowLayout, TextField, useTranslate } from "react-admin";
import IdentityShowActions from "./IdentityShowActions";
import CredentialsDatagrid from "../credentials/CredentialsDatagrid";
import { ScalarArrayField } from "../../component/field";

const IdentityShow = props => {
    const translate = useTranslate();

    return (
        <Show
            actions={<IdentityShowActions {...props} />}
            title={<IdentityTitle />}
            {...props}
        >
            <TabbedShowLayout syncWithLocation>
                <Tab label={translate("identity.tab.profile")}>
                    <TextField
                        label={translate("identity.label.identifier")}
                        source="identity"
                    />
                    <TextField
                        label={translate("identity.label.email")}
                        source="email"
                    />
                    <ScalarArrayField
                        label={translate("identity.label.roles")}
                        source="roles"
                    />
                </Tab>
                <Tab label={translate("identity.tab.credentials")}>
                    <ArrayField
                        addLabel={false}
                        fullWidth
                        source="credentials"
                    >
                        <CredentialsDatagrid />
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default IdentityShow;
