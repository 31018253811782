import React from "react";
import Settings from "../component/Settings";
import profile from "../resource/profile";
import { Route } from "react-router-dom";

const customRoutes = [
    <Route exact path="/settings" render={() => <Settings />} />,
    <Route exact path="/profile/edit" component={profile.edit} />,
    <Route exact path="/profile" component={profile.show} />,
];

export default customRoutes;
