import React from "react";
import formatString from "format-string-by-pattern";
import { DateInput, TextInput, email, maxLength, minLength, required, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import { WizardFormStep } from "../../../component/wizard";
import { minAge, phoneNumber } from "../../../util/validation";

const validateName = [required(), maxLength(128)];
const validateEmailAddress = [required(), email(), minLength(2), maxLength(128)];
const validateAge = [required(), minAge(18)];
const validatePhoneNumber = [required(), minLength(9), maxLength(14), phoneNumber()];

const BookingFormStepTwo = () => {
    const translate = useTranslate();

    return (
        <WizardFormStep label={translate("booking.step.two")}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={translate("passenger.label.emailAddress")}
                        helperText={translate("passenger.help.emailAddress")}
                        source="emailAddress"
                        validate={validateEmailAddress}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label={translate("passenger.label.firstName")}
                        source="firstName"
                        validate={validateName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label={translate("passenger.label.lastName")}
                        source="lastName"
                        validate={validateName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateInput
                        fullWidth
                        label={translate("passenger.label.dateOfBirth")}
                        source="dateOfBirth"
                        validate={validateAge}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label={translate("passenger.label.licenseNumber")}
                        source="licenseNumber"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label={translate("passenger.label.phoneNumber")}
                        parse={formatString("999999999")}
                        placeholder="999999999"
                        source="phoneNumber"
                        validate={validatePhoneNumber}
                    />
                </Grid>
            </Grid>
        </WizardFormStep>
    );
};

export default BookingFormStepTwo;
