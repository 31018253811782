import React from "react";
import CheckOutIcon from "@material-ui/icons/FlightLand";
import { UpdateWithFormButton } from "../../../component/button";
import { TextInput, useLocale, useTranslate } from "react-admin";
import { Typography } from "@material-ui/core";
import { dateTimeOptions } from "../../../util/dates";

const BookingCheckOutButton = props => {
    const locale = useLocale();
    const translate = useTranslate();
    const { record } = props;
    const { checkOutAt, emailAddress, locator } = record;
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const date = new Date(Date.parse(checkOutAt) + 3600000);

    return (
        <UpdateWithFormButton
            action="checkout"
            icon={<CheckOutIcon />}
            initialValues={{
                emailAddress,
                locator,
            }}
            path="checkout/"
            resource="booking"
            {...props}
        >
            {
                date && (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        {translate("booking.message.checkOutUntil", {
                            date: dateTimeFormatter.format(date),
                        })}
                    </Typography>
                )
            }
            <TextInput
                fullWidth
                label={translate("booking.label.checkOutNotes")}
                multiline
                resettable
                source="checkOutNotes"
            />
        </UpdateWithFormButton>
    );
};

export default BookingCheckOutButton;
