import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { SelectInput, useDataProvider, useLocale, useNotify, useVersion } from "react-admin";
import { timeOptions } from "../../../util/dates";
import { useForm } from "react-final-form";

const BookingTimeText = ({ locale, record }) => {
    const { checkIn, checkOut } = record;
    const timeFormatter = new Intl.DateTimeFormat(locale, timeOptions);

    return (
        <span>
            {timeFormatter.format(Date.parse(checkIn))}
            {"-"}
            {timeFormatter.format(Date.parse(checkOut))}
        </span>
    );
};

const BookingChangeTimeInput = ({ errorLabel, label, notAvailableLabel, record, source, value, ...props }) => {
    const dataProvider = useDataProvider();
    const locale = useLocale();
    const notify = useNotify();
    const version = useVersion();
    const { change } = useForm();
    const [choices, setChoices] = useState([]);

    const handleChange = event => change("changeTo", event.target.value);

    const fetchChoices = useCallback(async () => {
        const { ref, condition } = record;

        if (! value || ! ref || ! condition) {
            return;
        }

        // noinspection DuplicatedCode
        const extra = {
            c: condition,
            d: value,
            l: locale,
            r: ref,
        };

        try {
            const { data } = await dataProvider.getList("booking", { extra, path: "query/" });

            if (! Array.isArray(data) || ! data.length) {
                notify(notAvailableLabel, "warning", { ...record });
            }

            setChoices(data);
        } catch (e) {
            notify(errorLabel, "error", { ...record });
            setChoices([]);
        }
    }, [dataProvider, errorLabel, locale, notAvailableLabel, notify, record, value]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchChoices();
    }, [version, fetchChoices]);

    return (
        <SelectInput
            choices={choices}
            fullWidth
            key={version}
            label={label}
            optionText={
                <BookingTimeText
                    locale={locale}
                    record={record}
                />
            }
            optionValue="checkIn"
            source={source}
            translateChoice={false}
            {...props}
            onChange={handleChange}
        />
    );
};

BookingChangeTimeInput.propTypes = {
    errorLabel: PropTypes.string,
    label: PropTypes.string,
    notAvailableLabel: PropTypes.string,
    record: PropTypes.any,
    source: PropTypes.string.isRequired,
    value: PropTypes.any,
};

BookingChangeTimeInput.defaultProps = {
    errorLabel: "booking.message.queryError",
    notAvailableLabel: "booking.label.changeNotAvailable",
    record: {},
};

export default BookingChangeTimeInput;
