import React from "react";
import { SelectInput } from "react-admin";
import { stockTypeChoices } from "./stockTypeChoices";

const StockTypeInput = props => (
    <SelectInput
        name="type"
        choices={stockTypeChoices}
        {...props}
    />
);

export default StockTypeInput;
