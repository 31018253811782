import React from "react";
import PropTypes from "prop-types";
import { CloneButton, CreateButton, EditButton, ListButton, TopToolbar } from "react-admin";

const ShowActions = ({ basePath, data, id }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={{ ...data, id }} />
        <CloneButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} variant="contained" style={{ marginLeft: "1em" }} />
    </TopToolbar>
);

ShowActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

ShowActions.defaultProps = {
    data: {},
};

export default ShowActions;
