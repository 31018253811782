import React from "react";
import PropTypes from "prop-types";
import { Toolbar, makeStyles } from "@material-ui/core";
import { calendarViews } from "./calendarUtil";

const useStyles = makeStyles(theme => ({
    toolbar: {
        alignItems: "flex-start",
        justifyContent: "space-between",
        paddingRight: 0,
        "& .MuiToolbar-root": {
            alignItems: "center",
            display: "flex",
            textAlign: "center",
            width: "100%",
        },
        [theme.breakpoints.up("xs")]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.down("xs")]: {
            backgroundColor: theme.palette.background.paper,
            paddingLeft: theme.spacing(2),
        },
    },
    actions: {
        minHeight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
        }
    },
}));

const CalendarToolbar = props => {
    const { actions, calendar, date, save, views, ...rest } = props;
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar}>
            <span />
            {
                actions && React.cloneElement(actions, {
                    calendar,
                    className: classes.actions,
                    date,
                    save,
                    views,
                    ...actions.props,
                    ...rest,
                })
            }
        </Toolbar>
    );
};

CalendarToolbar.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    calendar: PropTypes.object.isRequired,
    classes: PropTypes.object,
    date: PropTypes.object,
    exporter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    save: PropTypes.oneOf(["session", "local"]),
    views: PropTypes.arrayOf(PropTypes.oneOf(calendarViews)),
};

export default CalendarToolbar;
