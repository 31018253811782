import React from "react";
import { SelectField } from "react-admin";
import { stockTypeChoices } from "./stockTypeChoices";

const StockTypeField = props => (
    <SelectField
        choices={stockTypeChoices}
        {...props}
    />
);

export default StockTypeField;
