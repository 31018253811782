import React from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

const CheckInInstructor = ({ classes }) => {
    const translate = useTranslate();

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>{"I"}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography component="p" variant="body1">
                        {translate("passenger.label.instructor")}
                    </Typography>
                }
                secondary={
                    <Typography color="textSecondary" component="p" variant="body2">
                        {translate("passenger.type.licensed")}
                    </Typography>
                }
            />
        </ListItem>
    );
}

export default CheckInInstructor;
