import React from "react";
import DefaultIcon from "@material-ui/icons/ArrowBackIos";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

const CalendarPrevButton = ({ calendar, icon, label, ...props }) => {
    const handleClick = () => calendar.current.getApi().prev();

    return (
        <IconButton
            aria-label={label}
            label={label}
            onClick={handleClick}
            {...props}
        >
            {icon ? icon : null}
        </IconButton>
    );
};

CalendarPrevButton.propTypes = {
    calendar: PropTypes.object.isRequired,
    icon: PropTypes.node,
    label: PropTypes.string,
};

CalendarPrevButton.defaultProps = {
    icon: (<DefaultIcon fontSize="small" />),
};

export default CalendarPrevButton;
