import React from "react";
import { SimpleList } from "react-admin";

const CalendarSimpleList = props => {
    return (
        <SimpleList
            primaryText={record => `${record.month}/${record.year}`}
            secondaryText={record => record.closed ? "CERRADO" : "ABIERTO"}
            tertiaryText={record => `` }
            {...props}
        />
    );
};

export default CalendarSimpleList;
