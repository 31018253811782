import React from "react";
import PropTypes from "prop-types";
import { TopToolbar, usePermissions, useShowContext } from "react-admin";
import {
    BookingBackButton,
    BookingCancelButton,
    BookingChangeButton,
    BookingCheckInButton,
    BookingCheckOutButton,
    BookingDropButton,
    BookingListButton,
} from "./button";
import {
    isCancelPossible,
    isChangePossible,
    isCheckInPossible,
    isCheckOutPossible,
    isDropPossible,
} from "./bookingUtils";
//import BookingSignButton from "./button/BookingSignButton";

const BookingShowActions = props => {
    const { loaded, permissions } = usePermissions();
    const { record } = useShowContext();

    if (! loaded || ! record) {
        return null;
    }

    const hasAdminRole = permissions && permissions.indexOf("ROLE_ADMIN") > -1;

    return (
        <TopToolbar {...props} >
            <BookingBackButton {...props} />
            {
                hasAdminRole && (
                    <BookingListButton />
                )
            }
            <span />
            {
                isChangePossible(record) && (
                    <BookingChangeButton record={record} {...props} />
                )
            }
            <span />
            {
                isCheckInPossible(record) && (
                    <BookingCheckInButton record={record} {...props} />
                )
            }
            <span />
            {
                isCheckOutPossible(record) && (
                    <BookingCheckOutButton record={record} {...props} />
                )
            }
            <span />
            {
                isCancelPossible(record) && (
                    <BookingCancelButton record={record} {...props} />
                )
            }
            <span />
            {
                isDropPossible(record) && (
                    <BookingDropButton record={record} {...props} />
                )
            }
        </TopToolbar>
    )
};

BookingShowActions.propTypes = {
    data: PropTypes.object,
};

BookingShowActions.defaultProps = {
    data: {},
};

export default BookingShowActions;
