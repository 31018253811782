import React from "react";
import { BoxField } from "../../component/box";
import { Grid, Typography } from "@material-ui/core";
import { parseClientInfo } from "./incidentUtils";
import { useRecordContext, useTranslate } from "react-admin";

const IncidentClientInfoField = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (! record) {
        return null;
    }

    const { client } = record;
    const tokens = parseClientInfo(client);

    return (
        <>
            {
                tokens.map((token, i) => {
                    const { cols, label, value } = token;

                    return (
                        <Grid key={i} item xs={12} md={cols}>
                            <BoxField
                                fullWidth
                                label={
                                    <Typography color="textSecondary" variant="body2">
                                        {translate("incident.label." + label)}
                                    </Typography>
                                }
                            >
                                <Typography color="textPrimary" variant="body2">
                                    {value}
                                </Typography>
                            </BoxField>
                        </Grid>
                    );
                })
            }
        </>
    )
};

export default IncidentClientInfoField;
