import React from "react";
import BackToListButton from "../button/BackToListButton";
import PropTypes from "prop-types";
import { SaveButton, Toolbar, useTranslate } from "react-admin";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    left: {
        marginRight: theme.spacing(1),
    },
    right: {
        marginLeft: "auto",
    },
}));

const CreateToolbar = ({ redirect, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar {...props} >
            <SaveButton
                className={classes.left}
                label={translate("ra.action.save")}
                redirect={redirect}
                submitOnEnter={true}
            />
            <SaveButton
                className={classes.left}
                label={translate("ra.action.save_and_add")}
                redirect={false}
                submitOnEnter={false}
            />
            <BackToListButton
                className={classes.right}
                label={translate("ra.action.list")}
            />
        </Toolbar>
    )
};

CreateToolbar.propTypes = {
    redirect: PropTypes.string,
};

CreateToolbar.defaultProps = {
    redirect: "show",
};

export default CreateToolbar;
