import React from "react";
import { SimpleList, useTranslate } from "react-admin";

const StockSimpleList = props => {
    const translate = useTranslate();

    return (
        <SimpleList
            primaryText={record => record.sku}
            secondaryText={record => `${record.brand} ${record.model} ${record.currentlyAvailable ? "" : " · " + translate("stock.message.unavailableShort")}`}
            tertiaryText={record => record.type}
            {...props}
        />
    );
};

export default StockSimpleList;
