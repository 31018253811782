import CredentialsType from "./CredentialsType";

const credentialsTypeChoices = [
    { id: CredentialsType.ACCESS_KEY, name: "credentials.type.accessKey" },
    { id: CredentialsType.BIOMETRIC, name: "credentials.type.biometric" },
    { id: CredentialsType.ONE_TIME_PASSWORD, name: "credentials.type.oneTimePassword" },
    { id: CredentialsType.PASSWORD, name: "credentials.type.password" },
    { id: CredentialsType.PUBLIC_KEY, name: "credentials.type.publicKey" },
    { id: CredentialsType.SECRET_KEY, name: "credentials.type.secretKey" },
];

export default credentialsTypeChoices;
