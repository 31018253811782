import React from "react";
import PropTypes from "prop-types";
import StockAddEventButton from "./button/StockAddEventButton";
import { CloneButton, CreateButton, ListButton, ShowButton, TopToolbar } from "react-admin";

const StockEditActions = ({ basePath, data, id }) => (
    <TopToolbar>
        <StockAddEventButton record={data} />
        <ShowButton basePath={basePath} record={{id, ...data}} />
        <CloneButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} variant="contained" style={{ marginLeft: "1em" }} />
    </TopToolbar>
);

StockEditActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

StockEditActions.defaultProps = {
    data: {},
};

export default StockEditActions;
