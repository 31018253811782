import React from "react";
import BookingTitle from "./BookingTitle";
import { BookingForm } from "./form";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {
    emailAddress: "anybody@example.org",
    firstName: "Any",
    lastName: "Body",
    phoneNumber: "922333333",
    paymentMethod: "cash",
    paymentCardIssuer: "",
    paymentCardNumber: "4548810000000003",
    paymentCardExpiry: "12/49",
    paymentCardSecurityCode: "123",
};

const BookingCreate = props => (
    <Create
        actions={<CreateActions />}
        title={<BookingTitle />}
        {...props}
    >
        <BookingForm initialValues={initialValues} />
    </Create>
);

export default BookingCreate;
