import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { CreateButton, ExportButton, TopToolbar, sanitizeListRestProps, useListContext } from "react-admin";

const useStyles = makeStyles(theme => ({
    create: {
        marginLeft: theme.spacing(2),
    },
}));

const ListActions = (props) => {
    const classes = useStyles();
    const {
        className,
        exportable,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        //selectedIds, (unused)
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar
            className={className}
            {...sanitizeListRestProps(rest)}
        >
            {
                filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })
            }
            {
                exportable && (
                    <ExportButton
                        disabled={total === 0}
                        filterValues={filterValues}
                        maxResults={maxResults}
                        resource={resource}
                        sort={currentSort}
                    />
                )
            }
            {
                hasCreate && <CreateButton
                    className={classes.create}
                    color="primary"
                    basePath={basePath}
                    variant="contained"
                />
            }
        </TopToolbar>
    );
};

ListActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
    exportable: PropTypes.bool,
};

ListActions.defaultProps = {
    data: {},
    exportable: true
};

export default ListActions;
