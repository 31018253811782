import React, { useState } from "react";
import CalendarToolbar from "./CalendarToolbar";
import CalendarWrap from "./CalendarWrap";
import PropTypes from "prop-types";
import { Title, useListContext, useRedirect, useVersion } from "react-admin";
import { calendarViews, filterViews, selectViews, selectInitialView } from "./calendarUtil";
import { useMediaQuery } from "@material-ui/core";

const CalendarView = props => {
    const {
        actions,
        allowPastEvents,
        basePath,
        className,
        classes,
        component: Content,
        exporter,
        initialDate,
        locale,
        monthFormat,
        resource,
        save,
        source,
        title,
        views,
        yearFormat,
    } = props;

    const [date, setDate] = useState(initialDate);
    const calendar = React.createRef();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const listContext = useListContext();
    const redirect = useRedirect();
    const version = useVersion();

    const {
        defaultTitle,
        filterValues,
        setFilters,
    } = listContext;

    const filteredViews = views && views.length > 0 ? filterViews(views, isSmall) : selectViews(isSmall);
    const initialView = selectInitialView(save, filteredViews, isSmall);

    const handleChange = calendarState => {
        const monthFormatter = new Intl.DateTimeFormat(locale, monthFormat);
        const yearFormatter = new Intl.DateTimeFormat(locale, yearFormat);
        const formatter = date => yearFormatter.format(date) + monthFormatter.format(date);

        const { start } = calendarState;

        // DO NOT consider the date change if it
        // does not affect the current filter query
        if (formatter(start) !== formatter(date)) {
            const newFilters = { ...filterValues, [source]: { start: formatter(start) }};

            setDate(start);
            setFilters(newFilters, false, true);
        }
    };

    const handleCreate = eventInfo => {
        const { date } = eventInfo;
        date.setHours(12);

        // DO NOT allow to create past events, unless
        // explicitly set
        if (allowPastEvents || date >= new Date()) {
            const path = `${basePath}/create?source=${JSON.stringify({ date })}`;
            redirect(path);
        }
    };

    return (
        <div className={className}>
            <Title
                defaultTitle={defaultTitle}
                title={title}
            />
            {
                actions && (
                    <CalendarToolbar
                        actions={actions}
                        calendar={calendar}
                        exporter={exporter}
                        date={date}
                        save={save}
                        views={filteredViews}
                    />
                )
            }
            <div className={classes.main}>
                <Content key={version} className={classes.content}>
                    <CalendarWrap
                        calendar={calendar}
                        initialDate={date}
                        initialView={initialView}
                        resource={resource}
                        onChange={handleChange}
                        onCreate={handleCreate}
                    />
                </Content>
            </div>
        </div>
    );
};

CalendarView.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    allowPastEvents: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.shape({
        main: PropTypes.string,
        content: PropTypes.string,
    }),
    component: PropTypes.any,
    dateFormat: PropTypes.object,
    defaultTitle: PropTypes.string,
    exporter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    filter: PropTypes.object,
    hasCreate: PropTypes.bool,
    ids: PropTypes.array,
    initialDate: PropTypes.object,
    locale: PropTypes.string,
    loading: PropTypes.bool,
    monthFormat: PropTypes.object,
    refresh: PropTypes.func,
    resource: PropTypes.string,
    save: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["session", "local"])]),
    source: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    views: PropTypes.arrayOf(PropTypes.oneOf(calendarViews)),
    yearFormat: PropTypes.object,
};

export default CalendarView;
