import React from "react";
import { Filter, useTranslate } from "react-admin";
import { MonthInput, YearInput } from "../../component/input";

const CalendarFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <YearInput
                source="year"
                label={translate("calendar.label.year")}
            />
            <MonthInput
                source="month"
                label={translate("calendar.label.month")}
            />
        </Filter>
    );
};

export default CalendarFilter;
