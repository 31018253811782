import CalendarIcon from '@material-ui/icons/CalendarToday';
import CalendarCreate from './CalendarCreate';
import CalendarList from "./CalendarList";
import CalendarShow from "./CalendarShow";
import CalendarEdit from "./CalendarEdit";

const calendar = {
    create: CalendarCreate,
    edit: CalendarEdit,
    icon: CalendarIcon,
    list: CalendarList,
    show: CalendarShow,
}

export default calendar;
