import React from "react";
import {
    SimpleForm,
    TextInput,
    required,
    email,
    minLength,
    maxLength,
    useTranslate,
} from "react-admin";

const validateIdentitifier = [required(), minLength(8), maxLength(128)];
const validateEmailAddress = [required(), email(), maxLength(128)];

const IdentityForm = props => {
    const translate = useTranslate();
    
    return (
        <SimpleForm {...props}>
            <TextInput
                label={translate("identity.label.identifier")}
                source="identity"
                validate={validateIdentitifier}
            />
            <TextInput
                fullWidth
                label={translate("identity.label.email")}
                source="email"
                validate={validateEmailAddress}
            />
        </SimpleForm>
    );
};

export default IdentityForm;
