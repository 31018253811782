import React from "react";
import { Datagrid, DateField, TextField, useTranslate } from "react-admin";
import { dateTimeOptions } from "../../util/dates";

const CustomerDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid rowClick="show" {...props}>
            <TextField
                label={translate("customer.label.firstName")}
                source="firstName"
            />
            <TextField
                label={translate("customer.label.lastName")}
                source="lastName"
            />
            <TextField
                label={translate("customer.label.emailAddress")}
                source="emailAddress"
            />
            <DateField
                label={translate("customer.label.lastOrderAt")}
                source="lastOrderAt"
                options={dateTimeOptions}
            />
        </Datagrid>
    );
};

export default CustomerDatagrid;
