import React from "react";
import BookingCardCustomer from "./BookingCardCustomer";
import BookingCardInvoice from "./BookingCardInvoice";
import BookingCardPayment from "./BookingCardPayment";
import PropTypes from "prop-types";
import { CardContent, Divider, Grid } from "@material-ui/core";

const BookingCardDetails = ({ classes, currencyFormatter, dateTimeFormatter, record }) => (
    <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
                <BookingCardCustomer
                    classes={classes}
                    record={record}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BookingCardPayment
                    classes={classes}
                    currencyFormatter={currencyFormatter}
                    record={record}
                />
            </Grid>
            <Grid item xs={12}>
                <BookingCardInvoice
                    classes={classes}
                    dateTimeFormatter={dateTimeFormatter}
                    record={record}
                />
            </Grid>
        </Grid>
    </CardContent>
);

BookingCardDetails.propTypes = {
    classes: PropTypes.object,
    currencyFormatter: PropTypes.object,
    dateTimeFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardDetails.defaultProps = {
    classes: {},
};

export default BookingCardDetails;
