import React from "react";
import PropTypes from "prop-types";
import { BoxField } from "../../../component/box";
import { Typography } from "@material-ui/core";
import { getAge } from "../../../util/dates";
import { useTranslate } from "react-admin";

const BookingCardCustomer = ({ classes, record }) => {
    const translate = useTranslate();
    const { age, condition, dateOfBirth, emailAddress, firstName, lastName, phoneNumber } = record;

    return (
        <BoxField fullWidth label={
            <Typography color="textSecondary" variant="subtitle2">
                {translate("booking.message.customerData")}
            </Typography>
        }>
            <Typography className={classes.bold} variant="body2">
                {firstName}
                {" "}
                {lastName}
            </Typography>
            <Typography gutterBottom variant="body2">
                {translate("booking.message.age", { age: age || getAge(new Date(Date.parse(dateOfBirth))) })}
                {" • "}
                {translate("passenger.condition." + condition)}
                {" • "}
                {phoneNumber}
            </Typography>
            <Typography variant="h5">
                {emailAddress}
            </Typography>
        </BoxField>
    );
};

BookingCardCustomer.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardCustomer.defaultProps = {
    classes: {},
};

export default BookingCardCustomer;
