import React from "react";
import BookingCardHistoryEntry from "./BookingCardHistoryEntry";
import PropTypes from "prop-types";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { dateTimeOptions } from "../../../util/dates";
import { getBookingEvents } from "../bookingUtils";
import { useLocale, useTranslate } from "react-admin";

const BookingCardHistory = ({ dateTimeFormatter, record }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const formatter = new Intl.DateTimeFormat(locale, dateTimeOptions);

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("booking.message.history")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        getBookingEvents(record, formatter).map((event, i) => {
                            const { actor, data, date, label, type, formatted } = event;
                            const invoiceNumber = record.invoice ? record.invoice.number : null;

                            let vars = {};

                            if (data) {
                                try {
                                    vars = JSON.parse(data);
                                } catch (e) {}
                            }

                            return (
                                <BookingCardHistoryEntry
                                    actor={actor}
                                    date={dateTimeFormatter.format(date)}
                                    key={i}
                                    notes={translate("booking.events." + label, { ...record, ...vars, ...formatted, actor, invoiceNumber })}
                                    type={type}
                                />
                            );
                        })
                    }
                </Grid>
            </Grid>
        </CardContent>
    );
}

BookingCardHistory.propTypes = {
    classes: PropTypes.object,
    dateTimeFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardHistory.defaultProps = {
    classes: {},
};

export default BookingCardHistory;
