import React from "react";
import { SelectInput } from "react-admin";
import { bookingChangeCauseChoices } from "./bookingChangeCauseChoices";

const BookingChangeCauseInput = props => (
    <SelectInput
        choices={bookingChangeCauseChoices}
        {...props}
    />
);

export default BookingChangeCauseInput;
