import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import { useLocale, useRecordContext } from "react-admin";

const MonthField = ({ format, source }) => {
    const locale = useLocale();
    const record = useRecordContext();
    const formatter = new Intl.DateTimeFormat(locale, { month: format });
    const date = new Date();
    date.setMonth(get(record, source));

    return (
        <Typography color="textPrimary" component="span" variant="body2">
            {formatter.format(date)}
        </Typography>
    )
};

MonthField.propTypes = {
    format: PropTypes.oneOf(["2-digit", "numeric", "narrow", "short", "long"]),
    source: PropTypes.string,
};

MonthField.defaultProps = {
    format: "long",
};

export default MonthField;
