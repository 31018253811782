import React from "react";
import DefaultIcon from "@material-ui/icons/ArrowForwardIos";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

const CalendarNextButton = ({ calendar, icon, label, ...props }) => {
    const handleClick = () => calendar.current.getApi().next();

    return (
        <IconButton
            {...props}
            aria-label={label}
            label={label}
            onClick={handleClick}
        >
            {icon ? icon : null}
        </IconButton>
    );
};

CalendarNextButton.propTypes = {
    calendar: PropTypes.object.isRequired,
    icon: PropTypes.node,
    label: PropTypes.string,
};

CalendarNextButton.defaultProps = {
    icon: (<DefaultIcon fontSize="small" />),
};

export default CalendarNextButton;
