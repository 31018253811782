import React from "react";
import PropTypes from "prop-types";
import { ListButton, TopToolbar } from "react-admin";

const IncidentShowActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

IncidentShowActions.propTypes = {
    basePath: PropTypes.string,
};

export default IncidentShowActions;
