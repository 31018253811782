import React from "react";
import PropTypes from "prop-types";
import { Step, StepLabel, Stepper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {},
}, { name: "RaStepper" });

const WizardStepper = ({ activeStep, alternativeLabel, nonLinear, stepLabels, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel={alternativeLabel}
                nonLinear={nonLinear}
                {...props}
            >
                {
                    stepLabels.map((label, step) => (
                        <Step key={step}>
                            <StepLabel>
                                {label}
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </div>
    );
};

WizardStepper.propTypes = {
    alternativeLabel: PropTypes.bool,
    activeStep: PropTypes.number,
    nonLinear: PropTypes.bool,
    stepLabels: PropTypes.arrayOf(PropTypes.string),
};

WizardStepper.defaultProps = {
    alternativeLabel: true,
    activeStep: 0,
};

export default WizardStepper;
