import React from "react";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip } from "@material-ui/core";
import { useTranslate } from "react-admin";

const CheckInPassengerAdd = ({ label, onEdit }) => {
    const translate = useTranslate();

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <PersonIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText secondary={translate(label || "ra.action.add_passenger")} />
            <ListItemSecondaryAction>
                <Tooltip title={translate("ra.action.add")}>
                    <IconButton
                        aria-label={translate("ra.action.add")}
                        edge="end"
                        onClick={onEdit}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default CheckInPassengerAdd;
