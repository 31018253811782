import PropTypes from "prop-types";
import React from "react";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import { Box, CardContent, Typography } from "@material-ui/core";
import { isCheckInPossible } from "../bookingUtils";
import { useTranslate } from "react-admin";

const BookingCardWarning = ({ classes, record }) => {
    const translate = useTranslate();
    const {
        warning,
        warningMessage,
        warningCause,
    } = record;

    if (! warning || ! isCheckInPossible(record)) {
        return null;
    }

    return (
        <CardContent>
            <Box alignItems="center" className={classes.error} display="flex">
                <WarningIcon fontSize={"small"} />
                <Typography className={classes.error} variant="body2">
                    <span className={classes.bold}>
                        {translate("order.warning." + warningCause)}
                    </span>
                    {"—"}
                    {translate(warningMessage)}
                </Typography>
            </Box>
        </CardContent>
    );
};

BookingCardWarning.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardWarning.defaultProps = {
    classes: {},
};

export default BookingCardWarning;
