import React from "react";
import CredentialsType from "../../credentials/CredentialsType";
import CredentialsTypeInput from "../../credentials/CredentialsTypeInput";
import KeyIcon from "@material-ui/icons/VpnKey";
import { DateTimeInput, SelectInput, required, useShowContext, useTranslate } from "react-admin";
import { UpdateWithFormButton } from "../../../component/button";
import { dateTimeOptions } from "../../../util/dates";

const validate = [required()];

const IdentityCreateCredentialsButton = props => {
    const translate = useTranslate();
    const { loaded, record } = useShowContext();

    if (! loaded || ! record) {
        return null;
    }

    const { devices } = record;
    const hasDevices = Array.isArray(devices) && devices.length > 0;

    return (
        <UpdateWithFormButton
            action="credentials"
            icon={<KeyIcon />}
            initialValues={{ type: CredentialsType.PASSWORD, notBefore: new Date() }}
            path="credentials/"
            resource="identity"
            {...props}
        >
            <CredentialsTypeInput
                fullWidth
                label={translate("credentials.label.type")}
                source="type"
                validate={validate}
            />
            <DateTimeInput
                fullWidth
                label={translate("credentials.label.notBefore")}
                source="notBefore"
                options={dateTimeOptions}
                validate={validate}
            />
            <DateTimeInput
                fullWidth
                label={translate("credentials.label.expireAt")}
                source="expireAt"
                options={dateTimeOptions}
            />
            {
                hasDevices && (
                    <SelectInput
                        choices={devices}
                        fullWidth
                        label={translate("credentials.label.bindToDevice")}
                        optionText={record => `${record.type} (${record.platform})`}
                        optionValue="did"
                        source="bindToDevice"
                    />
                )
            }
        </UpdateWithFormButton>
    );
};

export default IdentityCreateCredentialsButton;
