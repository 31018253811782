import PaymentMethod from "./PaymentMethod";

const paymentNoVoucherChoices = [
    { id: PaymentMethod.CASH, name: "payment.method.cash" },
    { id: PaymentMethod.CARD, name: "payment.method.card" },
];

const paymentChoices = [
    ...paymentNoVoucherChoices,
    { id: PaymentMethod.VOUCHER, name: "payment.method.voucher" },
];

export {
    paymentChoices,
    paymentNoVoucherChoices,
};
