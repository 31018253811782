import React from "react";
import IdentityDatagrid from "./IdentityDatagrid";
import IdentityFilter from "./IdentityFilter";
import IdentitySimpleList from "./IdentitySimpleList";
import IdentityTitle from "./IdentityTitle";
import { List, Responsive } from "react-admin";
import { ListActions } from "../../component/action";

const IdentityList = props => {
    return (
        <List
            actions={<ListActions />}
            filters={<IdentityFilter />}
            title={<IdentityTitle />}
            {...props}
        >
            <Responsive
                small={
                    <IdentitySimpleList />
                }
                medium={
                    <IdentityDatagrid rowClick="show" />
                }
            />
        </List>
    );
};

export default IdentityList;
