import ClientInfo from "./ClientInfo";
import IncidentSeverity from "./IncidentSeverity";

const colors = {
    [IncidentSeverity.LOW]: "#ddddd4",
    [IncidentSeverity.MEDIUM]: "#88d9eb",
    [IncidentSeverity.HIGH]: "#f5c400",
    [IncidentSeverity.CRITICAL]: "#c70000",
};

const getSeverityColor = severity => colors[severity] || colors.low;

const parseClientInfo = clientInfo => {
    const tokens = [];
    const pattern = /([A-Z]+=(?:'[^']+'|[^/]+))+/g;
    let match;
    let i = 0;

    while ((match = pattern.exec(clientInfo))) {
        const [key, val] = match[1].split("=");
        const cols = key === ClientInfo.UA ? 12 : 6;
        const label = key.toLowerCase();
        const value = val.charAt(0) === "'" && val.charAt(val.length - 1) === "'" ? val.substr(1, val.length - 2) : val;

        tokens.push({ cols, label, value });

        if ( ++ i > 10) {
            break;
        }
    }

    return tokens;
};

export {
    getSeverityColor,
    parseClientInfo,
}
