import { PhoneNumberUtil } from "google-libphonenumber";

const PATTERN_CARD = /^[0-9]{4}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
const PATTERN_EXPIRY = /^[0-9]{2}\/[0-9]{2}$/;
const PATTERN_ID_CARD = /^[0-9A-Z]{8,}$/;
const PATTERN_SECURITY_CODE = /^[0-9]{3}$/;
const PATTERN_VOUCHER = /^747-[0-9A-Z]{17}-[0-9]$/i;

const idCard = (message = "ra.validation.idCard") => value => {
    if (! value) {
        return;
    }

    return PATTERN_ID_CARD.test(value) ? undefined : { message };
};

const minAge = (min, message = "ra.validation.minAge") => value => {
    if (! value) {
        return;
    }

    const age = new Date().getFullYear() - new Date(value.split("-")).getFullYear();

    return min > age ? { message, args: { min }} : undefined;
};

const phoneNumber = (message = "ra.validation.phoneNumber") => value => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const pn = phoneUtil.parse("+34" + value, "ES");

    if (! phoneUtil.isPossibleNumber(pn)) {
        return message;
    }

    return phoneUtil.isValidNumber(pn) ? undefined : message;
};

const cardExpiry = (message = "ra.validation.expiry") => value => ! PATTERN_EXPIRY.test(value);
const cardNumber = (message = "ra.validation.card") => value => ! PATTERN_CARD.test(value);
const cardSecurityCode = (message = "ra.validation.securityCode") => value => ! PATTERN_SECURITY_CODE.test(value);
const voucherNumber = (message = "ra.validation.voucherNumber") => value => ! PATTERN_VOUCHER.test(value);

const fileSize = (maxFileSize, message = "ra.validation.fileSize") => value => {
    if (! (value instanceof File)) {
        return;
    }

    const { rawFile } = value;

    if (! (rawFile instanceof File)) {
        return;
    }

    const { size } = rawFile;

    if (size > maxFileSize) {
        return message;
    }
};

export {
    cardExpiry,
    cardNumber,
    cardSecurityCode,
    fileSize,
    idCard,
    minAge,
    phoneNumber,
    voucherNumber,
}
