import BookingCancelCause from "./BookingCancelCause";

const bookingCancelCauseChoices = [
    { id: BookingCancelCause.CUSTOMER_REQUEST, name: "booking.cancel.customerRequest" },
    { id: BookingCancelCause.OTHER, name: "booking.cancel.other" },
];

export {
    bookingCancelCauseChoices,
};
