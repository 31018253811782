import React from "react";
import ToggleThemeIcon from "@material-ui/icons/Brightness4";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { changeTheme } from "../../config/customActions";
import { useDispatch, useSelector } from "react-redux";

const ToggleThemeButtom = ({ addLabel, className, icon, label, ...props }) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const translate = useTranslate();
    const translatedLabel = translate(label);

    const handleClick = () => dispatch(changeTheme(theme === "dark" ? "light" : "dark"));

    return (
        <Tooltip title={translatedLabel}>
            <IconButton
                aria-label={translatedLabel}
                aria-haspopup={true}
                className={className}
                color="inherit"
                onClick={handleClick}
                {...props}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

ToggleThemeButtom.propTypes = {
    addLabel: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string,
};

ToggleThemeButtom.defaultProps = {
    addLabel: false,
    icon: (<ToggleThemeIcon />),
    label: "ra.action.toggle_theme",
};

export default ToggleThemeButtom;
