import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";

const CalendarTodayButton = ({ calendar, icon, label, ...props }) => {
    const handleClick = () => calendar.current.getApi().today();

    return (
        <Button
            aria-label={label}
            label={label}
            onClick={handleClick}
            {...props}
        >
            {icon ? icon : null}
        </Button>
    );
};

CalendarTodayButton.propTypes = {
    calendar: PropTypes.object.isRequired,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    label: PropTypes.string,
};

CalendarTodayButton.defaultProps = {
    icon: false,
};

export default CalendarTodayButton;
