import React from "react";
import AttentionRequiredIcon from "@material-ui/icons/ErrorOutline";
import PropType from "prop-types";
import classnames from "classnames";
import { makeStyles, Tooltip } from "@material-ui/core";
import { standardCalendarViews } from "./calendarUtil";
import { useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
    event: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0.5),
        width: "100%",
    },
    past: {
        color: "#999",
    },
    today: {
        color: "#222",
    },
    primary: {
        alignItems: "center",
        display: "flex",
    },
    secondary: {
        display: "flex",
    },
    tertiary: {
        display: "flex",
    },
    icon: {
        fontSize: "16px",
        marginLeft: theme.spacing(0.5),
    },
    title: {
        fontSize: "16px",
        lineHeight: 1,
    },
    gridTitle: {
        fontSize: "14px",
        fontWeight: 700,
    },
    time: {
        fontSize: "12px",
        lineHeight: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    label: {
        fontSize: "12px",
    },
}));

const CalendarEvent = ({ className, eventInfo }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (! eventInfo) {
        return null;
    }

    const { event: { title, extendedProps }, isPast, isToday, timeText, view: { type } } = eventInfo;
    const { label, warning, warningMessage } = extendedProps;
    const showTertiary = standardCalendarViews.indexOf(type) === -1;

    return (
        <div className={classnames(className, classes.event, {
            [classes.past]: isPast,
            [classes.today]: ! isPast && isToday,
        })}>
            <div className={classes.primary}>
                <span className={classnames(classes.title, {
                    [classes.gridTitle]: ! showTertiary,
                })}>
                    {title}
                </span>
                {
                    warning && (
                        <Tooltip title={translate(warningMessage)}>
                            <AttentionRequiredIcon className={classes.icon} />
                        </Tooltip>
                    )
                }
            </div>
            <div className={classes.secondary}>
                <span className={classes.time}>
                    {timeText}
                </span>
            </div>
            {
                showTertiary && (
                    <div className={classes.tertiary}>
                        <span className={classes.label}>
                            {label}
                        </span>
                    </div>
                )
            }
        </div>
    );
};

CalendarEvent.propTypes = {
    className: PropType.string,
    eventInfo: PropType.object,
};

export default CalendarEvent;
