import React, { useCallback, useEffect, useState } from "react";
import CheckInAccomodation from "./CheckInAccomodation";
import CheckInAccomodationAdd from "./CheckInAccomodationAdd";
import CheckInAccomodationEdit from "./CheckInAccomodationEdit";
import CheckInErrors from "./CheckInErrors";
import CheckInIcon from "@material-ui/icons/FlightTakeoff";
import CheckInPassengerEdit from "./CheckInPassengerEdit";
import PassengerType from "../../passenger/PassengerType";
import { List, Typography, makeStyles } from "@material-ui/core";
import { UpdateWithFormButton } from "../../../component/button";
import { initBookingAccomodations, initBookingPassengers } from "../bookingUtils";
import { useDataProvider, useLocale, useNotify, useTranslate, useVersion } from "react-admin";
import { dateTimeOptions } from "../../../util/dates";

const useStyles = makeStyles(theme => ({
    list: {
        width: "480px",
        maxWidth: "100%",
        "& .MuiListItem-root": {
            [theme.breakpoints.down("sm")]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
    bold: {
        fontWeight: 700,
    },
    flex: {
        alignItems: "center",
        display: "flex",
    },
    copy: {
        marginLeft: "auto",
    },
    subheader: {
        alignItems: "center",
        display: "flex",
        width: "100%",
    },
    avatar: {
        backgroundColor: "#ffb700",
        color: "#222",
    },
    subheaderActions: {
        marginLeft: "auto",
    },
    subheaderAvatar: {
        marginRight: theme.spacing(1),
    },
    icon: {
        marginLeft: theme.spacing(1),
    },
    error: {
        color: "#f44336",
        textAlign: "left",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
    },
}));

const BookingCheckInButton = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const locale = useLocale();
    const notify = useNotify();
    const translate = useTranslate();
    const version = useVersion();
    const { record } = props;
    const { checkInAt, emailAddress, locator, requiresInstructor } = record;
    const [accomodation, setAccomodation] = useState(null);
    const [accomodations, setAccomodations] = useState(initBookingAccomodations(record));
    const [accomodationOffset, setAccomodationOffset] = useState(-1);
    const [passenger, setPassenger] = useState(null);
    const [passengers, setPassengers] = useState(initBookingPassengers(record));
    const [passengerOffset, setPassengerOffset] = useState(-1);
    const [stock, setStock] = useState([]);
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const date = new Date(Date.parse(checkInAt) + 3600000);
    const initialValues = { locator, emailAddress, passengers, accomodations };
    
    const handleExitEditAccomodation = () => setAccomodation(null);
    const handleEnterEditAccomodation = offset => () => {
        setAccomodationOffset(offset);
        setAccomodation(accomodations[offset]);
    };

    const handleDeleteAccomodation = offset => () => {
        const newAccomodations = [...accomodations];
        newAccomodations[offset] = { pristine: true };
        setAccomodations(newAccomodations);
        notify("booking.message.accomodationRemoved");
    };

    const handleExitEditPassenger = () => setPassenger(null);
    const handleEnterEditPassenger = offset => () => {
        setPassengerOffset(offset);
        setPassenger(passengers[offset]);
    };

    const handleDeletePassenger = offset => () => {
        const newPassengers = [...passengers];
        newPassengers[offset] = { pristine: true };
        setPassengers(newPassengers);
        notify("booking.message.passengerRemoved");
    };

    const handleClose = () => {
        const handlingAccomodation = !! accomodation;
        const handlingPassenger = !! passenger;
        const handle = handlingPassenger || handlingAccomodation;

        if (handlingPassenger) {
            handleExitEditPassenger();
        }

        if (handlingAccomodation) {
            handleExitEditAccomodation();
        }

        return handle;
    };

    const handleSubmit = (values, update) => {
        if (passenger) {
            const newPassenger = values.passengers[passengerOffset];
            const newPassengers = [...passengers];

            newPassengers[passengerOffset] = { ...newPassenger, pristine: false };
            setPassengers(newPassengers);

            const newAccomodation = values.accomodations[accomodationOffset];
            const newAccomodations = [...accomodations];

            newAccomodation.driverType = newPassenger.licenseNumber ? PassengerType.LICENSED : PassengerType.UNLICENSED;

            if (! newAccomodation.passengers) {
                newAccomodation.passengers = [];
            }

            if (newAccomodation.passengers.indexOf(passengerOffset) === -1) {
                newAccomodation.passengers.push(passengerOffset);
            }

            setAccomodations(newAccomodations);

            handleExitEditPassenger();
            notify("booking.message.passengerSaved");

        } else if (accomodation) {
            const newAccomodation = values.accomodations[accomodationOffset];
            const newAccomodations = [...accomodations];

            if (values.accomodations[accomodationOffset].instructor) {
                newAccomodation.driverType = PassengerType.INSTRUCTOR;
                newAccomodation.passengers = ["I"];
            }

            newAccomodations[accomodationOffset] = { ...newAccomodation, pristine: false };
            setAccomodations(newAccomodations);
            handleExitEditAccomodation();
            notify("booking.message.accomodationSaved");

        } else {
            update();
        }
    };

    const handleCopy = customer => { console.log("HandleCopy()", customer);
        const {
            emailAddress,
            firstName,
            lastName,
            dateOfBirth,
            idCardNumber,
            licenseNumber,
        } = customer;

        const newPassengers = [...passengers];
        newPassengers[0] = {
            emailAddress,
            firstName,
            lastName,
            dateOfBirth,
            idCardNumber,
            licenseNumber,
            pristine: false,
        };

        setPassengers(newPassengers);
        handleEnterEditPassenger(0);
    };

    const fetchStock = useCallback(async () => {
        try {
            const { data } = await dataProvider.getList("stock", {});
            setStock(data);
        } catch (e) {
            notify("booking.message.stockNotAvailable", "warning", { message: e.message });
        }
    }, [dataProvider, notify]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchStock();
    }, [version, fetchStock]);

    if (! record) {
        return null;
    }


    const validateCheckIn = record => values => {
        const errors = {};

        if (passenger || accomodation) {
            return errors;
        }

        if (! accomodations) {
            errors.prop = "ra.validation.required";
        } else {
            if (record.requiresInstructor) {
                let instructorFound = false;

                for (const accomodation of accomodations) {
                    const { driverType, pristine } = accomodation;

                    if (! pristine && driverType === PassengerType.INSTRUCTOR) {
                        instructorFound = true;
                        break;
                    }
                }

                if (! instructorFound) {
                    errors.prop = "ra.validation.missingInstructor";
                }
            }
        }

        if (! values.passengers) {
            errors.prop = "ra.validation.required";
        } else {
            let numPassengers = 0;

            for (const passenger of values.passengers) {
                const { pristine } = passenger;

                if (! pristine) {
                    numPassengers ++;
                }
            }

            if (numPassengers < record.minPassengers) {
                errors.prop = "ra.validation.minPassengers";
            } else if (numPassengers > record.maxPassengers) {
                errors.prop = "ra.validation.maxPassengers";
            }
        }

        return errors;
    };

    return (
        <UpdateWithFormButton
            key={version}
            action="checkin"
            icon={<CheckInIcon />}
            initialValues={initialValues}
            path="checkin/"
            resource="booking"
            validate={validateCheckIn(record)}
            onClose={handleClose}
            onSubmit={handleSubmit}
            {...props}
        >
            {
                date && (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        {
                            requiresInstructor && (
                                <>
                                    <span className={classes.bold}>{translate("booking.message.requiresInstructor")}</span>
                                    {". "}
                                </>
                            )
                        }
                        {translate("booking.message.checkInUntil", {
                            date: dateTimeFormatter.format(date),
                        })}
                    </Typography>
                )
            }
            <CheckInErrors classes={classes} />
            {
                passenger ? (
                    <CheckInPassengerEdit
                        classes={classes}
                        offset={passengerOffset}
                        onCopy={handleCopy}
                    />
                ) : accomodation ? (
                    <CheckInAccomodationEdit
                        accomodations={accomodations}
                        classes={classes}
                        offset={accomodationOffset}
                        stock={stock}
                    />
                ) : (
                    <List className={classes.list}>
                        {
                            accomodations.map((accomodation, i) => accomodation.pristine ? (
                                <CheckInAccomodationAdd
                                    key={`accomodation-${i}`}
                                    onEdit={handleEnterEditAccomodation(i)}
                                />
                            ) : (
                                <CheckInAccomodation
                                    key={`accomodation-${i}`}
                                    accomodation={accomodation}
                                    accomodations={accomodations}
                                    classes={classes}
                                    offset={i}
                                    passengers={passengers}
                                    onDeleteAccomodation={handleDeleteAccomodation(i)}
                                    onDeletePassenger={handleDeletePassenger}
                                    onEditAccomodation={handleEnterEditAccomodation(i)}
                                    onEditPassenger={handleEnterEditPassenger}
                                />
                            ))
                        }
                    </List>
                )
            }
        </UpdateWithFormButton>
    );
};

export default BookingCheckInButton;
