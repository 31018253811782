import React from "react";
import PropTypes from "prop-types";
import { IdentityCreateCredentialsButton } from "./button";
import { ListButton, TopToolbar } from "react-admin";

const IdentityShowActions = ({ basePath }) => (
    <TopToolbar>
        <IdentityCreateCredentialsButton />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

IdentityShowActions.propTypes = {
    basePath: PropTypes.string,
};

export default IdentityShowActions;
