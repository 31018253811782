import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { SelectInput, useDataProvider, useLocale, useNotify, useVersion } from "react-admin";
import { Typography, makeStyles } from "@material-ui/core";
import { useForm } from "react-final-form";

const useStyles = makeStyles({
    select: {
        "& .MuiTypography-root.MuiTypography-body2.MuiTypography-colorTextSecondary": {
            display: "none",
            visibility: "hidden",
        },
    },
    option: {
        "& p": {
            display: "block",
        },
    },
});

const OrderProductText = ({ className, locale, record }) => {
    if (! record || ! record.id) {
        return null;
    }

    const {
        labels,
        maxDuration,
        maxPassengers,
        minDuration,
        minPassengers,
    } = record;

    let optionText;

    for (const label of labels) {
        if (locale === label.lang) {
            optionText = label.label;
            break;
        }
    }

    const desc = [];

    if (minDuration !== maxDuration) {
        desc.push(`${minDuration}-${maxDuration} min`);
    } else {
        desc.push(`${minDuration} min`);
    }

    if (minPassengers !== maxPassengers) {
        desc.push(`${minPassengers}-${maxPassengers} pasajeros`);
    } else {
        desc.push(`${minPassengers} pasajeros`);
    }

    return (
        <div className={className}>
            <Typography
                color="textPrimary"
                component="p"
                variant="body1"
            >
                {optionText}
            </Typography>
            <Typography
                color="textSecondary"
                component="p"
                variant="body2"
            >
                {desc.join(" · ")}
            </Typography>
        </div>
    );
};

const BookingProductInput = ({ errorLabel, label, notAvailableLabel, source, value, ...props }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const locale = useLocale();
    const notify = useNotify();
    const version = useVersion();
    const { change } = useForm();
    const [choices, setChoices] = useState([]);

    const handleChange = event => {
        const ref = event.target.value;

        for (const choice of choices) {
            if (ref === choice.ref) {
                change("product", choice);
            }
        }
    };

    const fetchChoices = useCallback(async () => {
        if (! value) {
            return;
        }

        const extra = { d: value };

        try {
            // noinspection JSCheckFunctionSignatures
            const { data } = await dataProvider.getList("booking", { extra, path: "products/" });

            if (! Array.isArray(data) || ! data.length) {
                notify(notAvailableLabel, "warning", { date: value.substr(0, 10) });
            }

            setChoices(data);
        } catch (e) {
            notify(errorLabel, "error", { date: value });
            setChoices([]);
        }
    }, [dataProvider, errorLabel, notAvailableLabel, notify, value]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchChoices();
    }, [version, fetchChoices]);

    return (
        <SelectInput
            className={classes.select}
            choices={choices}
            label={label}
            optionText={
                <OrderProductText
                    className={classes.option}
                    locale={locale}
                />
            }
            optionValue="ref"
            source={source}
            translateChoice={false}
            {...props}
            onChange={handleChange}
        />
    );
};

BookingProductInput.propTypes = {
    errorLabel: PropTypes.string,
    label: PropTypes.string,
    notAvailableLabel: PropTypes.string,
    source: PropTypes.string.isRequired,
    value: PropTypes.any,
};

BookingProductInput.defaultProps = {
    errorLabel: "booking.message.productsError",
    notAvailableLabel: "booking.message.noProductsAvailable",
};

export default BookingProductInput;
