import React from "react";
import { BookingCardConditions, BookingCardDetails, BookingCardTitle } from "../card";
import { FormDataConsumer, useLocale, useTranslate } from "react-admin";
import { WizardFormStep } from "../../../component/wizard";
import { dateOptions, timeOptions } from "../../../util/dates";

const BookingFormStepFour = () => {
    const locale = useLocale();
    const translate = useTranslate();
    const currencyFormatter = new Intl.NumberFormat(locale, { style: "currency", currency: "EUR" });
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);
    const timeFormatter = new Intl.DateTimeFormat(locale, timeOptions);

    return (
        <WizardFormStep label={translate("booking.step.one")}>
            <FormDataConsumer fullWidth>
                {
                    ({ formData }) => formData && (
                        <>
                            <BookingCardTitle
                                addLabel
                                dateFormatter={dateFormatter}
                                timeFormatter={timeFormatter}
                                record={formData}
                            />
                            <BookingCardDetails
                                currencyFormatter={currencyFormatter}
                                record={formData}
                            />
                            <BookingCardConditions
                                currencyFormatter={currencyFormatter}
                                record={formData}
                            />
                        </>
                    )
                }
            </FormDataConsumer>
        </WizardFormStep>
    );
};

export default BookingFormStepFour;
