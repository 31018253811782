import React from "react";
import LabelArrayInput from "../label/LabelArrayInput";
import LabelArrayRichInput from "../label/LabelArrayRichInput";
import { CurrencyInput, PercentageInput } from "../../component/input";
import { Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import config from "../../config";
import { formatBytes } from "../../util/bytes";
import {
    BooleanInput,
    NumberInput,
    FileInput,
    FormDataConsumer,
    FormTab,
    ImageField,
    TabbedForm,
    TabbedFormTabs,
    TextInput,
    required,
    useTranslate,
} from "react-admin";
import { fileSize } from "../../util/validation";

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%",
    },
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const { ingest: { accept, maxSizeHi, maxSizeLo }} = config;

const validate = [required()];

const validateForm = translate => values => {
    let message;
    const errors = {};

    if (! values.posterHiRes) {
        errors.posterHiRes = translate("ra.validation.required");
    } else if ((message = fileSize(maxSizeHi)(values.posterLoRes))) {
        errors.posterLoRes = translate(message);
    }

    if (! values.posterLoRes) {
        errors.posterLoRes = translate("ra.validation.required");
    } else if ((message = fileSize(maxSizeLo)(values.posterLoRes))) {
        errors.posterLoRes = translate(message);
    }

    return errors;
};

const ProductForm = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <TabbedForm
            {...props}
            redirect="show"
            syncWithLocation
            validate={validateForm(translate)}
            tabs={
                <TabbedFormTabs scrollButtons="auto" />
            }
        >
            <FormTab label={translate("product.tab.conditions")}>
                <Grid className={classes.grid} container spacing={1}>
                    <Grid item xs={12}>
                        <TextInput
                            fullWidth
                            label={translate("product.label.ref")}
                            source="ref"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.passengers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.numSkiJets")}
                            min={0}
                            source="numSkiJets"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.numPaddles")}
                            min={0}
                            source="numPaddles"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.minPassengers")}
                            min={0}
                            source="minPassengers"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.maxPassengers")}
                            min={0}
                            source="maxPassengers"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.details")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.minDuration")}
                            min={0}
                            source="minDuration"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.maxDuration")}
                            min={0}
                            source="maxDuration"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <NumberInput
                            fullWidth
                            label={translate("product.label.distance")}
                            min={0}
                            source="distance"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.conditions")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            helperText={translate("product.help.onlyOnDemand")}
                            label={translate("product.label.onlyOnDemand")}
                            source="onlyOnDemand"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            helperText={translate("product.help.onlyIfFavorableWeather")}
                            label={translate("product.label.onlyIfFavorableWeather")}
                            source="onlyIfFavorableWeather"
                        />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.instructorRequired")}
                            source="instructorRequired"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormDataConsumer>
                            {
                                ({ formData }) => formData.numSkiJets > 0 ? (
                                    <BooleanInput
                                        fullWidth
                                        label={translate("product.label.refillRequired")}
                                        source="refillRequired"
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.cancellation")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.cancellable")}
                            source="cancellable"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest}) => formData.cancellable ? (
                                    <NumberInput
                                        defaultValue={48}
                                        helperText={translate("product.help.cancellationLimit")}
                                        label={translate("product.label.cancellationLimit")}
                                        source="cancellationLimit"
                                        validate={validate}
                                        {...rest}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest}) => formData.cancellable ? (
                                    <CurrencyInput
                                        helperText={translate("product.help.cancellationFee")}
                                        label={translate("product.label.cancellationFee")}
                                        source="cancellationFee"
                                        validate={validate}
                                        {...rest}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.changes")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.changeable")}
                            source="changeable"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest}) => formData.changeable ? (
                                    <NumberInput
                                        defaultValue={48}
                                        helperText={translate("product.help.changeLimit")}
                                        label={translate("product.label.changeLimit")}
                                        source="changeLimit"
                                        validate={validate}
                                        {...rest}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.extras")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.includesBasicNavigation")}
                            source="includesBasicNavigation"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.includesLifeJacket")}
                            source="includesLifeJacket"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.includesLockerRoom")}
                            source="includesLockerRoom"
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label={translate("product.tab.fees")}>
                <Grid className={classes.grid} container spacing={1}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.fees")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            label={translate("product.label.residentFee")}
                            source="residentFee"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            label={translate("product.label.domesticFee")}
                            source="domesticFee"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            label={translate("product.label.foreignFee")}
                            source="foreignFee"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.extras")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            label={translate("product.label.picturesFee")}
                            source="picturesFee"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.taxes")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <PercentageInput
                            fullWidth
                            label={translate("product.label.taxes")}
                            source="taxes"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            helperText={translate("product.help.portTaxes")}
                            label={translate("product.label.portTaxes")}
                            source="portTaxes"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            helperText={translate("product.help.additionalTaxes")}
                            label={translate("product.label.additionalTaxes")}
                            source="additionalTaxes"
                            validate={validate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.vouchers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BooleanInput
                            fullWidth
                            label={translate("product.label.allowRedeemVoucher")}
                            source="allowRedeemVoucher"
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label={translate("product.tab.labels")}>
                <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    {translate("product.message.labels")}
                </Typography>
                <LabelArrayInput
                    fullWidth
                    label={false}
                    source="labels"
                />
            </FormTab>
            <FormTab label={translate("product.tab.descriptions")}>
                <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    {translate("product.message.descriptions")}
                </Typography>
                <LabelArrayRichInput
                    fullWidth
                    label={false}
                    source="descriptions"
                />
            </FormTab>
            <FormTab label={translate("product.tab.poster")}>
                <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    {translate("product.message.posters")}
                </Typography>
                <FileInput
                    accept={accept}
                    fullWidth
                    placeholder={
                        <p>
                            <span>{translate("product.help.drop")}</span>
                            <br/>
                            <small>{translate("product.help.poster", { accept, maxSize: formatBytes(maxSizeHi) })}</small>
                        </p>
                    }
                    label={translate("product.label.posterHiRes")}
                    maxSize={maxSizeHi}
                    source="posterHiRes"
                    validate={validate}
                >
                    <ImageField source="url" title="ref" />
                </FileInput>
                <FileInput
                    accept={accept}
                    fullWidth
                    placeholder={
                        <p>
                            <span>{translate("product.help.drop")}</span>
                            <br/>
                            <small>{translate("product.help.poster", { accept, maxSize: formatBytes(maxSizeLo) })}</small>
                        </p>
                    }
                    label={translate("product.label.posterLoRes")}
                    maxSize={maxSizeLo}
                    source="posterLoRes"
                    validate={validate}
                >
                    <ImageField source="url" title="ref" />
                </FileInput>
                <Typography color="textSecondary"  variant="body2">
                    {translate("product.help.optimize")}
                    {" (e.g., "}
                    <a href="https://www.imgboost.com/" rel="noopener noreferrer" target="_blank">this one</a>
                    {")"}
                </Typography>
            </FormTab>
        </TabbedForm>
    );
};

export default ProductForm;
