import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SelectInput, useDataProvider, useLocale, useNotify, useTranslate, useVersion } from "react-admin";
import { timeOptions } from "../../../util/dates";
import { useForm } from "react-final-form";

const OrderCheckInText = ({ label, locale, record }) => {
    const { amount, amountWithCancellation, checkIn, checkOut } = record;
    const timeFormatter = new Intl.DateTimeFormat(locale, timeOptions);
    const currencyFormatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
    });

    return (
        <span>
            {timeFormatter.format(Date.parse(checkIn))}
            {"-"}
            {timeFormatter.format(Date.parse(checkOut))}
            {" ("}
            {currencyFormatter.format(amount)}
            {" / "}
            {currencyFormatter.format(amountWithCancellation)}
            {" "}
            {label}
            {")"}
        </span>
    );
};

const BookingCheckInInput = ({ errorLabel, label, notAvailableLabel, record, source, ...props }) => {
    const dataProvider = useDataProvider();
    const locale = useLocale();
    const notify = useNotify();
    const translate = useTranslate();
    const version = useVersion();
    const { change } = useForm();
    const [choices, setChoices] = useState([]);

    const handleChange = event => {
        const value = event.target.value;

        for (const choice of choices) {
            if (value === choice.checkIn) {
                change("checkIn", choice);
            }
        }
    };

    const fetchChoices = useCallback(async () => {
        const { date, ref, condition } = record;

        if (! date || ! ref || ! condition) {
            setChoices([]);
            return;
        }

        const extra = {
            c: condition,
            d: date,
            l: locale,
            r: ref,
        };

        try {
            // noinspection JSCheckFunctionSignatures
            const { data } = await dataProvider.getList("booking", { extra, path: "query/" });

            if (! Array.isArray(data) || ! data.length) {
                notify(notAvailableLabel, "warning", { ...record });
            }

            setChoices(data);
        } catch (e) {
            notify(errorLabel, "error", { ...record });
            setChoices([]);
        }
    }, [dataProvider, errorLabel, locale, notAvailableLabel, notify, record]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchChoices();
    }, [version, fetchChoices]);

    return (
        <SelectInput
            key={version}
            choices={choices}
            label={label}
            optionText={
                <OrderCheckInText label={translate("booking.message.withCancellation")} locale={locale} />
            }
            optionValue="checkIn"
            source={source}
            translateChoice={false}
            {...props}
            onChange={handleChange}
        />
    );
};

BookingCheckInInput.propTypes = {
    errorLabel: PropTypes.string,
    label: PropTypes.string,
    notAvailableLabel: PropTypes.string,
    record: PropTypes.any,
    source: PropTypes.string.isRequired,
};

BookingCheckInInput.defaultProps = {
    errorLabel: "booking.message.queryError",
    notAvailableLabel: "booking.message.noCheckInAvailable",
    record: {},
};

export default BookingCheckInInput;
