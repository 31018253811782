import React from "react";
import BookingCardPassenger from "./BookingCardPassenger";
import PropTypes from "prop-types";
import { BookingCheckInButton } from "../button";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { isCheckInPossible } from "../bookingUtils";
import { useTranslate } from "react-admin";

const BookingCardPassengers = ({ classes, dateTimeFormatter, record }) => {
    const translate = useTranslate();
    const { checkInAt, checkedInAt, checkedInBy, customer, passengers } = record;
    const limit = new Date(Date.parse(checkInAt) + 60 * 3600 * 1000);
    const isCheckedIn = !! checkedInAt;
    const checked = isCheckedIn ? new Date(Date.parse(checkedInAt)) : null;

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("booking.message.passengerData")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        isCheckedIn ? passengers.map((passenger, i) => passenger.pristine ? null : (
                            <BookingCardPassenger
                                key={i}
                                classes={classes}
                                customer={i === 0 ? customer : null}
                                record={passenger}
                            />
                        )) : isCheckInPossible(record) ? (
                            <BookingCheckInButton
                                record={record}
                                variant="contained"
                            />
                        ) : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        {
                            isCheckedIn ?
                                translate("booking.message.checkedInAt", {
                                    date: dateTimeFormatter.format(checked),
                                    actor: checkedInBy,
                                }) :
                                isCheckInPossible(record) ?
                                    translate("booking.message.pendingCheckIn", {
                                        date: dateTimeFormatter.format(limit),
                                    }) :
                                    translate("booking.message.checkInClosed")
                        }
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    );
}

BookingCardPassengers.propTypes = {
    classes: PropTypes.object,
    dateTimeFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardPassengers.defaultProps = {
    classes: {},
};

export default BookingCardPassengers;
