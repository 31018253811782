const config = {
    env: process.env.NODE_ENV,
    accessToken: process.env.REACT_APP_PUBLIC_TOKEN,
    serviceURL: process.env.REACT_APP_PUBLIC_ENDPOINT,
    version: process.env.REACT_APP_VERSION,
    caching: {
        enabled: true,
        appCache: 30,
    },
    collection: {
        page: 1,
        perPage: 10,
        perPageOptions: [10, 25, 50, 100],
    },
    ingest: {
        accept: "image/jpeg,image/png,image/webp",
        maxSizeHi: 2 * 1024 * 1024,
        maxSizeLo: 1024 * 1024,
    },
    negotiation: {
        accept: "application/hal+json, application/json; q=0.9, */*; q=0.8",
        digests: ["sha256", "sha384", "sha512"],
        useLocale: false,
    },
    supportedLocales: [
        "de",
        "en",
        "es",
        "fr",
        "it",
        "pt",
        "ru",
    ],
};

export default config;
