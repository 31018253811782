import React from "react";
import { BooleanField, Datagrid, NumberField, TextField, useTranslate } from "react-admin";
import { DurationField, MonthField } from "../../component/field";

const CalendarDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid {...props}>
            <NumberField
                label={translate("calendar.label.year")}
                source="year"
            />
            <MonthField
                label={translate("calendar.label.month")}
                source="month"
            />
            <BooleanField
                label={translate("calendar.label.closed")}
                source="closed"
            />
            <TextField
                label={translate("calendar.label.firstCheckInAt")}
                source="firstCheckInAt"
            />
            <TextField
                label={translate("calendar.label.lastCheckInAt")}
                source="lastCheckInAt"
            />
            <DurationField
                label={translate("calendar.label.ist")}
                source="interServiceTime"
                units="min"
            />
            <DurationField
                label={translate("calendar.label.expirationTime")}
                source="expirationTime"
                units="min"
            />
        </Datagrid>
    );
};

export default CalendarDatagrid;
