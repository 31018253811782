import React, { cloneElement }  from "react";
import PropTypes from "prop-types";
import { ExportButton, TopToolbar, sanitizeListRestProps, useListContext } from "react-admin";

const IncidentListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;

    const {
        currentSort,
        resource,
        filterValues,
        total,
    } = useListContext();

    return (
        <TopToolbar
            className={className}
            {...sanitizeListRestProps(rest)}
        >
            {cloneElement(filters, { context: "button" })}
            <ExportButton
                disabled={total === 0}
                filterValues={filterValues}
                maxResults={maxResults}
                resource={resource}
                sort={currentSort}
            />
        </TopToolbar>
    );
};

IncidentListActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

IncidentListActions.defaultProps = {
    data: {},
};

export default IncidentListActions;
