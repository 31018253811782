import React from "react";
import BackIcon from "@material-ui/icons/ArrowBackIos"
import { BackToListButton } from "../../../component/button";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    button: { marginRight: "auto" },
});

const BookingBackButton = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <BackToListButton
            className={classes.button}
            icon={<BackIcon />}
            label={translate("booking.action.back")}
            resource="booking"
            {...props}
        />
    );
};

export default BookingBackButton;
