import React from "react";
import BookingCheckInInput from "./BookingCheckInInput";
import BookingProductInput from "./BookingProductInput";
import { DateInput, FormDataConsumer, required, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import { PassengerConditionInput } from "../../passenger";
import { WizardFormStep } from "../../../component/wizard";

const validate = [required()]

const BookingFormStepOne = () => {
    const translate = useTranslate();

    return (
        <WizardFormStep label={translate("booking.step.one")}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <DateInput
                        fullWidth
                        label={translate("booking.label.date")}
                        source="date"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PassengerConditionInput
                        fullWidth
                        helperText={translate("passenger.help.condition")}
                        label={translate("passenger.label.condition")}
                        validate={validate}
                    />
                </Grid>
                <FormDataConsumer fullWidth>
                    {
                        ({ formData, ...rest }) => (
                            <Grid item xs={12} md={6}>
                                <BookingProductInput
                                    label={translate("booking.label.product")}
                                    source="ref"
                                    validate={validate}
                                    value={formData.date}
                                    {...rest}
                                />
                            </Grid>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {
                        ({ formData, ...rest }) => (
                            <Grid item xs={12} md={6}>
                                <BookingCheckInInput
                                    label={translate("booking.label.checkInAt")}
                                    record={formData}
                                    source="checkInAt"
                                    validate={validate}
                                    {...rest}
                                />
                            </Grid>
                        )
                    }
                </FormDataConsumer>
            </Grid>
        </WizardFormStep>
    );
};

export default BookingFormStepOne;
