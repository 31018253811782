import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip } from "@material-ui/core";
import { SkiJetIcon } from "../../../component/icon";
import { useTranslate } from "react-admin";

const CheckInAccomodationAdd = ({ onEdit }) => {
    const translate = useTranslate();

    return (
        <>
            <Divider component="li" />
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <SkiJetIcon color="white" width={24} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText secondary={translate("ra.action.add_accomodation")} />
                <ListItemSecondaryAction>
                    <Tooltip title={translate("ra.action.add")}>
                        <IconButton
                            aria-label={translate("ra.action.add")}
                            edge="end"
                            onClick={onEdit}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
}

export default CheckInAccomodationAdd;
