import React from "react";
import IncidentDatagrid from "./IncidentDatagrid";
import IncidentFilter from "./IncidentFilter";
import IncidentListActions from "./IncidentListActions";
import IncidentSimpleList from "./IncidentSimpleList";
import IncidentTitle from "./IncidentTitle";
import { List, Responsive } from "react-admin";

const IncidentList = (props) => (
    <List
        actions={<IncidentListActions />}
        bulkActionButtons={false}
        filters={<IncidentFilter />}
        sort={{ field: "occurredAt", order: "DESC" }}
        title={<IncidentTitle />}
        {...props}
    >
        <Responsive
            small={
                <IncidentSimpleList />
            }
            medium={
                <IncidentDatagrid rowClick="show" />
            }
        />
    </List>
);

export default IncidentList;
