import React, {useCallback, useState} from "react";
import DefaultIcon from "@material-ui/icons/Work";
import PropTypes from "prop-types";
import { Button, useNotify, useRefresh, useUpdate, useTranslate } from "react-admin";
import { ModalForm } from "../form";

const UpdateWithFormButton = ({
    action,
    button,
    children,
    failure,
    icon,
    initialValues,
    path,
    record,
    resource,
    success,
    title,
    validate,
    onClose,
    onFailure,
    onSubmit,
    onSuccess,
    ...rest
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const buttonLabel  = button  || `${resource}.action.${action}`;
    const failureLabel = failure || `${resource}.message.${action}Failure`;
    const successLabel = success || `${resource}.message.${action}Success`;
    const titleLabel   = title   || `${resource}.message.${action}Modal`;

    const { id } = record;
    const [ open, setOpen ] = useState(false);
    const [ update, { loading, error }] = useUpdate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        let handled = false;

        if (onClose) {
            handled = onClose();
        }

        if (! handled) {
            setOpen(false);
        }
    };

    const handleSubmit = useCallback(
        values => {
            update(resource, `${id}/${path}`, { ...values }, record, {
                onFailure: error => {
                    notify(failureLabel, 'warning', { id, message: error.message });
                    onFailure(error);
                },
                onSuccess: () => {
                    notify(successLabel, "info", { id });
                    refresh();
                    setOpen(false);
                    onSuccess();
                },
            });
        },
        [
            failureLabel,
            id,
            notify,
            path,
            record,
            refresh,
            resource,
            successLabel,
            update,
            onFailure,
            onSuccess,
        ]
    );

    let actualHandleSubmit;

    if (onSubmit) {
        actualHandleSubmit = values => onSubmit(values, () => {
            handleSubmit(values);
        });
    } else {
        actualHandleSubmit = handleSubmit;
    }

    return (
        <React.Fragment>
            <Button
                label={translate(buttonLabel)}
                disabled={loading || !! error}
                onClick={handleOpen}
                {...rest}
            >
                {icon}
            </Button>
            <ModalForm
                initialValues={initialValues}
                loading={loading}
                open={open}
                title={translate(titleLabel)}
                onClose={handleClose}
                onSubmit={actualHandleSubmit}
                validate={validate}
            >
                {children}
            </ModalForm>
        </React.Fragment>
    )
};

UpdateWithFormButton.propTypes = {
    action: PropTypes.string.isRequired,
    button: PropTypes.string,
    children: PropTypes.node,
    failure: PropTypes.string,
    icon: PropTypes.node,
    initialValues: PropTypes.object,
    path: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    success: PropTypes.string,
    title: PropTypes.string,
    validate: PropTypes.func,
    onClose: PropTypes.func,
    onFailure: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
};

UpdateWithFormButton.defaultProps = {
    icon: (<DefaultIcon />),
    initialValues: {},
    record: {},
    onClose: null,
    onFailure: () => {},
    onSubmit: null,
    onSuccess: () => {},
};

export default UpdateWithFormButton;
