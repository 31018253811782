import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import { TIME_GRID_WEEK } from "./calendarUtil";

const CalendarDateDisplay = ({ calendar, date, dateFormat, locale, monthView, total, ...props }) => {
    const formatter = new Intl.DateTimeFormat(locale, dateFormat);
    const label = formatter.format(date) + (total > 0 ? ` (${total})` : "");

    const handleClick = () => calendar.current.getApi().changeView(monthView);

    return (
        <Button
            aria-label={label}
            label={label}
            onClick={handleClick}
            {...props}
        />
    );
};

CalendarDateDisplay.propTypes = {
    calendar: PropTypes.object.isRequired,
    date: PropTypes.object,
    dateFormat: PropTypes.object,
    locale: PropTypes.string,
    monthView: PropTypes.string,
    total: PropTypes.number,
};

CalendarDateDisplay.defaultProps = {
    dateFormat: { year: "numeric", month: "long" },
    monthView: TIME_GRID_WEEK,
    total: 0,
};

export default CalendarDateDisplay;
