import React from "react";
import { Card, CardContent, Divider, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const CustomerCardSkeleton = () => (
    <Card style={{ marginTop: 72 }}>
        <CardContent>
            <Skeleton
                animation="wave"
                variant="circle"
                width={72}
                height={72}
            />
        </CardContent>
        <CardContent>
            <Skeleton
                animation="wave"
                variant="text"
                width="80%"
            />
        </CardContent>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="75%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        height={128}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="75%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        height={128}
                    />
                </Grid>
            </Grid>
        </CardContent>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="75%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        height={128}
                    />
                </Grid>
            </Grid>
        </CardContent>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="75%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        height={128}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default CustomerCardSkeleton;
