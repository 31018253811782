import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import get from "lodash/get";
import { Box, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { BoxField } from "../../component/box";
import { dateOptions, dateTimeOptions } from "../../util/dates";
import { useDelete, useLocale, useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    box: {
        position: "relative",
    },
    note: {
        marginTop: theme.spacing(2),
    },
    deleteIcon: {
        position: "absolute",
        right: theme.spacing(2),
    },
}));

const StockEventsField = ({ emptyText, source, ...props }) => {
    const classes = useStyles();
    const locale = useLocale();
    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const translate = useTranslate();
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);
    const [ deleteOne, { loading, error }] = useDelete();
    const { id } = record;

    const actuallyDelete = useCallback(
        (i) => {
            deleteOne("stock", id + "/event/" + i, record, {
                onFailure: error => {
                    notify(translate("stock.message.eventDeleteFailure"), "warning", { ...error, id });
                },
                onSuccess: () => {
                    notify(translate("stock.message.eventDeleteSuccess"), "info");
                    refresh();
                },
            });
        }, [
            deleteOne,
            id,
            notify,
            record,
            refresh,
            translate,
        ]
    );

    const handleDelete = i => () => actuallyDelete(i);

    const events = get(record, source);

    if (! Array.isArray(events) || ! events.length) {
        return (
            <div className={classes.root}>
                <Typography color="textPrimary" gutterBottom variant="body2" {...props}>
                    {emptyText}
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {
                events.map((event, i) => {
                    const { date, notes, unavailable, unavailableFrom, unavailableUntil } = event;
                    const from = unavailable ? new Date(Date.parse(unavailableFrom)) : null;
                    const until = unavailable ? new Date(Date.parse(unavailableUntil)) : null;

                    return (
                        <BoxField
                            className={classes.box}
                            key={i}
                            label={
                                <Typography color="textSecondary" variant="body2">
                                    {dateTimeFormatter.format(new Date(Date.parse(date)))}
                                </Typography>
                            }
                        >
                            {
                                unavailable && (
                                    <Tooltip title={translate("stock.action.delete")}>
                                        <IconButton
                                            className={classes.deleteIcon}
                                            disabled={loading || error}
                                            onClick={handleDelete(i)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                            <Typography variant="body2">
                                {notes}
                            </Typography>
                            {
                                unavailable && (
                                    <Typography className={classes.note} variant="body2">
                                        <Box
                                            bgcolor="#c70000"
                                            borderRadius={8}
                                            marginRight={1}
                                            component="span"
                                            display="inline-block"
                                            height={12}
                                            width={12}
                                        />
                                        {translate("event.message.period", {
                                            unavailableFrom: dateFormatter.format(from),
                                            unavailableUntil: dateFormatter.format(until),
                                        })}
                                    </Typography>
                                )
                            }
                        </BoxField>
                    );
                })
            }
        </div>
    )
};

StockEventsField.propTypes = {
    emptyText: PropTypes.string,
    options: PropTypes.object,
};

StockEventsField.defaultProps = {
    source: "events",
};

export default StockEventsField;
