import React from "react";
import PropTypes from "prop-types";
import { SelectInput, useLocale } from "react-admin";

const YearInput = ({ format, length, source, start, ...props }) => {
    const locale = useLocale();
    const formatter = new Intl.DateTimeFormat(locale, { year: format });
    const choices = Array
        .from(Array(length).keys())
        .map((_, i) => ({ id: start + i, name: formatter.format(new Date(Date.UTC(start + i))), }));

    return (
        <SelectInput
            choices={choices}
            source={source}
            translateChoice={false}
            {...props}
        />
    );
};

YearInput.propTypes = {
    format: PropTypes.oneOf(["2-digit", "numeric", "narrow", "short", "long"]),
    length: PropTypes.number,
    source: PropTypes.string.isRequired,
    start: PropTypes.number,
};

YearInput.defaultProps = {
    format: "numeric",
    start: new Date().getFullYear(),
    length: 25,
};

export default YearInput;
