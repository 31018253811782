import IncidentSeverity from "./IncidentSeverity";

const incidentSeverityChoices = [
    { id: IncidentSeverity.LOW, name: "incident.severity.low" },
    { id: IncidentSeverity.MEDIUM, name: "incident.severity.medium" },
    { id: IncidentSeverity.HIGH, name: "incident.severity.high" },
    { id: IncidentSeverity.CRITICAL, name: "incident.severity.critical" },
];

export {
    incidentSeverityChoices,
};
