import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useTranslate } from "react-admin";

const CheckInErrors = ({ classes }) => {
    const { invalid, errors } = useFormState();
    const translate = useTranslate();

    if (! invalid) {
        return null;
    }

    return (
        <Typography
            className={classes.error}
            color="textSecondary"
            gutterBottom
            variant="body2"
        >
            {translate(errors.prop)}
        </Typography>
    );
};

CheckInErrors.propTypes = {
    classes: PropTypes.object,
};

CheckInErrors.defaultProps = {
    classes: {},
};

export default CheckInErrors;
