import React from "react";
import ListIcon from "@material-ui/icons/List"
import { BackToListButton } from "../../../component/button";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    button: { marginRight: "auto" },
});

const BookingListButton = () => {
    const classes = useStyles();
    const translate = useTranslate();

    // Yes, it's actually a link to order.list resource
    return (
        <BackToListButton
            className={classes.button}
            icon={<ListIcon />}
            label={translate("booking.action.list")}
            resource="order"
        />
    );
};

export default BookingListButton;
