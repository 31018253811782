import React from "react";
import CalendarForm from "./CalendarForm";
import CalendarTitle from "./CalendarTitle";
import { Edit } from "react-admin";
import { EditActions } from "../../component/action";

const CalendarEdit = (props) => (
    <Edit
        actions={<EditActions {...props} />}
        title={<CalendarTitle />}
        {...props}
    >
        <CalendarForm />
    </Edit>
);

export default CalendarEdit;
