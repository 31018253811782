import React, {useCallback, useState} from "react";
import DefaultIcon from "@material-ui/icons/Work";
import PropTypes from "prop-types";
import { Button, Confirm, useNotify, useRefresh, useTranslate, useUpdate } from "react-admin";

const UpdateButton = ({
    action,
    button,
    failure,
    confirm,
    icon,
    path,
    record,
    resource,
    success,
    title,
    onFailure,
    onSuccess,
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const buttonLabel  = button  || `${resource}.action.${action}`;
    const confirmLabel = confirm || `${resource}.message.${action}Label`;
    const failureLabel = failure || `${resource}.message.${action}Failure`;
    const successLabel = success || `${resource}.message.${action}Success`;
    const titleLabel   = title   || `${resource}.message.${action}Modal`;

    const { id } = record;
    const [ open, setOpen ] = useState(false);
    const [ update, { loading, error }] = useUpdate();
    const actualPath = ! path ? id : typeof path === "function" ? path(record) : `${id}/${path}`;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleUpdate = useCallback(
        () => {
            update(resource, actualPath, record, record, {
                onFailure: error => {
                    notify(failureLabel, "warning", { ...error, id });
                    onFailure(error);
                },
                onSuccess: () => {
                    notify(successLabel, "info", { id });
                    refresh();
                    setOpen(false);
                    onSuccess();
                },
            });
        },
        [
            actualPath,
            failureLabel,
            id,
            notify,
            record,
            refresh,
            resource,
            successLabel,
            update,
            onFailure,
            onSuccess,
        ]
    );

    return (
        <React.Fragment>
            <Button
                label={translate(buttonLabel)}
                disabled={loading || error}
                onClick={handleOpen}
            >
                {icon}
            </Button>
            <Confirm
                CancelIcon={"span"}
                ConfirmIcon={"span"}
                content={translate(confirmLabel)}
                isOpen={open}
                loading={loading}
                title={translate(titleLabel)}
                onClose={handleClose}
                onConfirm={handleUpdate}
            />
        </React.Fragment>
    )
};

UpdateButton.propTypes = {
    action: PropTypes.string.isRequired,
    button: PropTypes.string,
    failure: PropTypes.string,
    confirm: PropTypes.string,
    icon: PropTypes.node,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    success: PropTypes.string,
    title: PropTypes.string,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
};

UpdateButton.defaultProps = {
    record: {},
    icon: (<DefaultIcon />),
    initialValues: {},
    onFailure: () => {},
    onSuccess: () => {},
};

export default UpdateButton;
