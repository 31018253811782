import React from "react";
import PassengerConditionInput from "./PassengerConditionInput";
import PropTypes from "prop-types";
import { ArrayInput, DateInput, SimpleFormIterator, TextInput, required, useTranslate } from "react-admin";
import { dateOptions } from "../../util/dates";

const validate = [required()];

const PassengerArrayInput = ({ source, label }) => {
    const translate = useTranslate();

    return (
        <ArrayInput
            label={label}
            source={source}
        >
            <SimpleFormIterator>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.firstName")}
                    source="firstName"
                    validate={validate}
                />
                <TextInput
                    fullWidth
                    label={translate("passenger.label.lastName")}
                    source="lastName"
                    validate={validate}
                />
                <DateInput
                    fullWidth
                    label={translate("passenger.label.dateOfBirth")}
                    options={dateOptions}
                    source="dateOfBirth"
                    validate={validate}
                />
                <TextInput
                    fullWidth
                    label={translate("passenger.label.idCardType")}
                    source="idCardType"
                />
                <TextInput
                    fullWidth
                    label={translate("passenger.label.idCardNumber")}
                    source="idCardNumber"
                />
                <PassengerConditionInput
                    fullWidth
                    label={translate("passenger.label.condition")}
                    source="condition"
                />
                <TextInput
                    fullWidth
                    label={translate("passenger.label.licenseNumber")}
                    source="licenseNumber"
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

PassengerArrayInput.propTypes = {
    label: PropTypes.string,
    source: PropTypes.string,
};

export default PassengerArrayInput;
