import IncidentIcon from '@material-ui/icons/Error';
import IncidentList from "./IncidentList";
import IncidentShow from "./IncidentShow";

const incident = {
    list: IncidentList,
    show: IncidentShow,
    icon: IncidentIcon,
}

export default incident;
