import StockIcon from '@material-ui/icons/Widgets';
import StockCreate from './StockCreate';
import StockList from "./StockList";
import StockShow from "./StockShow";
import StockEdit from "./StockEdit";

const stock = {
    create: StockCreate,
    edit: StockEdit,
    icon: StockIcon,
    list: StockList,
    show: StockShow,
}

export default stock;
