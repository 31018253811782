import OrderStatus from "../order/OrderStatus";

const getBookingDate = (dateString, timeString) => {
    const [hours, mins] = timeString.split(":");
    const date = new Date(Date.parse(dateString));
    date.setHours(hours);
    date.setMinutes(mins);

    return date;
};

const getBookingDeadline = (date, limitInHours) => {
    const deadline = new Date(date);
    deadline.setHours(deadline.getHours() - limitInHours);

    return deadline;
};

const getBookingEvents = (record, formatter) => {
    const events = [];
    const {
        cancelledAt,
        cancelledBy,
        changedAt,
        checkInAt,
        checkInType,
        changedBy,
        checkedInAt,
        checkedInBy,
        checkedOutAt,
        checkedOutBy,
        droppedAt,
        droppedBy,
        expiredAt,
        paymentMethod,
        previousCheckInAt,
        purchasedAt,
        refundedAt,
    } = record;

    if (cancelledAt) {
        events.push({
            type: "warning",
            label: "cancelled",
            date: new Date(Date.parse(cancelledAt)),
            actor: cancelledBy,
            formatted: {
                cancelledAtFormatted: formatter.format(new Date(Date.parse(cancelledAt))),
            },
        });
    }

    if (changedAt) {
        events.push({
            type: "info",
            label: "changed",
            date: new Date(Date.parse(changedAt)),
            actor: changedBy,
            formatted: {
                checkInAtFormatted: formatter.format(new Date(Date.parse(checkInAt))),
                previousCheckInAtFormatted: formatter.format(new Date(Date.parse(previousCheckInAt))),
            },
        });
    }

    if (checkedInAt) {
        if (checkInType === "staff") {
            events.push({
                type: "success",
                label: "checkedIn",
                date: new Date(Date.parse(checkedInAt)),
                actor: checkedInBy,
            });
        } else if (checkInType === "customer") {
            events.push({
                type: "success",
                label: "checkedIn",
                date: new Date(Date.parse(checkedInAt)),
                actor: "customer",
            });
            events.push({
                type: "success",
                label: "checkInEmailSent",
                date: new Date(Date.parse(purchasedAt)),
                actor: "system",
            });
        }
    }

    if (checkedOutAt) {
        events.push({
            type: "success",
            label: "checkedOut",
            date: new Date(Date.parse(checkedOutAt)),
            actor: checkedOutBy,
        });
    }

    if (droppedAt) {
        events.push({
            type: "warning",
            label: "dropped",
            date: new Date(Date.parse(droppedAt)),
            actor: droppedBy,
        });
    }

    if (expiredAt) {
        events.push({
            type: "warning",
            label: "expired",
            date: new Date(Date.parse(expiredAt)),
        });
    }

    if (purchasedAt) {
        events.push({
            type: "success",
            label: "purchased",
            date: new Date(Date.parse(purchasedAt)),
            actor: "customer",
        });
        events.push({
            type: "success",
            label: "confirmationEmailSent",
            date: new Date(Date.parse(purchasedAt)),
            actor: "system",
        });

        if (paymentMethod === "card") {
            events.push({
                type: "success",
                label: "invoiced",
                date: new Date(Date.parse(purchasedAt)),
                actor: "system",
            });
        }
    }

    if (refundedAt) {
        events.push({
            type: "warning",
            label: "refunded",
            date: new Date(Date.parse(refundedAt)),
            actor: "system",
        });
    }

    return events.sort((a, b) => a < b ? -1 : 1);
};

const initBookingAccomodations = record => {
    if (! record) {
        return [];
    }

    const { accomodations, numSkiJets, requiresInstructor } = record;
    const minLength = numSkiJets + (requiresInstructor ? 1 : 0);

    while (accomodations.length < minLength) {
        accomodations.push({ pristine: true });
    }

    return accomodations;
};

const initBookingPassengers = record => {
    if (! record) {
        return [];
    }

    const { passengers, maxPassengers } = record;

    while (passengers.length < maxPassengers) {
        passengers.push({ pristine: true });
    }

    return passengers;
};

const isCancelPossible = record => {
    const {
        cancellable,
        cancellableUntil,
        cancelledAt,
        status,
    } = record;

    return cancellable
        && status === OrderStatus.PENDING_CHECK_IN
        && ! cancelledAt
        && !! cancellableUntil
        && Date.parse(cancellableUntil) > Date.now();
};

const isChangePossible = record => {
    const {
        changeable,
        changeableUntil,
        status,
    } = record;

    return changeable
        && status === OrderStatus.PENDING_CHECK_IN
        && !! changeableUntil
        && Date.parse(changeableUntil) > Date.now();
};

const isCheckInPossible = record => {
    const { status } = record;

    // Note we DO NOT check for checkedInAt property, as we allow
    // to perform check-in again, while non-expired, cancelled, or
    // refunded
    return [
        OrderStatus.PENDING_CHECK_IN,
        OrderStatus.DROPPED,
        OrderStatus.CHECKED_IN,
    ].indexOf(status) > -1;
};

const isCheckOutPossible = record => {
    const { checkedOutAt, status } = record;

    return status === OrderStatus.CHECKED_IN
        && ! checkedOutAt;
};

const isDropPossible = record => {
    const { status } = record;

    return status === OrderStatus.PENDING_CHECK_IN;
};

export {
    getBookingDate,
    getBookingDeadline,
    getBookingEvents,
    initBookingAccomodations,
    initBookingPassengers,
    isCancelPossible,
    isChangePossible,
    isCheckInPossible,
    isCheckOutPossible,
    isDropPossible,
}
