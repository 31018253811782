import React, { useState } from "react";
import PropTypes from "prop-types";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const SignOn = ({ signIn, signUp }) => {
    const [state, setState] = useState({
        username: null,
        signingUp: false,
    });

    const { signingUp, username } = state;

    const handleSignIn = () => setState({ ...state, signingUp: false });
    const handleSignUp = () => setState({ ...state, signingUp: true });
    const handleSignedUp = profile => {
        const { username } = profile;
        setState({ username, signingUp: false });
    };

    return signingUp ? (
        <SignUp
            signUp={signUp}
            onSignIn={handleSignIn}
            onSignUp={handleSignedUp}
        />
    ) : (
        <SignIn
            signIn={signIn}
            signUp={signUp}
            username={username}
            onSignUp={handleSignUp}
        />
    );
};

SignOn.propTypes = {
    signIn: PropTypes.object,
    signUp: PropTypes.any,
};

SignOn.defaultProps = {
    signIn: {},
    signUp: false,
};

export default SignOn;
