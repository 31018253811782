import BookingCardHistoryEntryType from "./BookingCardHistoryEntryType";
import PropTypes from "prop-types";
import React from "react";
import { BoxField } from "../../../component/box";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        display: "flex",
    },
});

const BookingCardHistoryEntry = ({ actor, classes, date, notes, type }) => {
    const styles = useStyles();

    return (
        <BoxField className={classes.mt} fullWidth label={
            <div className={styles.root}>
                <BookingCardHistoryEntryType size={12} type={type} />
                <Typography
                    color="textPrimary"
                    component="span"
                    variant="body2"
                >
                    {actor}
                </Typography>
                <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                >
                    {", "}
                    {date}
                </Typography>
            </div>
        }>
            <Typography variant="body2">
                {notes}
            </Typography>
        </BoxField>
    );
};

BookingCardHistoryEntry.propTypes = {
    actor: PropTypes.string,
    classes: PropTypes.object,
    date: PropTypes.string,
    notes: PropTypes.any,
    type: PropTypes.oneOf(["error", "info", "success", "warning"]),
};

BookingCardHistoryEntry.defaultProps = {
    classes: {},
};

export default BookingCardHistoryEntry;
