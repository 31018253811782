import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { getAge } from "../../../util/dates";
import { useTranslate } from "react-admin";
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography
} from "@material-ui/core";

const CheckInPassenger = ({ classes, driver = false, passenger, onDelete, onEdit }) => {
    const translate = useTranslate();
    const {
        dateOfBirth,
        firstName,
        lastName,
        licenseNumber,
    } = passenger;

    if (! firstName || ! lastName || ! dateOfBirth) {
        return null;
    }

    const age = getAge(dateOfBirth);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {firstName.charAt(0).toUpperCase()}
                    {lastName.charAt(0).toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography component="p" variant="body1">
                        {firstName}
                        {" "}
                        {lastName}
                    </Typography>
                }
                secondary={
                    <Typography color="textSecondary" component="p" variant="body2">
                        {
                            driver ?
                                translate("accomodation.label.driver") :
                                translate("accomodation.label.companion")
                        }
                        {" • "}
                        {
                            licenseNumber ?
                                translate("passenger.type.licensed", { licenseNumber }) :
                                translate("passenger.type.unlicensed")
                        }
                        {" • "}
                        {translate("booking.message.age", { age })}
                    </Typography>
                }
            />
            <ListItemSecondaryAction>
                {
                    onDelete && (
                        <Tooltip title={translate("ra.action.delete")}>
                            <IconButton
                                aria-label={translate("ra.action.delete")}
                                className={classes.icon}
                                edge="end"
                                onClick={onDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                <Tooltip title={translate("ra.action.edit")}>
                    <IconButton
                        aria-label={translate("ra.action.edit")}
                        className={classes.icon}
                        edge="end"
                        onClick={onEdit}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default CheckInPassenger;
