const dateOptions = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
const dateMinOptions = { weekday: "short", day: "numeric" };
const dateTimeOptions = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", timezone: "Atlantic/Canary" };
const dateTimeMinOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", timezone: "Atlantic/Canary" };
const dateTimeFullOptions = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", timezone: "Atlantic/Canary" };
const timeOptions = { hour: "2-digit", minute: "2-digit", meridiem: false, hour12: false };
const hourOptions = { hour: "2-digit", minute: "2-digit" };

const getAge = date => Math.max(0, new Date().getFullYear() - new Date(Date.parse(date)).getFullYear());
const getDate = date => date instanceof Date ? date : new Date(Date.parse(date));

const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const getFormattedDate = (date, prefomattedDate = false, hideYear = false) => {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();

    let minutes = date.getMinutes();

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${ minutes }`;
    }

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${ prefomattedDate } at ${ hours }:${ minutes }`;
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${ day }. ${ month } at ${ hours }:${ minutes }`;
    }

    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
};

const timeAgo = (translate, dateParam) => {
    if (! dateParam) {
        return null;
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();


    if (seconds < 5) {
        return translate("ra.time.now");
    } else if (seconds < 60) {
        return translate("ra.time.secondsAgo", { seconds });
    } else if (seconds < 90) {
        return translate("ra.time.oneMinuteAgo");
    } else if (minutes < 60) {
        return translate("ra.time.minutesAgo", { minutes });
    } else if (isToday) {
        return translate("ra.time.today", { date: getFormattedDate(date) }); // Today at 10:20
    } else if (isYesterday) {
        return translate("ra.time.yesterday", { date: getFormattedDate(date) }); // Yesterday at 10:20
    } else if (isThisYear) {
        return translate("ra.time.date", { date: getFormattedDate(date, false, true) }); // 10. January at 10:20
    }

    return getFormattedDate(date); // 10. January 2017. at 10:20
};

export {
    dateOptions,
    dateMinOptions,
    dateTimeMinOptions,
    dateTimeOptions,
    dateTimeFullOptions,
    hourOptions,
    timeOptions,
    getAge,
    getDate,
    timeAgo,
}
