import React from "react";
import BookingCancelCauseInput from "../BookingCancelCauseInput";
import CancelIcon from "@material-ui/icons/Cancel";
import { DateTimeInput, TextInput, required, useLocale, useTranslate } from "react-admin";
import { Typography } from "@material-ui/core";
import { UpdateWithFormButton } from "../../../component/button";
import { dateTimeOptions } from "../../../util/dates";

const validate = [required()];

const BookingCancelButton = props => {
    const locale = useLocale();
    const translate = useTranslate();
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const { record } = props;
    const { cancellableUntil, emailAddress, locator } = record;
    const cancelledAt = new Date();
    const date = new Date(Date.parse(cancellableUntil));

    return (
        <UpdateWithFormButton
            action="cancel"
            icon={<CancelIcon />}
            initialValues={{
                cancelledAt,
                emailAddress,
                locator,
            }}
            path="cancel/"
            resource="booking"
            {...props}
        >
            {
                date && (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        {translate("booking.message.cancellableUntil", {
                            date: dateTimeFormatter.format(date),
                        })}
                    </Typography>
                )
            }
            <DateTimeInput
                fullWidth
                label={translate("booking.label.cancelledAt")}
                source="cancelledAt"
                options={dateTimeOptions}
            />
            <BookingCancelCauseInput
                fullWidth
                label={translate("booking.label.cancellationCause")}
                source="cancellationCause"
                validate={validate}
            />
            <TextInput
                fullWidth
                label={translate("booking.label.cancellationNotes")}
                multiline
                resettable
                source="cancellationNotes"
            />
        </UpdateWithFormButton>
    );
};

export default BookingCancelButton;
