import React from "react";
import StockType from "../../stock/StockType";
import { BooleanInput, SelectInput, required, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";

const filterStock = (stock, accomodations, offset) => {
    const skipPlateNumbers = [];

    for (const accomodation of accomodations) {
        const { plateNumber, pristine } = accomodation;

        if (! pristine && plateNumber && accomodations.indexOf(accomodation) !== offset) {
            skipPlateNumbers.push(plateNumber);
        }
    }

    const choices = [];

    for (const record of stock) {
        const { brand, currentlyAvailable, model, plateNumber, type } = record;

        if (! currentlyAvailable) {
            continue;
        }

        if (type !== StockType.SKI_JET || skipPlateNumbers.indexOf(plateNumber) > -1) {
            continue;
        }

        choices.push({
            id: plateNumber,
            name: `${plateNumber} (${brand} ${model})`,
        });
    }

    return choices;
};

const validate = [required()];

const CheckInAccomodationEdit = ({ accomodations, offset, stock }) => {
    const translate = useTranslate();
    const choices = filterStock(stock, accomodations, offset);

    let isInstructorSwitchEnabled = true;

    for (const accomodation of accomodations) {
        const { pristine, instructor } = accomodation;

        if (! pristine && instructor && offset !== accomodations.indexOf(accomodation)) {
            isInstructorSwitchEnabled = false;
            break;
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SelectInput
                    fullWidth
                    choices={choices}
                    label={translate("accomodation.label.stock")}
                    source={`accomodations.${offset}.plateNumber`}
                    validate={validate}
                />
            </Grid>
            {
                isInstructorSwitchEnabled && (
                    <Grid item xs={12}>
                        <BooleanInput
                            fullWidth
                            label={translate("accomodation.label.instructor")}
                            source={`accomodations.${offset}.instructor`}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
}

export default CheckInAccomodationEdit;
