import React from "react";
import config from "../../config";
import { SelectInput } from "react-admin";

const LanguageInput = props => {
    const { supportedLocales } = config;
    const choices = supportedLocales.map(locale => ({ id: locale, name: locale }));

    return (
        <SelectInput
            label="Lenguaje"
            choices={choices}
            {...props}
        />
    );
};

export default LanguageInput;
