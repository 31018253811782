import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    fullWidth: {
        width: "100%",
        maxWidth: "100%",
    },
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    box: {
        alignItems: "stretch",
        backgroundColor: "#edf3f0",
        borderRadius: 10,
        display: "flex",
        padding: theme.spacing(2),
    },
    dark: {
        backgroundColor: "#757575",
        color: theme.palette.primary,
    },
    dense: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    text: {
        flex: 1,
        "& p": {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            lineHeight: 1.3,
        },
    },
}));

const BoxField = ({ className, children, dense, fullWidth, label }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classnames(classes.root, className, {
            [classes.fullWidth]: fullWidth,
        })}>
            {
                label && (
                    <div className={classes.label}>
                        {label}
                    </div>
                )
            }
            <div className={classnames(classes.box, {
                [classes.dense]: dense,
                [classes.dark]: theme.palette.type === "dark",
            })}>
                <div className={classes.text}>
                    {children}
                </div>
            </div>
        </div>
    );
};

BoxField.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    dense: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

BoxField.defaultProps = {
    addLabel: false,
    dense: false,
    fullWidth: false,
};

export default BoxField;
