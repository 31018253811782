import { Children, useCallback, useState } from "react";
import { FingerprintContext } from "./FingerprintContext";

const FingerprintProvider = (props) => {
    const { fingerprintProvider, children } = props;

    const [state, setState] = useState({
        fingerprint: null,
        fingerprintProvider,
    });

    useCallback(async () => {
        const newFingerprint = await fingerprintProvider.getFingerprint();
        setState({ ...state, fingerprint: newFingerprint });
    }, [state, setState, fingerprintProvider]);

    return (
        <FingerprintContext.Provider value={state}>
            {Children.only(children)}
        </FingerprintContext.Provider>
    )
};

export default FingerprintProvider;
