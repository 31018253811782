import React from "react";
import { NumberInput } from "react-admin";
import { InputAdornment } from "@material-ui/core";

const PercentageInput = props => {
    const { label, source, ...rest } = props;

    return (
        <NumberInput
            label={label}
            min={0}
            max={1}
            step={0.01}
            source={source}
            {...rest}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        {"%"}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PercentageInput;
