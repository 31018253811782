import React from "react";
import CustomUserMenu from "./CustomUserMenu";
import Logo from "./Logo";
import Typography from "@material-ui/core/Typography";
import { AppBar } from "react-admin";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { ToggleThemeButton } from "./button";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    spacer: {
        flex: 1,
    },
    logo: {
        height: "48px",
        width: "96px",
    },
});

const CustomAppBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.type === "dark" ? "#303030" : "white";
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <AppBar userMenu={<CustomUserMenu />} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {
                isSmall ? null : (<Logo className={classes.logo} color={color} />)
            }
            <span className={classes.spacer} />
            <ToggleThemeButton />
        </AppBar>
    );
};

export default CustomAppBar;
