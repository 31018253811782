import React from "react";
import ProfileEditActions from "./ProfileEditActions";
import ProfileEditForm from "./ProfileEditForm";
import ProfileTitle from "./ProfileTitle";
import { Edit } from "react-admin";

// Note: As this component isn't loaded from a route generated by a
// React-Admin <Resource>, you have to provide the id, resource, and
// basepath props yourself (They're hardcoded here).

// Note: the provided "id" prop should actually match your user id.
const ProfileEdit = (props) => (
    <Edit
        id=""
        actions={<ProfileEditActions />}
        basePath="/profile"
        redirect="show"
        resource="profile"
        title={<ProfileTitle />}
        {...props}
    >
        <ProfileEditForm />
    </Edit>
);

export default ProfileEdit;
