import React from "react";
import LanguageInput from "./LanguageInput";
import PropTypes from "prop-types";
import RichTextInput from "ra-input-rich-text";
import { ArrayInput, SimpleFormIterator, required, useTranslate } from "react-admin";

const validateRequired = [required()];

const LabelArrayRichInput = ({ source, ...props }) => {
    const translate = useTranslate();

    return (
        <ArrayInput
            fullWidth
            source={source}
            {...props}
        >
            <SimpleFormIterator>
                <LanguageInput
                    label={translate("product.label.language")}
                    source="lang"
                    validate={validateRequired}
                />
                <RichTextInput
                    fullWidth
                    label={translate("product.label.label")}
                    multiline
                    resettable
                    source="label"
                    validate={validateRequired}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

LabelArrayRichInput.propTypes = {
    source: PropTypes.string.isRequired,
};

export default LabelArrayRichInput;
