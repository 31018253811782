import React from "react";
import BookingFormStepFour from "./BookingFormStepFour";
import BookingFormStepOne from "./BookingFormStepOne";
import BookingFormStepThree from "./BookingFormStepThree";
import BookingFormStepTwo from "./BookingFormStepTwo";
import PropTypes from "prop-types";
import { WizardForm } from "../../../component/wizard";

const BookingForm = props => {
    const { redirect } = props;
    const handleRedirect = record => redirect === "show" ?
        `/booking/${record.id}/show` :
        `/booking/${record.id}`
    ;

    return (
        <WizardForm
            redirect={handleRedirect}
            {...props}
        >
            <BookingFormStepOne />
            <BookingFormStepTwo />
            <BookingFormStepThree />
            <BookingFormStepFour />
        </WizardForm>
    );
};

BookingForm.propTypes = {
    redirect: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["edit", "show"])]),
};

BookingForm.defaultProps = {
    redirect: "show",
};

export default BookingForm;
