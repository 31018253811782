import React from "react";
import CheckInInstructor from "./CheckInInstructor";
import CheckInPassengerAdd from "./CheckInPassengerAdd";
import CheckInPassenger from "./CheckInPassenger";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Divider, IconButton, List, ListSubheader, Tooltip } from "@material-ui/core";
import { SkiJetIcon } from "../../../component/icon";
import { useTranslate } from "react-admin";

const CheckInAccomodation = ({
    classes = {},
    accomodation,
    accomodations,
    offset,
    passengers,
    onDeleteAccomodation,
    onDeletePassenger,
    onEditAccomodation,
    onEditPassenger,
}) => {
    const translate = useTranslate();
    const { instructor, plateNumber } = accomodation;

    if (! plateNumber) {
        return null;
    }

    let startOffset = 0;

    for (let i = 0; i < offset; i ++) {
        const { pristine, instructor } = accomodations[i];

        if (pristine || ! instructor) {
            startOffset ++;
        }
    }

    return (
        <>
            <Divider component="li" />
            <List
                disablePadding
                subheader={
                    <ListSubheader component="div">
                        <div className={classes.subheader}>
                            <SkiJetIcon className={classes.subheaderAvatar} color="#bdbdbd" width={24} />
                            <span>
                                {plateNumber}
                            </span>
                            <span className={classes.subheaderActions}>
                                <Tooltip title={translate("ra.action.delete")}>
                                    <IconButton
                                        aria-label={translate("ra.action.delete")}
                                        className={classes.icon}
                                        edge="end"
                                        onClick={onDeleteAccomodation}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={translate("ra.action.edit")}>
                                    <IconButton
                                        aria-label={translate("ra.action.edit")}
                                        className={classes.icon}
                                        edge="end"
                                        onClick={onEditAccomodation}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </div>
                    </ListSubheader>
                }
            >
                {
                    instructor ? (
                        <CheckInInstructor classes={classes} />
                    ) :
                    passengers.slice(startOffset, startOffset + 2).map((passenger, i) => {
                        const passengerOffset = startOffset + i;

                        return passenger.pristine ? (
                            <CheckInPassengerAdd
                                key={`passenger-${passengerOffset}`}
                                label={i === 0 ? "passenger.message.driver" : "passenger.message.companion"}
                                onEdit={onEditPassenger(passengerOffset)}
                            />
                        ) : (
                            <CheckInPassenger
                                classes={classes}
                                driver={i === 0}
                                key={`passenger-${passengerOffset}`}
                                passenger={passenger}
                                onDelete={onDeletePassenger(passengerOffset)}
                                onEdit={onEditPassenger(passengerOffset)}
                            />
                        );
                    })
                }
            </List>
        </>
    );
}

export default CheckInAccomodation;
