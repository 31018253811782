import React from "react";
import PropTypes from "prop-types";
import ProfileEditButton from "./ProfileEditButton";
import { TopToolbar } from "react-admin";

const ProfileShowActions = ({ basePath }) => (
    <TopToolbar>
        <ProfileEditButton basePath={basePath} />
    </TopToolbar>
);

ProfileShowActions.propTypes = {
    basePath: PropTypes.string,
};

ProfileShowActions.defaultProps = {
    basePath: "/profile",
};

export default ProfileShowActions;
