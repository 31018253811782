import React from "react";
import { SelectInput } from "react-admin";
import { incidentSeverityChoices } from "./incidentSeverityChoices";

const IncidentSeverityInput = props => (
    <SelectInput
        choices={incidentSeverityChoices}
        {...props}
    />
);

export default IncidentSeverityInput;
