import React, {useState} from "react";
import PropTypes from "prop-types";
import Logo from "./Logo";
import { Field, Form } from "react-final-form";
import { Notification, useAuthProvider, useNotify, useTranslate } from "react-admin";
import { Button, Card, CardActions, CircularProgress, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    actions: {
        flexDirection: "column",
        padding: "0 1em 1em 1em",
    },
    card: {
        minWidth: 400,
        marginTop: "4em",
    },
    link: {
        display: "flex",
        marginTop: "1em",
        justifyContent: "center",
    },
    form: {
        padding: "0 1em 1em 1em",
    },
    input: {
        marginTop: "1em",
    },
    logo: {
        margin: "1em auto",
        display: "flex",
        justifyContent: "center",
        width: "64px",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
});

const getErrorMessageFor = err => typeof err === "string" ? err : err && err.message ? err.message : "ra.signUp.error";
const hasProfileField = (profileFields, field) => -1 !== profileFields.indexOf(field);
const validateProfile = (translate, profileFields) => profile => {
    const errors = {};

    if (hasProfileField(profileFields, "firstname") && ! profile.firstname) {
        errors.firstname = translate("ra.validation.required");
    }

    if (hasProfileField(profileFields, "familyName") && ! profile.familyname) {
        errors.familyname = translate("ra.validation.required");
    }

    if (hasProfileField(profileFields, "gender") && ! profile.gender) {
        errors.gender = translate("ra.validation.required");
    }

    if (hasProfileField(profileFields, "dateOfBirth") && ! profile.dateOfBirth) {
        errors.dateOfBirth = translate("ra.validation.required");
    }

    if (! profile.emailAddress) {
        errors.emailAddress = translate("ra.validation.required");
    }

    if (! profile.uusername) {
        errors.uusername = translate("ra.validation.required");
    }

    if (! profile.plainPassword) {
        errors.plainPassword = translate("ra.validation.required");
    }

    if (! profile.plainPasswordConfirm) {
        errors.plainPasswordConfirm = translate("ra.validation.required");
    } else if (profile.plainPassword && profile.plainPassword !== profile.plainPasswordConfirm) {
        errors.plainPasswordConfirm = translate("ra.validation.passwordMismatch");
    }

    return errors;
};

/*
const renderCheck = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <Checkbox
        fullWidth
        error={!! (touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
    />
);
*/

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        fullWidth
        error={!! (touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
    />
);

const SignUp = ({ profileFields, onSignIn, onSignUp }) => {
    const authProvider = useAuthProvider();
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);

    if (! authProvider.signUp) {
        throw new Error('Authentication provider is expected to provide a signUp() method.');
    }

    const handleSubmit = async (profile) => {
        setLoading(true);

        try {
            const user = await authProvider.signUp(profile);

            notify("ra.signUp.success", "info", { ...user });
            onSignUp(user);
        } catch (err) {
            setLoading(false);
            notify(getErrorMessageFor(err), "warning");
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validateProfile(translate, profileFields)}
            render={
                ({ handleSubmit }) => (
                    <form id="signup" onSubmit={handleSubmit} noValidate>
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <div className={classes.logo}>
                                    <Logo />
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            name="emailAddress"
                                            component={renderInput}
                                            label={translate("ra.signUp.emailAddress")}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="uusername"
                                            component={renderInput}
                                            label={translate("ra.signUp.username")}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="plainPassword"
                                            component={renderInput}
                                            label={translate("ra.signUp.plainPassword")}
                                            type="password"
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="plainPasswordConfirm"
                                            component={renderInput}
                                            label={translate("ra.signUp.plainPasswordConfirm")}
                                            disabled={loading}
                                            type="password"
                                        />
                                    </div>
                                    {
                                        hasProfileField(profileFields, "firstName") ? (
                                            <div className={classes.input}>
                                                <Field
                                                    name="firstName"
                                                    component={renderInput}
                                                    label={translate("ra.signUp.firstName")}
                                                    disabled={loading}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    {
                                        hasProfileField(profileFields, "familyName") ? (
                                            <div className={classes.input}>
                                                <Field
                                                    name="familyName"
                                                    component={renderInput}
                                                    label={translate("ra.signUp.familyName")}
                                                    disabled={loading}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    {
                                        hasProfileField(profileFields, "gender") ? (
                                            <div className={classes.input}>
                                                <Field
                                                    name="gender"
                                                    component={renderInput}
                                                    label={translate("ra.signUp.gender")}
                                                    disabled={loading}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    {
                                        hasProfileField(profileFields, "dateOfBirth") ? (
                                            <div className={classes.input}>
                                                <Field
                                                    name="dateOfBirth"
                                                    component={renderInput}
                                                    label={translate("ra.signUp.dateOfBirth")}
                                                    disabled={loading}
                                                />
                                            </div>
                                        ) : null
                                    }
                                    { /*
                                        <div className={classes.input}>
                                            <Field
                                                name="agreement"
                                                component={renderCheck}
                                                disabled={loading}
                                                label={translate("ra.signUp.agreement")}
                                                helperText={translate("ra.signUp.agreement")}
                                                type="checkbox"
                                            />
                                        </div>
                                    */ }
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                    >
                                        {
                                            loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )
                                        }
                                        {translate("ra.action.signUp")}
                                    </Button>
                                    <Button
                                        className={classes.link}
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                        size="small"
                                        type="button"
                                        onClick={onSignIn}
                                    >
                                        {translate('ra.action.signIn')}
                                    </Button>
                                </CardActions>
                            </Card>
                            <Notification />
                        </div>
                    </form>
                )
            }
        />
    );
};

SignUp.propTypes = {
    profileFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSignUp: PropTypes.func.isRequired,
    onSignIn: PropTypes.func.isRequired,
};

SignUp.defaultProps = {
    profileFields: [],
    onSignUp: () => {},
};

export default SignUp;
