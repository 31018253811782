import BookingCreate from "./BookingCreate";
import BookingIcon from "@material-ui/icons/Label";
import BookingList from "./BookingList";
import BookingShow from "./BookingShow";

const booking = {
    list: BookingList,
    create: BookingCreate,
    icon: BookingIcon,
    show: BookingShow,
}

export default booking;
