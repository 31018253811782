import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useLocale, useRecordContext } from "react-admin";

const CalendarDateField = ({ options, ...rest }) => {
    const locale = useLocale();
    const record = useRecordContext();
    const formatter = new Intl.DateTimeFormat(locale, options);
    const { year, month } = record;
    const date = new Date(year, month, 1);

    return (
        <Typography color="textPrimary" component="p" variant="h5" {...rest}>
            {formatter.format(date)}
        </Typography>
    )
};

CalendarDateField.propTypes = {
    options: PropTypes.object,
};

CalendarDateField.defaultProps = {
    options: { year: "numeric", month: "long" },
};

export default CalendarDateField;
