import React from "react";
import ProductForm from "./ProductForm";
import ProductTitle from "./ProductTitle";
import { Edit } from "react-admin";
import { EditActions } from "../../component/action";

const ProductEdit = (props) => (
    <Edit
        actions={<EditActions {...props} />}
        title={<ProductTitle />}
        {...props}
    >
        <ProductForm />
    </Edit>
);

export default ProductEdit;
