import React from "react";
import CustomAppBar from "./CustomAppBar";
import CustomMenu from "./CustomMenu";
import { Layout } from "react-admin";
import { darkTheme, lightTheme } from "../config/themes";
import { useSelector } from "react-redux";

const CustomLayout = (props) => {
    const theme = useSelector(state => state.theme === "dark" ? darkTheme : lightTheme);

    return (
        <Layout
            {...props}
            appBar={CustomAppBar}
            menu={CustomMenu}
            theme={theme}
        />
    );
};

export default CustomLayout;
