import React from "react";
import PropTypes from "prop-types";
import ShowIcon from "@material-ui/icons/Visibility";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";

const useStyles = makeStyles({
    label: {
        paddingLeft: "0.5em",
    },
});

const ProfileShowButton = ({ basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Button
            component={Link}
            color="primary"
            size="small"
            to={basePath}
        >
            <ShowIcon />
            <span className={classes.label}>
                {translate("ra.action.show")}
            </span>
        </Button>
    );
};

ProfileShowButton.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

ProfileShowButton.defaultProps = {
    basePath: "/profile",
};

export default ProfileShowButton;
