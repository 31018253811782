import React from "react";
import OrderIcon from "@material-ui/icons/ConfirmationNumber";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { BoxField } from "../../../component/box";
import { dateTimeMinOptions } from "../../../util/dates";
import { Link, useLocale } from "react-admin";

const CustomerCardOrderIterator = props => {
    const locale = useLocale();
    const formatter = new Intl.DateTimeFormat(locale, dateTimeMinOptions);
    const { classes, data, ids, loaded } = props;

    console.log(props);

    if (! loaded) {
        return null;
    }

    return (
        <>
            {
                ids.map(id => {
                    const record = data[id];
                    const { checkInAt, locator, status } = record;
                    const checkIn = new Date(Date.parse(checkInAt));

                    return (
                        <BoxField key={id} dense fullWidth>
                            <Box className={classes.spacing} display="flex" alignItems="center">
                                <Tooltip title={locator}>
                                    <IconButton component={Link} to={`/booking/${locator}/show`}>
                                        <OrderIcon />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body1">
                                    {locator}
                                </Typography>
                                <Typography color="textSecondary" variant="body2">
                                    {formatter.format(checkIn)}
                                </Typography>
                                <Typography className={classes.right} color="textSecondary" variant="body2">
                                    {status}
                                </Typography>
                            </Box>
                        </BoxField>
                    )
                })
            }
        </>
    );
};

export default CustomerCardOrderIterator;
