import React from "react";
import BookingCardAccomodation from "./BookingCardAccomodation";
import PropTypes from "prop-types";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

const BookingCardAccomodations = ({ classes, record }) => {
    const translate = useTranslate();
    const { accomodations, checkedInAt } = record;

    if (! checkedInAt) {
        return null;
    }

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("booking.message.accomodationData")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        accomodations && accomodations.length ? accomodations.map((accomodation, i) => (
                            <BookingCardAccomodation
                                accomodation={accomodation}
                                classes={classes}
                                record={record}
                                key={i}
                            />
                        )) : (
                            <Typography color="textSecondary" variant="body2">
                                {translate("booking.message.stockNotSpecified")}
                            </Typography>
                        )
                    }
                </Grid>
            </Grid>
        </CardContent>
    );
}

BookingCardAccomodations.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardAccomodations.defaultProps = {
    classes: {},
};

export default BookingCardAccomodations;
