import React from "react";
import IncidentSeverityInput from "./IncidentSeverityInput";
import { Filter, useTranslate } from "react-admin";

const IncidentFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <IncidentSeverityInput source="severity" label={translate("incident.label.severity")} />
        </Filter>
    );
};

export default IncidentFilter;
