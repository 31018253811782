import React from "react";
import { Filter, TextInput, useTranslate } from "react-admin";

const OrderFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <TextInput
                alwaysOn
                source="locator.lk"
                label={translate("order.label.locator")}
            />
        </Filter>
    );
};

export default OrderFilter;
