import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { BooleanInput, DateInput, DateTimeInput, FormDataConsumer, TextInput, required, useTranslate } from "react-admin";
import { Grid, Typography } from "@material-ui/core";
import { UpdateWithFormButton } from "../../../component/button";
import { dateOptions, dateTimeOptions } from "../../../util/dates";

const validate = [required()];
const validateForm = values => {
    const { unavailable, unavailableFrom, unavailableUntil } = values;

    if (unavailable && unavailableFrom && unavailableUntil) {
        const a = new Date(Date.parse(unavailableFrom));
        const b = new Date(Date.parse(unavailableUntil));

        if (a && b && a > b) {
            const message = "ra.validation.unavailability";

            return {
                unavailableFrom: message,
                unavailableUntil: message,
            };
        }
    }

    return {};
};

const StockAddEventButton = props => {
    const translate = useTranslate();
    const initialValues = { date: new Date() };

    return (
        <UpdateWithFormButton
            action="event"
            icon={<AddIcon />}
            initialValues={initialValues}
            path="event"
            resource="stock"
            validate={validateForm}
            {...props}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <DateTimeInput
                        fullWidth
                        label={translate("event.label.date")}
                        source="date"
                        options={dateTimeOptions}
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput
                        fullWidth
                        label={translate("event.label.unavailable")}
                        source="unavailable"
                    />
                </Grid>
                <FormDataConsumer>
                    {
                        ({ formData }) => formData.unavailable && (
                            <>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        {translate("event.message.unavailability")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        fullWidth
                                        label={translate("event.label.unavailableFrom")}
                                        source="unavailableFrom"
                                        options={dateOptions}
                                        validate={validate}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        fullWidth
                                        label={translate("event.label.unavailableUntil")}
                                        source="unavailableUntil"
                                        options={dateOptions}
                                        validate={validate}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </FormDataConsumer>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        label={translate("event.label.notes")}
                        multiline
                        resettable
                        source="notes"
                    />
                </Grid>
            </Grid>
        </UpdateWithFormButton>
    );
};

export default StockAddEventButton;
