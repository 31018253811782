import React from "react";
import { Title, useLocale, useSetLocale, useTranslate } from "react-admin";
import { Button, Card, CardContent, makeStyles } from "@material-ui/core";
import { changeTheme } from "../config/customActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
    label: {
        width: "9em",
    },
    button: {
        margin: "1em",
    },
    fieldset: {
        display: "flex",
        alignItems: "center",
    },
});

const Settings = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const theme = useSelector(state => state.theme);
    const translate = useTranslate();

    const handleChangeTheme = theme => () => dispatch(changeTheme(theme));
    const handleSetLocale = locale => () => setLocale(locale);

    return (
        <Card>
            <Title title={translate('ra.page.settings')} />
            <CardContent className={classes.fieldset}>
                <div className={classes.label}>
                    {translate('ra.message.theme')}
                </div>
                <Button
                    className={classes.button}
                    color={theme === "light" ? "primary" : "default"}
                    variant="contained"
                    onClick={handleChangeTheme("light")}
                >
                    {translate("ra.message.lightTheme")}
                </Button>
                <Button
                    className={classes.button}
                    color={theme === "dark" ? "primary" : "default"}
                    variant="contained"
                    onClick={handleChangeTheme("dark")}
                >
                    {translate("ra.message.darkTheme")}
                </Button>
            </CardContent>
            <CardContent className={classes.fieldset}>
                <div className={classes.label}>
                    {translate('ra.message.locale')}
                </div>
                <Button
                    className={classes.button}
                    color={locale === "en" ? "primary" : "default"}
                    variant="contained"
                    onClick={handleSetLocale("en")}
                >
                    {"en"}
                </Button>
                <Button
                    className={classes.button}
                    color={locale === "es" ? "primary" : "default"}
                    variant="contained"
                    onClick={handleSetLocale("es")}
                >
                    {"es"}
                </Button>
            </CardContent>
        </Card>
    );
};

export default Settings;
