import React from "react";
import PropTypes from "prop-types";
import { Avatar, Box, CardContent, Typography } from "@material-ui/core";
import { getProductLabel } from "../../product/productUtils";
import { useLocale, useTranslate } from "react-admin";

const BookingCardTitle = ({ addLabel, classes, record, dateFormatter, size, timeFormatter }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const { checkInAt, locator, product = {}} = record;
    const { duration, maxDuration, minDuration } = { ...record, ...product };
    const checkIn = new Date(Date.parse(checkInAt));

    return (
        <CardContent>
            {
                addLabel && (
                    <Typography color="textSecondary" gutterBottom variant="subtitle2">
                        {translate("booking.message.productData")}
                    </Typography>
                )
            }
            <Box display="flex" alignItems={"center"}>
                <Avatar
                    className={classes.avatar}
                    src="/asset/or1.png"
                    style={{ height: size, width: size }}
                />
                <Box flex="1" ml={2}>
                    <Typography variant="h5">
                        {locator}
                    </Typography>
                    <Typography variant="body1">
                        {getProductLabel(product, locale)}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {dateFormatter.format(checkIn)}
                        {" • "}
                        {timeFormatter.format(checkIn)}
                        {" • "}
                        {
                            minDuration === maxDuration ?
                                translate("booking.message.duration", { duration }) :
                                translate("booking.message.durationRange", { maxDuration, minDuration })
                        }
                    </Typography>
                </Box>
            </Box>
        </CardContent>
    );
};

BookingCardTitle.propTypes = {
    addLabel: PropTypes.bool,
    classes: PropTypes.object,
    dateFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
    timeFormatter: PropTypes.object,
};

BookingCardTitle.defaultProps = {
    addLabel: false,
    classes: {},
    size: 48,
};

export default BookingCardTitle;
