import React, { useState } from "react";
import BookingIcon from "@material-ui/icons/Bookmark";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import IdentityIcon from "@material-ui/icons/AccountCircle";
import IncidentIcon from "@material-ui/icons/Error";
import OrderIcon from "@material-ui/icons/ConfirmationNumber";
import PersonIcon from "@material-ui/icons/Person";
import ProductIcon from "@material-ui/icons/LocalGroceryStore";
import SecurityIcon from "@material-ui/icons/Security";
import StockIcon from "@material-ui/icons/Widgets";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import SubMenu from "./SubMenu";
import WarehouseIcon from "@material-ui/icons/Storefront";
import { DashboardMenuItem, MenuItemLink, Responsive, usePermissions, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    menu: {
        marginTop: theme.spacing(2),
    },
}));

const CustomMenu = ({ onMenuClick, dense, logout }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { loaded, permissions } = usePermissions();
    const [menu, setMenu] = useState({
        customer: false,
        warehouse: false,
        management: false,
    });

    useSelector((state) => state.theme);

    const handleToggle = newMenu => setMenu({ ...menu, [newMenu]: ! menu[newMenu] });

    if (! loaded) {
        return null;
    }

    const hasAdminRole = permissions && permissions.indexOf("ROLE_ADMIN") > -1;
    const { customer, warehouse, management } = menu;

    return (
        <div className={classes.menu}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} />
            <MenuItemLink
                dense={dense}
                leftIcon={<BookingIcon />}
                primaryText={translate('ra.menu.booking')}
                to={`/booking`}
                onClick={onMenuClick}
            />
            {
                hasAdminRole && (
                    <>
                        <SubMenu
                            handleToggle={ () => handleToggle('customer') }
                            icon={<PersonIcon />}
                            isOpen={ customer }
                            name="customer"
                            primaryText={translate('ra.group.customer')}
                        >
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<PersonIcon />}
                                primaryText={translate('ra.menu.customer')}
                                to={`/customer`}
                                onClick={onMenuClick}
                            />
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<OrderIcon />}
                                primaryText={translate('ra.menu.order')}
                                to={`/order`}
                                onClick={onMenuClick}
                            />
                        </SubMenu>
                        <SubMenu
                            handleToggle={ () => handleToggle('warehouse') }
                            icon={<WarehouseIcon />}
                            isOpen={ warehouse }
                            name="warehouse"
                            primaryText={translate('ra.group.warehouse')}
                        >
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<CalendarIcon />}
                                primaryText={translate('ra.menu.calendar')}
                                to={`/calendar`}
                                onClick={onMenuClick}
                            />
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<ProductIcon />}
                                primaryText={translate('ra.menu.product')}
                                to={`/product`}
                                onClick={onMenuClick}
                            />
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<StockIcon />}
                                primaryText={translate('ra.menu.stock')}
                                to={`/stock`}
                                onClick={onMenuClick}
                            />
                        </SubMenu>
                        <SubMenu
                            handleToggle={ () => handleToggle('management') }
                            icon={<SecurityIcon />}
                            isOpen={ management }
                            name="management"
                            primaryText={translate('ra.group.management')}
                        >
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<IdentityIcon />}
                                primaryText={translate('ra.menu.identity')}
                                to={`/identity`}
                                onClick={onMenuClick}
                            />
                            <MenuItemLink
                                dense={dense}
                                leftIcon={<IncidentIcon />}
                                primaryText={translate('ra.menu.incident')}
                                to={`/incident`}
                                onClick={onMenuClick}
                            />
                        </SubMenu>
                    </>
                )
            }
            <Responsive
                xsmall={
                    <MenuItemLink
                        dense={dense}
                        leftIcon={<SettingsIcon />}
                        primaryText={translate('ra.menu.settings')}
                        to="/settings"
                        onClick={onMenuClick}
                    />
                }
                medium={null}
            />
            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </div>
    );
};

CustomMenu.propTypes = {
    dense: PropTypes.bool,
    logout: PropTypes.object,
    onMenuClick: PropTypes.func,
};

CustomMenu.defaultProps = {
    dense: false,
};

export default CustomMenu;
