import React from "react";
import { Avatar, Box, makeStyles } from "@material-ui/core";
import { FunctionField, TextField } from "react-admin";

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(2),
        height: 64,
        width: 64,
    },
}));

const ProductAvatarField = () => {
    const classes = useStyles();

    return (
        <FunctionField render={
            record => {
                if (! record) {
                    return null;
                }

                const { posterLoRes: { url }} = record;

                return (
                    <Box display="flex" alignItems="center" mb={2}>
                        <Avatar
                            className={classes.avatar}
                            src={url}
                        />
                        <TextField
                            component="p"
                            source="ref"
                            variant="h5"
                        />
                    </Box>
                );
            }
        } />
    )
};

export default ProductAvatarField;
