import React from "react";
import BookingTitle from "./BookingTitle";
import { Calendar } from "../../component/calendar";

const BookingList = props => {
    const { locale } = props;

    // Yep, you have to rework this as neither monthFormat nor yearFormat
    // is in List props (nor locale)
    const date = new Date();
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: "2-digit" });
    const yearFormatter = new Intl.DateTimeFormat(locale, { year: "numeric" });

    const defaultKey = yearFormatter.format(date) + monthFormatter.format(date);

    return (
        <Calendar
            basePath="/booking"
            filterDefaultValues={{ key: { start: defaultKey } }}
            resource="booking"
            source="key"
            title={<BookingTitle/>}
            {...props}
        />
    );
};

export default BookingList;
