import React from "react";
import PropTypes from "prop-types";
import credentialsTypeChoices from "./credentialsTypeChoices";
import { SelectInput } from "react-admin";

const CredentialsTypeInput = ({ helperText, label, source, ...props }) => (
    <SelectInput
        label={label}
        helperText={helperText}
        source={source}
        choices={credentialsTypeChoices}
        {...props}
    />
);

CredentialsTypeInput.propTypes = {
    label: PropTypes.string,
    helperText: PropTypes.string,
    source: PropTypes.string.isRequired,
};

CredentialsTypeInput.defaultProps = {
    source: "condition",
};

export default CredentialsTypeInput;
