import React from "react";
import { ListButton, TopToolbar } from "react-admin";
import PropTypes from "prop-types";

const CreateActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

CreateActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

CreateActions.defaultProps = {
    data: {},
};

export default CreateActions;
