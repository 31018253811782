const ClientInfo = {
    APP: "APP",
    DID: "DID",
    FP: "FP",
    IP: "IP",
    IPS: "IPS",
    O: "O",
    UA: "UA",
};

export default ClientInfo;
