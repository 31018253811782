import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { Avatar, Box, IconButton, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { BoxField } from "../../../component/box";
import { Link, linkToRecord, useTranslate } from "react-admin";

const BookingCardPassenger = ({ classes, customer, editable, record, removable, onEdit, onRemove }) => {
    const translate = useTranslate();
    const { age, firstName, lastName, licenseNumber } = record;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const type = age < 18 ? "minor" : licenseNumber ? "licensed" : "unlicensed";

    return (
        <BoxField dense fullWidth>
            <Box className={classes.spaced} display="flex" alignItems="center">
                <Avatar className={classes.passengerAvatar}>
                    {firstName.charAt(0).toUpperCase()}
                    {lastName.charAt(0).toUpperCase()}
                </Avatar>
                <div className={classnames(classes.passenger, {
                    [classes.spaced]: ! isSmall,
                })}>
                    <Typography variant="body1">
                        {firstName}
                        {" "}
                        {lastName}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {translate("booking.message.age", { ...record })}
                        {" • "}
                        {translate("passenger.type." + type)}
                        {
                            customer ? " • " : null
                        }
                        {
                            customer ? translate("booking.message.holder") : null
                        }
                    </Typography>
                </div>
                <div className={classes.actions}>
                    {
                        customer && (
                            <Tooltip title={translate("ra.action.show")}>
                                <Link to={linkToRecord("/customer", customer.id, "show")}>
                                    <IconButton
                                        aria-label={translate("ra.action.edit")}
                                        className={classes.edit}
                                    >
                                        <LaunchIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )
                    }
                    <span/>
                    {
                        editable && (
                            <Tooltip title={translate("ra.action.edit")}>
                                <IconButton
                                    aria-label={translate("ra.action.edit")}
                                    className={classes.edit}
                                    onClick={onEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    <span/>
                    {
                        removable && (
                            <Tooltip title={translate("ra.action.remove")}>
                                <IconButton
                                    aria-label={translate("ra.action.remove")}
                                    className={classes.edit}
                                    onClick={onRemove}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
            </Box>
        </BoxField>
    );
}

BookingCardPassenger.propTypes = {
    classes: PropTypes.object,
    editable: PropTypes.bool,
    customer: PropTypes.object,
    record: PropTypes.object.isRequired,
    removable: PropTypes.bool,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
};

BookingCardPassenger.defaultProps = {
    classes: {},
    customer: null,
    editable: false,
    removable: false,
    onEdit: () => {},
    onRemove: () => {},
};

export default BookingCardPassenger;
