import React from "react";
import BuildIcon from "@material-ui/icons/Build";
import OrderStatus from "../../order/OrderStatus";
import PassengerType from "../../passenger/PassengerType";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Avatar, Box, IconButton, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { BoxField } from "../../../component/box";
import { SkiJetIcon } from "../../../component/icon";
import { useTranslate } from "react-admin";

const BookingCardAccomodation = ({ accomodation, classes, record, onClick }) => {
    const translate = useTranslate();
    const { status, passengers } = record;
    const { driverType, passengers: accomodationPassengers, plateNumber } = accomodation;
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const mayReportIncidents = status !== OrderStatus.CHECKED_OUT;

    return (
        <BoxField dense fullWidth>
            <Box className={classes.spaced} display="flex" alignItems="center">
                <SkiJetIcon color="#bdbdbd" />
                <div className={classnames(classes.passenger, {
                    [classes.spaced]: ! isSmall,
                })}>
                    <Typography variant="body1">
                        {plateNumber}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {translate("passenger.type." + driverType)}
                    </Typography>
                    {
                        accomodationPassengers && accomodationPassengers.length ? (
                            <AvatarGroup max={2}>
                                {
                                    accomodationPassengers.map(offset => {
                                        const passenger = passengers[offset];

                                        if (! passenger) {
                                            return null;
                                        }

                                        const { firstName, lastName } = passenger;

                                        return (
                                            <Avatar alt={`${firstName} ${lastName}`} className={classes.passengerAvatar}>
                                                {firstName.charAt(0).toUpperCase()}
                                                {lastName.charAt(0).toUpperCase()}
                                            </Avatar>
                                        );
                                    })
                                }
                            </AvatarGroup>
                        ) : driverType === PassengerType.INSTRUCTOR ? (
                            <Avatar alt={translate("accomodation.label.instructor")} className={classes.passengerAvatar}>
                                {"I"}
                            </Avatar>
                        ) : null
                    }
                </div>
                <div className={classes.actions}>
                    <Tooltip title={translate("ra.action.report_incident")}>
                        <IconButton
                            aria-label={translate("ra.action.report_incident")}
                            className={classes.edit}
                            disabled={! mayReportIncidents}
                            onClick={onClick}
                        >
                            <BuildIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        </BoxField>
    );
}

BookingCardAccomodation.propTypes = {
    accomodation: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    classes: PropTypes.object,
    onClick: PropTypes.func,
};

BookingCardAccomodation.defaultProps = {
    classes: {},
    onClick: () => {},
};

export default BookingCardAccomodation;
