import React from "react";
import LaunchIcon from "@material-ui/icons/Launch";
import PropTypes from "prop-types";
import get from "lodash/get";
import { FunctionField, UrlField } from "react-admin";
import { BoxField } from "../../component/box";
import {Box, IconButton, Typography} from "@material-ui/core";

const ProductPosterField = ({ label, source }) => (
    <FunctionField render={
        record => {
            if (! record) {
                return null;
            }

            const poster = get(record, source);
            const { url, title } = poster;

            if (! url || ! title) {
                return null;
            }

            return (
                <BoxField
                    label={
                        <Typography color="textSecondary" variant="body2">
                            {label}
                        </Typography>
                    }
                >
                    <Box display="flex" alignItems="center">
                        <UrlField
                            record={poster}
                            source="url"
                            target="_blank"
                        />
                        <IconButton
                            href={url}
                            target="_blank"
                            style={{ marginLeft: "auto" }}
                        >
                            <LaunchIcon />
                        </IconButton>
                    </Box>
                </BoxField>
            );
        }
    } />
);

ProductPosterField.propTypes = {
    label: PropTypes.string,
    source: PropTypes.string,
};

export default ProductPosterField;
