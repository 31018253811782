import React from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@material-ui/core";

const getColor = (color, type, theme, map) => {
    if (color) {
        return color;
    }

    if (map && type in map) {
        return type[map];
    }

    if (type in theme.palette) {
        return theme.palette[type].main;
    }

    return theme.palette.primary.main;
}

const BoxIndicator = ({ color, label, map, type, size }) => {
    const theme = useTheme();
    const bgColor = getColor(color, type, theme, map);

    return (
        <Box
            aria-label={label}
            bgcolor={bgColor}
            borderRadius={size / 2}
            component="span"
            display="inline-block"
            height={size}
            width={size}
        />
    );
};

BoxIndicator.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
    map: PropTypes.object,
    type: PropTypes.oneOf([""]),
    size: PropTypes.number,
};

BoxIndicator.defaultProps = {
    addLabel: false,
    size: 12,
    type: "primary",
};

export default BoxIndicator;
