import React from "react";
import PropTypes from "prop-types";
import { CreateButton, ListButton, TopToolbar } from "react-admin";

const CustomerShowActions = ({ basePath, data, id }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} variant="contained" style={{ marginLeft: "1em" }} />
    </TopToolbar>
);

CustomerShowActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

CustomerShowActions.defaultProps = {
    data: {},
};

export default CustomerShowActions;
