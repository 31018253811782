import PropTypes from "prop-types";
import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.light,
        borderRadius: "50%",
    },
    info: {
        backgroundColor: theme.palette.info.light,
        borderRadius: "50%",
    },
    success: {
        backgroundColor: theme.palette.success.light,
        borderRadius: "50%",
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
        borderRadius: "50%",
    },
}));

const BookingCardHistoryEntryType = ({ size, type }) => {
    const classes = useStyles();

    return (
        <Box
            className={classes[type]}
            display="inline-flex"
            height={size}
            width={size}
            marginRight={1}
        />
    );
};

BookingCardHistoryEntryType.propTypes = {
    type: PropTypes.oneOf(["error", "info", "success", "warning"]),
    size: PropTypes.string,
};

BookingCardHistoryEntryType.defaultProps = {
    size: 12,
    type: "info",
};

export default BookingCardHistoryEntryType;
