import React from "react";
import CustomerShowActions from "./CustomerShowActions";
import { CustomerCard, CustomerCardSkeleton } from "./card";
import { ShowBase, useShowContext } from "react-admin";

const CustomerShowContent = props => {
    const { loaded, record } = useShowContext();

    return (
        <>
            <CustomerShowActions {...props} />
            {
                loaded ? (
                    <CustomerCard record={record} {...props} />
                ) : (
                    <CustomerCardSkeleton {...props} />
                )
            }
        </>
    );
};

const CustomerShow = props => (
    <ShowBase {...props}>
        <CustomerShowContent />
    </ShowBase>
);

export default CustomerShow;
