import React from "react";
import { BooleanField, Datagrid, TextField, useTranslate } from "react-admin";
import { CurrencyField } from "../../component/field";

const ProductDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid {...props}>
            <TextField
                label={translate("product.label.ref")}
                source="ref"
            />
            <CurrencyField
                label={translate("product.label.residentFee")}
                source="residentFee"
            />
            <CurrencyField
                label={translate("product.label.domesticFee")}
                source="domesticFee"
            />
            <CurrencyField
                label={translate("product.label.foreignFee")}
                source="foreignFee"
            />
            <BooleanField
                label={translate("product.label.allowRedeemVoucher")}
                source="allowRedeemVoucher"
            />
            <BooleanField
                label={translate("product.label.changeable")}
                source="changeable"
            />
            <BooleanField
                label={translate("product.label.cancellable")}
                source="cancellable"
            />
            <CurrencyField
                label={translate("product.label.cancellationFee")}
                source="cancellationFee"
            />
        </Datagrid>
    );
}

export default ProductDatagrid;
