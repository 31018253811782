import React from "react";
import CheckInCopyButton from "./CheckInCopyButton";
import { DateInput, ReferenceField, TextInput, email, required, useTranslate } from "react-admin";
import { Grid, Typography } from "@material-ui/core";
import { dateOptions } from "../../../util/dates";
import { idCard } from "../../../util/validation";

const validate = [required()];
const validateEmail = [required(), email()];
const validateDateOfBirth = [required()];
const validateId = [required(), idCard()];

const CheckInPassengerEdit = ({ classes, offset, onCopy }) => {
    const translate = useTranslate();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Typography color="textSecondary" variant="body2">
                        {translate("booking.message.passengerData", { offset: offset + 1 })}
                    </Typography>
                    {
                        offset === 0 && (
                            <ReferenceField
                                link={false}
                                reference="customer"
                                source="customer.id"
                            >
                                <CheckInCopyButton
                                    className={classes.copy}
                                    onClick={onCopy}
                                />
                            </ReferenceField>
                        )
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.emailAddress")}
                    source={`passengers.${offset}.emailAddress`}
                    validate={validateEmail}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.firstName")}
                    source={`passengers.${offset}.firstName`}
                    validate={validate}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.lastName")}
                    source={`passengers.${offset}.lastName`}
                    validate={validate}
                />
            </Grid>
            {
                offset === 0 && (
                    <>
                        <Grid item xs={12}>
                            <TextInput
                                fullWidth
                                label={translate("passenger.label.address")}
                                source={`passengers.${offset}.address`}
                                validate={validate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                label={translate("passenger.label.postalCode")}
                                source={`passengers.${offset}.postalCode`}
                                validate={validate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                fullWidth
                                label={translate("passenger.label.locality")}
                                source={`passengers.${offset}.locality`}
                                validate={validate}
                            />
                        </Grid>
                    </>
                )
            }
            <Grid item xs={6}>
                <DateInput
                    fullWidth
                    label={translate("passenger.label.dateOfBirth")}
                    source={`passengers.${offset}.dateOfBirth`}
                    options={dateOptions}
                    validate={validateDateOfBirth}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.idCardNumber")}
                    source={`passengers.${offset}.idCardNumber`}
                    validate={validateId}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    fullWidth
                    label={translate("passenger.label.licenseNumber")}
                    source={`passengers.${offset}.licenseNumber`}
                />
            </Grid>
        </Grid>
    );
}

export default CheckInPassengerEdit;
