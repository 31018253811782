import BookingDropCause from "./BookingDropCause";

const bookingDropCauseChoices = [
    { id: BookingDropCause.CUSTOMER_REQUEST, name: "booking.drop.customerRequest" },
    { id: BookingDropCause.OTHER, name: "booking.drop.other" },
];

export {
    bookingDropCauseChoices,
};
