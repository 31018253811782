import React from "react";
import StockEditActions from "./StockEditActions";
import StockForm from "./StockForm";
import StockTitle from "./StockTitle";
import { Edit } from "react-admin";

const StockEdit = (props) => (
    <Edit
        actions={<StockEditActions {...props} />}
        title={<StockTitle />}
        {...props}
    >
        <StockForm {...props} />
    </Edit>
);

export default StockEdit;
