import React from "react";
import StockType from "./StockType";
import StockTypeInput from "./StockTypeInput";
import { BooleanInput, FormDataConsumer, NumberInput, SimpleForm, TextInput, required, useTranslate } from "react-admin";
import { Grid, Divider, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const validate = [required()];

const StockForm = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("stock.message.sku")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label={translate("stock.label.sku")}
                        source="sku"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StockTypeInput
                        fullWidth
                        label={translate("stock.label.type")}
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput
                        fullWidth
                        label={translate("stock.label.available")}
                        source="available"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("stock.message.details")}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextInput
                        fullWidth
                        label={translate("stock.label.brand")}
                        source="brand"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextInput
                        fullWidth
                        label={translate("stock.label.model")}
                        source="model"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormDataConsumer>
                        {
                            ({ formData }) => (
                                <TextInput
                                    fullWidth
                                    label={translate("stock.label.plateNumber")}
                                    source="plateNumber"
                                    validate={formData.type === StockType.SKI_JET ? validate : null}
                                />
                            )
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumberInput
                        fullWidth
                        label={translate("stock.label.minAge")}
                        source="minAge"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput
                        fullWidth
                        label={translate("stock.label.requiresDrivingLicense")}
                        source="requiresDrivingLicense"
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default StockForm;
