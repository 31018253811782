import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import { FormContextProvider, useTranslate } from "react-admin";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            maxHeight: "100vh",
            height: "100vh",
            margin: 0,
            width: "100vw",
        },
    },
    title: {
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(4),
        },
    },
    content: {
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    wrap: {},
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const ModalForm = ({
    autoFocus,
    children,
    className,
    close,
    closeable,
    initialValues,
    loading,
    open,
    redirect,
    submit,
    title,
    onClose,
    onSubmit,
    ...rest
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <FormContextProvider>
            <Form
                initialValues={initialValues}
                mutators={{ ...arrayMutators }}
                subscription={defaultSubscription}
                onSubmit={onSubmit}
                {...rest}
                render={
                    ({ handleSubmit }) => (
                        <form
                            className={classnames("modal-form", className)}
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Dialog
                                classes={{
                                    paper: classes.paper,
                                }}
                                open={open}
                                onClose={onClose}
                            >
                                <DialogTitle className={classes.title}>
                                    {title}
                                </DialogTitle>
                                <DialogContent className={classes.content}>
                                    {
                                        React.Children.map(children, (child, i) => (
                                            <div className={classnames("ra-input", classes.wrap)} key={i}>
                                                {child}
                                            </div>
                                        ))
                                    }
                                </DialogContent>
                                <DialogActions>
                                    {
                                        closeable ? (
                                            <Button
                                                color="primary"
                                                onClick={onClose}
                                            >
                                                {translate(close)}
                                            </Button>
                                        ) : null
                                    }
                                    <Button
                                        autoFocus={autoFocus}
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        {translate(submit)}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </form>
                    )
                }
            />
        </FormContextProvider>
    );
};

ModalForm.propTypes = {
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    close: PropTypes.string,
    closeable: PropTypes.bool,
    initialValues: PropTypes.object,
    loading: PropTypes.bool,
    open: PropTypes.bool,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    submit: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ModalForm.defaultProps = {
    autoFocus: false,
    close: "ra.action.close",
    closeable: true,
    initialValues: {},
    loading: false,
    open: false,
    redirect: false,
    submit: "ra.action.submit",
};

export default ModalForm;
