import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import { Chip, Typography, makeStyles } from "@material-ui/core";
import {useRecordContext} from "react-admin";

const useStyles = makeStyles({
    chip: {
        margin: 4,
        cursor: 'inherit',
    },
});

const ScalarArrayField = ({ className, emptyText, source, ...props }) => {
    const classes = useStyles();
    const record = useRecordContext();

    if (! record) {
        return null;
    }

    const value = get(record, source);

    return record && Array.isArray(value) && value.length ? (
        <div>
            {
                value.map((item) => (
                    <Chip
                        className={classnames(classes.chip, className)}
                        label={item}
                    />
                ))
            }
        </div>
    ) : (
        <Typography color="textPrimary" component="span" variant="body2" {...props}>
            {emptyText}
        </Typography>
    );
};

ScalarArrayField.propTypes = {
    addLabel: PropTypes.bool,
    className: PropTypes.string,
    emptyText: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
};

ScalarArrayField.defaultProps = {
    addLabel: true,
};

export default ScalarArrayField;
