import React from "react";
import { SimpleList, useLocale } from "react-admin";
import { dateTimeOptions } from "../../util/dates";

const OrderSimpleList = props => {
    const locale = useLocale();
    const formatter = new Intl.DateTimeFormat(locale, dateTimeOptions);

    return (
        <SimpleList
            primaryText={record => record.locator}
            secondaryText={record => record.state}
            tertiaryText={record => formatter.format(Date.parse(record.checkInAt))}
            {...props}
        />
    );
};

export default OrderSimpleList;
