import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import { useRecordContext } from "react-admin";

const DurationField = ({ source, units }) => {
    const record = useRecordContext();

    return (
        <Typography color="textPrimary" component="span" variant="body2">
            {get(record, source)}
            {" "}
            {units}
        </Typography>
    )
};

DurationField.propTypes = {
    units: PropTypes.string,
    source: PropTypes.string,
};

DurationField.defaultProps = {
    units: "min",
};

export default DurationField;
