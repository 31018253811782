import React from "react";
import PropTypes from "prop-types";
import { SaveButton, Toolbar, useTranslate } from "react-admin";

const ProfileEditToolbar = ({ redirect, ...props}) => {
    const translate = useTranslate();

    return (
        <Toolbar {...props} >
            <SaveButton
                label={translate("ra.action.save")}
                redirect={redirect}
                submitOnEnter={true}
            />
        </Toolbar>
    )
};

ProfileEditToolbar.propTypes = {
    redirect: PropTypes.string,
};

ProfileEditToolbar.defaultProps = {
    redirect: "show",
};

export default ProfileEditToolbar;
