import React from "react";
import CalendarActions from "./CalendarActions";
import CalendarView from "./CalendarView";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { ListContextProvider, useListController } from "react-admin";
import { calendarViews } from "./calendarUtil";

/**
 * Calendar page component
 *
 * The <Calendar> component renders the calendar layout (title, buttons,
 * filters), and fetches the list of records from the REST API.
 *
 * The <Calendar> component accepts the following props:
 *
 * - actions
 * - filter (the permanent filter to apply to the query)
 * - filters (a React component used to display the filter form)
 * - title
 *
 * @example
 *
 * const PostFilter = (props) => (
 *     <Filter {...props}>
 *         <TextInput label="Search" source="q" alwaysOn />
 *         <TextInput label="Title" source="title" />
 *     </Filter>
 * );
 *
 * export const PostList = (props) => (
 *     <Calendar
 *         title="List of posts"
 *         filter={{ is_published: true }}
 *         filters={PostFilter}
 *         {...props}
 *     />
 * );
 */
const Calendar = props => {
    const controllerProps = useListController(props);

    return (
        <ListContextProvider value={controllerProps}>
            <CalendarView
                {...props}
                {...controllerProps}
            />
        </ListContextProvider>
    );
};

Calendar.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    allowPastEvents: PropTypes.bool,
    authProvider: PropTypes.func,
    bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    children: PropTypes.element,
    classes: PropTypes.object,
    className: PropTypes.string,
    filter: PropTypes.object,
    filterDefaultValues: PropTypes.object,
    filters: PropTypes.element,
    initialDate: PropTypes.object,
    hasCreate: PropTypes.bool.isRequired,
    hasEdit: PropTypes.bool.isRequired,
    hasList: PropTypes.bool.isRequired,
    hasShow: PropTypes.bool.isRequired,
    location: PropTypes.any,
    match: PropTypes.any,
    monthFormat: PropTypes.object,
    path: PropTypes.string,
    resource: PropTypes.string.isRequired,
    save: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["session", "local"])]),
    source: PropTypes.string.isRequired,
    title: PropTypes.element,
    views: PropTypes.arrayOf(PropTypes.oneOf(calendarViews)),
    yearFormat: PropTypes.object,
};

Calendar.defaultProps = {
    actions: (<CalendarActions />),
    allowPastEvents: false,
    classes: {},
    component: Card,
    initialDate: new Date(),
    monthFormat: { month: "2-digit" },
    yearFormat: { year: "numeric" },
    filter: {},
    save: "session",
    source: "key",
    views: [],
};

export default Calendar;
