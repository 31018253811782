import React from "react";
import { useTranslate } from "react-admin";

const BookingTitle = ({ record }) => {
    const translate = useTranslate();

    return (
        <span>{translate('calendar.label.title')}</span>
    );
};

export default BookingTitle;
