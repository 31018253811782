import React from "react";
import { SelectInput } from "react-admin";
import { bookingCancelCauseChoices } from "./bookingCancelCauseChoices";

const BookingCancelCauseInput = props => (
    <SelectInput
        choices={bookingCancelCauseChoices}
        {...props}
    />
);

export default BookingCancelCauseInput;
