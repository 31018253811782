const CredentialsType = {
    ACCESS_KEY: "access-key",
    BIOMETRIC: "biometric",
    ONE_TIME_PASSWORD: "one-time-password",
    PASSWORD: "password",
    PUBLIC_KEY: "public-key",
    SECRET_KEY: "secret-key",
};

export default CredentialsType;
