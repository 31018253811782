import React from "react";
import CalendarForm from "./CalendarForm";
import CalendarTitle from "./CalendarTitle";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    holidays: [],
    closed: false,
    firstCheckInAt: "08:00",
    lastCheckInAt: "18:00",
    expirationTime: 45,
    interServiceTime: 15,
};

const CalendarCreate = props => (
    <Create
        actions={<CreateActions />}
        title={<CalendarTitle />}
        {...props}
    >
        <CalendarForm initialValues={initialValues} />
    </Create>
);

export default CalendarCreate;
