import React from "react";
import StockDatagrid from "./StockDatagrid";
import StockFilter from "./StockFilter";
import StockTitle from "./StockTitle";
import StockSimpleList from "./StockSimpleList";
import { List, Responsive } from "react-admin";
import { ListActions } from "../../component/action";

const StockList = props => {
    return (
        <List
            actions={<ListActions />}
            filters={<StockFilter />}
            title={<StockTitle />}
            {...props}
        >
            <Responsive
                small={<StockSimpleList linkType="show" />}
                medium={<StockDatagrid rowClick="show" />}
            />
        </List>
    );
};

export default StockList;
