import React, {useState} from "react";
import DefaultIcon from "@material-ui/icons/DateRange";
import PropTypes from "prop-types";
import { Button, useTranslate } from "react-admin";
import { Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import { calendarViews, saveInitialView } from "./calendarUtil";

const viewLabel = view => "calendar.view." + view;

const CalendarViewButton = ({ calendar, icon, initialView, label, save, views, ...props }) => {
    const translate = useTranslate();
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(initialView || views[0]);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const handleClose = () => setOpen(false);
    const handleOpen = event => {
        setAnchor(event.currentTarget);
        setOpen(true);
    };

    const handleClick = newView => () => {
        if (newView !== view) {
            calendar.current.getApi().changeView(newView);
            setView(newView);

            if (save) {
                saveInitialView(save, newView, isSmall);
            }
        }
        setOpen(false);
    };

    return (
        <>
            <Button
                aria-label={label}
                label={label}
                onClick={handleOpen}
                {...props}
            >
                {icon}
            </Button>
            <Menu
                anchorEl={anchor}
                defaultValue={view}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {
                    views.map(curView => (
                        <MenuItem
                            key={curView}
                            value={curView}
                            onClick={handleClick(curView)}
                        >
                            {translate(viewLabel(curView))}
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

CalendarViewButton.propTypes = {
    calendar: PropTypes.object.isRequired,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    initialView: PropTypes.oneOf(calendarViews),
    label: PropTypes.string,
    save: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["session", "local"])]),
    views: PropTypes.arrayOf(PropTypes.oneOf(calendarViews)),
};

CalendarViewButton.defaultProps = {
    icon: (<DefaultIcon />),
};

export default CalendarViewButton;
