import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { SelectField, useRecordContext } from "react-admin";
import { getSeverityColor } from "./incidentUtils";
import { incidentSeverityChoices } from "./incidentSeverityChoices";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        display: "flex",
    },
});

const IncidentSeverityField = ({ size = 16, ...props }) => {
    const classes = useStyles();
    const record = useRecordContext();

    if (! record) {
        return null;
    }

    const { severity } = record;

    return (
        <div className={classes.root}>
            <Box
                bgcolor={getSeverityColor(severity)}
                borderRadius={size / 2}
                marginRight={1}
                component="span"
                display="inline-block"
                height={size}
                width={size}
            />
            <SelectField
                choices={incidentSeverityChoices}
                {...props}
            />
        </div>
    );
};

IncidentSeverityField.propTypes = {
    size: PropTypes.number,
    source: PropTypes.string,
};

IncidentSeverityField.defaultProps = {
    size: 16,
    source: "severity",
};

export default IncidentSeverityField;
