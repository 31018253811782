import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@material-ui/core";
import { useTranslate } from "react-admin";

const CheckInCopyButton = ({ className, record, onClick, ...props }) => {
    const translate = useTranslate();

    if (! record) {
        return null;
    }

    const handleClick = () => {
        onClick(record);
    };

    return (
        <Tooltip title={translate("booking.message.copyFromCustomer")}>
            <Button
                {...props}
                classes={className}
                onClick={handleClick}
                style={{ marginLeft: "auto" }}
            >
                {translate("booking.message.copyFromCustomer")}
            </Button>
        </Tooltip>
    );
};

CheckInCopyButton.propTypes = {
    className: PropTypes.string,
    record: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};

export default CheckInCopyButton;
