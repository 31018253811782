import React from "react";
import CustomerForm from "./CustomerForm";
import CustomerTitle from "./CustomerTitle";
import { Edit } from "react-admin";
import { EditActions } from "../../component/action";

const CustomerEdit = (props) => (
    <Edit
        actions={<EditActions />}
        title={<CustomerTitle />}
        {...props}
    >
        <CustomerForm {...props} />
    </Edit>
);

export default CustomerEdit;
