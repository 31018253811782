import React, { Component } from "react";
import ListIcon from "@material-ui/icons/List";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";

class BackToListButton extends Component {

    handleClick = () => {
        const { push, resource } = this.props;
        push(`/${resource}`);
    };

    render() {
        const { className, icon, label } = this.props;

        return (
            <Button
                className={className}
                label={label}
                onClick={this.handleClick}
            >
                {icon}
            </Button>
        );
    }
}

BackToListButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string,
    push: PropTypes.func,
    resource: PropTypes.string.isRequired,
};

BackToListButton.defaultProps = {
    icon: (<ListIcon />),
};

export default connect(undefined, { push })(BackToListButton);
