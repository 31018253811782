import ProductCreate from './ProductCreate';
import ProductEdit from "./ProductEdit";
import ProductIcon from '@material-ui/icons/LocalGroceryStore';
import ProductList from "./ProductList";
import ProductShow from "./ProductShow";

const product = {
    create: ProductCreate,
    edit: ProductEdit,
    icon: ProductIcon,
    list: ProductList,
    show: ProductShow,
}

export default product;
