import React from "react";
import BookingCardAccomodations from "./BookingCardAccomodations";
import BookingCardConditions from "./BookingCardConditions";
import BookingCardDetails from "./BookingCardDetails";
import BookingCardHistory from "./BookingCardHistory";
import BookingCardPassengers from "./BookingCardPassengers";
import BookingCardState from "./BookingCardState";
import BookingCardTitle from "./BookingCardTitle";
import BookingCardWarning from "./BookingCardWarning";
import PropTypes from "prop-types";
import { Card, makeStyles } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { dateOptions, dateTimeOptions, timeOptions } from "../../../util/dates";
import { useLocale } from "react-admin";

const useStyles = makeStyles(theme =>({
    avatar: {
        height: theme.spacing(9),
        width: theme.spacing(9),
    },
    bold: {
        fontWeight: 700,
    },
    passenger: {
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            alignItems: "flex-start",
            flexDirection: "column",
        }
    },
    passengerAvatar: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    actions: {
        marginLeft: "auto",
        flexShrink: 0,
    },
    inlineContainer: {
        alignItems: "center",
        display: "flex",
    },
    inline: {
        display: "flex",
        marginRight: theme.spacing(2),
    },
    spaced: {
        "& > *": {
            marginRight: theme.spacing(2),
            "&:last-child": {
                marginRight: 0,
            },
        },
    },
    notice: {},
    error: {
        background: "#f44336",
        color: theme.palette.common.white,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    warning: {
        background: "#ffb700",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
}));

const BookingCard = ({ record }) => {
    const classes = useStyles();
    const locale = useLocale();
    const { accomodations, checkInType } = record;
    const currencyFormatter = new Intl.NumberFormat(locale, { style: "currency", currency: "EUR" });
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const timeFormatter = new Intl.DateTimeFormat(locale, timeOptions);
    const hasAccomodations = checkInType === "staff" && accomodations && accomodations.length > 0;

    return (
        <Card>
            <BookingCardTitle
                classes={classes}
                dateFormatter={dateFormatter}
                timeFormatter={timeFormatter}
                record={record}
            />
            <BookingCardWarning
                classes={classes}
                record={record}
            />
            <BookingCardState
                classes={classes}
                record={record}
            />
            <BookingCardDetails
                classes={classes}
                currencyFormatter={currencyFormatter}
                dateTimeFormatter={dateTimeFormatter}
                record={record}
            />
            <BookingCardPassengers
                classes={classes}
                dateTimeFormatter={dateTimeFormatter}
                record={record}
            />
            {
                hasAccomodations && (
                    <BookingCardAccomodations
                        classes={classes}
                        record={record}
                    />
                )
            }
            <BookingCardConditions
                classes={classes}
                dateTimeFormatter={dateTimeFormatter}
                record={record}
            />
            <BookingCardHistory
                classes={classes}
                dateTimeFormatter={dateTimeFormatter}
                record={record}
            />
        </Card>
    );
};

BookingCard.propTypes = {
    record: PropTypes.object.isRequired,
};

BookingCard.defaultProps = {
    record: {},
};

export default BookingCard;
