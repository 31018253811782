import React from "react";
import IncidentSeverityField from "./IncidentSeverityField";
import { Datagrid, DateField, TextField, useTranslate } from "react-admin";
import { dateTimeOptions } from "../../util/dates";

const IncidentDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid {...props}>
            <DateField
                label={translate("incident.label.occurredAt")}
                source="occurredAt"
                options={dateTimeOptions}
            />
            <TextField
                label={translate("incident.label.title")}
                source="title"
            />
            <IncidentSeverityField
                label={translate("incident.label.severity")}
                source="severity"
            />
        </Datagrid>
    );
}

export default IncidentDatagrid;
