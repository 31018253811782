import React from "react";
import get from "lodash/get";
import { BoxField } from "../../component/box";
import { FunctionField } from "react-admin";
import { Typography } from "@material-ui/core";

const LabelArrayField = ({ source }) => (
    <FunctionField render={
        record => {
            if (! record) {
                return null;
            }

            const items = get(record, source);

            if (! Array.isArray(items) || ! items.length) {
                return null;
            }

            return items.map(item => {
                const { lang, label } = item;

                return (
                    <BoxField
                        label={
                            <Typography color="textSecondary" variant="body2">
                                {lang}
                            </Typography>
                        }
                    >
                        <Typography color="textPrimary" variant="body1">
                            {label}
                        </Typography>
                    </BoxField>
                );
            });
        }
    } />
);

export default LabelArrayField;
