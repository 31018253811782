import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import { useLocale, useRecordContext } from "react-admin";

const CurrencyField = ({ source, options }) => {
    const locale = useLocale();
    const record = useRecordContext();
    const formatter = new Intl.NumberFormat(locale, options);

    return (
        <Typography color="textPrimary" component="span" variant="body2">
            {formatter.format(get(record, source))}
        </Typography>
    )
};

CurrencyField.propTypes = {
    options: PropTypes.object,
    source: PropTypes.string,
};

CurrencyField.defaultProps = {
    options: { style: "currency", currency: "EUR" },
};

export default CurrencyField;
