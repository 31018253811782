import React from "react";
import formatString from "format-string-by-pattern";
import { BooleanInput, FormDataConsumer, TextInput, required, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import { PaymentMethod, PaymentMethodInput } from "../../payment";
import { WizardFormStep } from "../../../component/wizard";
import { cardExpiry, cardNumber, cardSecurityCode, voucherNumber } from "../../../util/validation";

const validate = [required()];
const validateCard = [required(), cardNumber()];
const validateExpiry = [required(), cardExpiry()];
const validateSecurityCode = [required(), cardSecurityCode()];
const validateVoucher = [required(), voucherNumber()];

const BookingFormStepThree = () => {
    const translate = useTranslate();

    return (
        <WizardFormStep label={translate("booking.step.two")}>
            <Grid container spacing={1}>
                <FormDataConsumer>
                    {
                        ({ formData }) => {
                            const { product = {}} = formData;
                            const { allowRedeemVoucher = false } = product;

                            return (
                                <Grid item xs={12}>
                                    <PaymentMethodInput
                                        allowRedeemVoucher={allowRedeemVoucher}
                                        fullWidth
                                        label={translate("payment.label.method")}
                                        helperText={translate(allowRedeemVoucher ?
                                            "payment.help.allowRedeemVoucher" :
                                            "payment.help.disallowRedeemVoucher"
                                        )}
                                        validate={validate}
                                    />
                                </Grid>
                            );
                        }
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {
                        ({ formData }) => {
                            const { paymentMethod, product = {} } = formData;
                            const { cancellable = false } = product;

                            return paymentMethod === PaymentMethod.CARD ? (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextInput
                                            fullWidth
                                            label={translate("payment.label.cardNumber")}
                                            parse={formatString("9999-9999-9999-9999")}
                                            placeholder={"9999-9999-9999-9999"}
                                            source="paymentCardNumber"
                                            validate={validateCard}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput
                                            fullWidth
                                            label={translate("payment.label.cardExpiry")}
                                            parse={formatString("99/99")}
                                            placeholder={"99/99"}
                                            source="paymentCardExpiry"
                                            validate={validateExpiry}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput
                                            fullWidth
                                            label={translate("payment.label.cardSecurityCode")}
                                            parse={formatString("999")}
                                            placeholder={"999"}
                                            source="paymentCardSecurityCode"
                                            type="password"
                                            validate={validateSecurityCode}
                                        />
                                    </Grid>
                                    {
                                        cancellable && (
                                            <Grid item xs={12}>
                                                <BooleanInput
                                                    fullWidth
                                                    label={translate("booking.label.cancellation")}
                                                    source="cancellation"
                                                />
                                            </Grid>
                                        )
                                    }
                                </React.Fragment>
                            ) : paymentMethod === PaymentMethod.VOUCHER ? (
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label={translate("payment.label.voucherNumber")}
                                        source="voucherNumber"
                                        validate={validateVoucher}
                                    />
                                </Grid>
                            ) : null
                        }
                    }
                </FormDataConsumer>
            </Grid>
        </WizardFormStep>
    );
};

export default BookingFormStepThree;
