import polyglotI18nProvider from "ra-i18n-polyglot";
import enMessages from "./i18n/en";

const DEFAULT_LOCALE = "en";

const messages = {
    en: enMessages,
};

// Try to detect whether the browser locale matches a supported locale.
// Use the found locale or the default locale.
const getBrowserLocaleOrDefault = () => {
    const locale = (navigator.language || navigator.userLanguage).split('-').shift();

    return locale in messages ? locale : DEFAULT_LOCALE;
};

export default polyglotI18nProvider(locale => messages[locale], getBrowserLocaleOrDefault());
