import BookingChangeCauseInput from "../BookingChangeCauseInput";
import BookingChangeTimeInput from "./BookingChangeTimeInput";
import ChangeIcon from "@material-ui/icons/CalendarToday";
import React from "react";
import { DateInput, FormDataConsumer, TextInput, required, useLocale, useTranslate } from "react-admin";
import { Typography} from "@material-ui/core";
import { UpdateWithFormButton } from "../../../component/button";
import { dateOptions, dateTimeOptions } from "../../../util/dates";

const validate = [required()];

const BookingChangeButton = props => {
    const locale = useLocale();
    const translate = useTranslate();
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const { record } = props;
    const { changeableUntil, emailAddress, locator } = record;
    const date = new Date(Date.parse(changeableUntil));

    return (
        <UpdateWithFormButton
            action="change"
            icon={<ChangeIcon />}
            initialValues={{
                emailAddress,
                locator,
            }}
            path="change/"
            resource="booking"
            {...props}
        >
            {
                date && (
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        {translate("booking.message.changeableUntil", {
                            date: dateTimeFormatter.format(date),
                        })}
                    </Typography>
                )
            }
            <DateInput
                fullWidth
                label={translate("booking.label.changeToDate")}
                source="changeToDate"
                options={dateOptions}
                validate={validate}
            />
            <FormDataConsumer fullWidth>
                {
                    ({ formData, ...rest }) => (
                        <BookingChangeTimeInput
                            fullWidth
                            label={translate("booking.label.changeToTime")}
                            record={record}
                            source="changeToTime"
                            validate={validate}
                            value={formData.changeToDate}
                            {...rest}
                        />
                    )
                }
            </FormDataConsumer>
            <BookingChangeCauseInput
                fullWidth
                label={translate("booking.label.changeCause")}
                source="changeCause"
                validate={validate}
            />
            <TextInput
                fullWidth
                label={translate("booking.label.changeNotes")}
                multiline
                resettable
                source="changeNotes"
            />
        </UpdateWithFormButton>
    );
};

export default BookingChangeButton;
