const darkTheme = {
    palette: {
        primary: {
            main: "#90caf9",
        },
        secondary: {
            main: "#FBBA72",
        },
        // Switching the dark mode on is a single property value change.
        type: "dark",
    },
};

const lightTheme = {
    palette: {
        secondary: {
            main: "#1976d2",
        },
        type: "light",
    },
};

export {
    darkTheme,
    lightTheme,
}
