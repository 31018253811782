import React, {useState} from "react";
import PropTypes from "prop-types";
import Logo from "./Logo";
import { Field, Form } from "react-final-form";
import { useLocation } from "react-router-dom";
import { Notification, useLogin, useNotify, useTranslate } from "react-admin";
import { Button, Card, CardActions, CircularProgress, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    actions: {
        flexDirection: "column",
        padding: "0 1em 1em 1em",
    },
    link: {
        display: "flex",
        marginTop: "1em",
        justifyContent: "center",
    },
    form: {
        padding: "0 1em 1em 1em",
    },
    input: {
        marginTop: "1em",
    },
    logo: {
        margin: "1em auto",
        display: "flex",
        justifyContent: "center",
        width: "128px",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    signInCard: {
        minWidth: 300,
        marginTop: "6em",
    },
    signUpCard: {
        minWidth: 400,
        marginTop: "4em",
    },
}));

const getErrorMessageFor = err => typeof err === "string" ? err : err && err.message ? err.message : "ra.signIn.error";

const validateCredentials = translate => auth => {
    const errors = {};

    if (! auth.username) {
        errors.username = translate("ra.validation.required");
    }

    if (! auth.password) {
        errors.password = translate("ra.validation.required");
    }

    return errors;
};

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        fullWidth
        error={!! (touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
    />
);

const SignIn = ({ signUp, onSignUp, username }) => {
    const classes = useStyles();
    const location = useLocation();
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);

    const handleSignIn = async (auth) => {
        setLoading(true);

        try {
            const user = await login(auth, location.state ? location.state.nextPathname : "/")

            notify("ra.signIn.success", "info", { ...user });
        } catch (err) {
            setLoading(false);
            notify(getErrorMessageFor(err), "warning");
        }
    };

    return (
        <Form
            onSubmit={handleSignIn}
            validate={validateCredentials(translate)}
            render={
                ({ handleSubmit }) => (
                    <form id="signin" onSubmit={handleSubmit} noValidate>
                        <div className={classes.main}>
                            <Card className={classes.signInCard}>
                                <div className={classes.logo}>
                                    <Logo />
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            name="username"
                                            component={renderInput}
                                            label={translate("ra.signIn.username")}
                                            disabled={loading}
                                            initialValue={username}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="password"
                                            component={renderInput}
                                            label={translate("ra.signIn.password")}
                                            type="password"
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                    >
                                        {
                                            loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )
                                        }
                                        {translate("ra.action.signIn")}
                                    </Button>
                                    {
                                        !! signUp ? (
                                            <Button
                                                className={classes.link}
                                                color="primary"
                                                disabled={loading}
                                                fullWidth
                                                size="small"
                                                type="button"
                                                onClick={onSignUp}
                                            >
                                                {translate("ra.action.signUp")}
                                            </Button>
                                        ) : null
                                    }
                                </CardActions>
                            </Card>
                            <Notification />
                        </div>
                    </form>
                )
            }
        />
    );
};

SignIn.propTypes = {
    signIn: PropTypes.object,
    signUp: PropTypes.any,
    username: PropTypes.string,
    onSignUp: PropTypes.func,
};

SignIn.defaultProps = {
    signIn: {},
    signUp: false,
    onSignUp: () => {},
};

export default SignIn;
