import React from "react";
import PropTypes from "prop-types";
import { SelectInput, useLocale } from "react-admin";

const MonthInput = ({ format, source, ...props }) => {
    const locale = useLocale();
    const formatter = new Intl.DateTimeFormat(locale, { month: format });
    const year = new Date().getFullYear();
    const choices = Array
        .from(Array(12).keys())
        .map((_, i) => ({ id: i, name: formatter.format(new Date(Date.UTC(year, i))), }));

    return (
        <SelectInput
            choices={choices}
            source={source}
            translateChoice={false}
            {...props}
        />
    );
};

MonthInput.propTypes = {
    format: PropTypes.oneOf(["2-digit", "numeric", "narrow", "short", "long"]),
    source: PropTypes.string.isRequired,
};

MonthInput.defaultProps = {
    format: "long",
};

export default MonthInput;
