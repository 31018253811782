import React from "react";
import { Filter, useTranslate } from "react-admin";
import { SearchInput } from "../../component/input";

const IdentityFilter = props => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput
                alwaysOn
                label={translate("identity.label.identifier")}
                source="identity.lk"
            />
        </Filter>
    );
}

export default IdentityFilter;
