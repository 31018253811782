import React, { Fragment } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listItem: {
    },
    listIcon: {
        minWidth: "40px",
    },
    listItemText: {
        color: theme.palette.text.secondary,
        paddingLeft: 0,
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
}));

const SubMenu = ({
    children,
    handleToggle,
    icon,
    isOpen,
    primaryText,
    secondaryText,
    sidebarIsOpen,
}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <ListItem
                button
                dense
                className={classes.listItem}
                onClick={handleToggle}
            >
                <ListItemIcon className={classes.listIcon}>
                    {isOpen ? <ExpandMoreIcon /> : icon}
                </ListItemIcon>
                <ListItemText
                    inset
                    className={classes.listItemText}
                    primary={
                        <Typography variant="body1">
                            {primaryText}
                        </Typography>
                    }
                    secondary={isOpen ? "" : secondaryText}
                />
            </ListItem>
            <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
            >
                <List
                    className={ sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed }
                    component="div"
                    dense
                    disablePadding
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    );
};

SubMenu.propTypes = {
    children: PropTypes.any,
    handleToggle: PropTypes.func,
    icon: PropTypes.any.isRequired,
    isOpen: PropTypes.bool,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    sidebarIsOpen: PropTypes.bool,
};

export default SubMenu;
