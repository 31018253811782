import React, {useCallback, useEffect, useState} from "react";
import OrderDatagrid from "./OrderDatagrid";
import OrderFilter from "./OrderFilter";
import OrderSimpleList from "./OrderSimpleList";
import OrderStatus from "./OrderStatus";
import OrderTitle from "./OrderTitle";
import { List, ListContextProvider, useListContext, useTranslate } from "react-admin";
import { ListActions } from "../../component/action";
import { Divider, Tabs, Tab, makeStyles, useMediaQuery } from "@material-ui/core";

const tabs = [
    { id: OrderStatus.PENDING_CHECK_IN, name: "order.status.pendingCheckIn" },
    { id: OrderStatus.CHECKED_IN, name: "order.status.checkedIn" },
    { id: OrderStatus.DROPPED, name: "order.status.dropped" },
    { id: OrderStatus.EXPIRED, name: "order.status.expired" },
    { id: OrderStatus.CANCELLED, name: "order.status.cancelled" },
    { id: OrderStatus.CHECKED_OUT, name: "order.status.checkedOut" },
];

const useStyles = makeStyles({
    tabs: {
        maxWidth: "100%",
    },
    total: {
        fontWeight: "bold",
    },
});

const TabbedDatagrid = props => {
    const classes = useStyles()
    const listContext = useListContext();
    const translate = useTranslate();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const [cancelled, setCancelled] = useState([]);
    const [checkedIn, setCheckedIn] = useState([]);
    const [checkedOut, setCheckedOut] = useState([]);
    const [dropped, setDropped] = useState([]);
    const [expired, setExpired] = useState([]);
    const [pendingCheckIn, setPendingCheckIn] = useState([]);
    const totals = {};

    useEffect(() => {
        if (ids && ids !== filterValues.status) {
            switch (filterValues.status) {
                case OrderStatus.CANCELLED:
                case OrderStatus.REFUNDED:
                    setCancelled(ids);
                    break;
                case OrderStatus.CHECKED_IN:
                    setCheckedIn(ids);
                    break;
                case OrderStatus.CHECKED_OUT:
                    setCheckedOut(ids);
                    break;
                case OrderStatus.DROPPED:
                    setDropped(ids);
                    break;
                case OrderStatus.EXPIRED:
                    setExpired(ids);
                    break;
                case OrderStatus.PENDING_CHECK_IN:
                    setPendingCheckIn(ids);
                    break;
                default:
            }
        }
    }, [ids, filterValues.status]);

    const handleChange = useCallback((e, value) => {
        setFilters && setFilters({ ...filterValues, status: value }, displayedFilters);
    }, [displayedFilters, filterValues, setFilters]);

    const selectedIds =
        filterValues.status === OrderStatus.CANCELLED ? cancelled :
        filterValues.status === OrderStatus.CHECKED_IN ? checkedIn :
        filterValues.status === OrderStatus.CHECKED_OUT ? checkedOut :
        filterValues.status === OrderStatus.DROPPED ? dropped :
        filterValues.status === OrderStatus.EXPIRED ? expired :
        filterValues.status === OrderStatus.PENDING_CHECK_IN ? pendingCheckIn :
        [];

    return (
        <>
            <Tabs
                className={classes.tabs}
                centered
                indicatorColor="primary"
                scrollButtons="auto"
                value={filterValues.status}
                variant="fullWidth"
                onChange={handleChange}
            >
                {
                    tabs.map(tab => {
                        const { id, name } = tab;
                        const label = typeof totals[name] !== "undefined" ? `${name} (${totals[name]})` : name;

                        return (
                            <Tab
                                key={id}
                                label={translate(label)}
                                value={id}
                            />
                        );
                    })
                }
            </Tabs>
            <Divider />
            <ListContextProvider value={{ ...listContext, ids: selectedIds }}>
                {
                    isXSmall ? (
                        <OrderSimpleList {...props} ids={selectedIds} />
                    ) : (
                        <OrderDatagrid {...props} ids={selectedIds} />
                    )
                }
            </ListContextProvider>
        </>
    );
};

const OrderList = props => {
    return (
        <List
            actions={<ListActions />}
            filterDefaultValues={{ status: OrderStatus.PENDING_CHECK_IN }}
            filters={<OrderFilter />}
            perPage={32}
            title={<OrderTitle />}
            sort={{ field: "checkInAt", order: "DESC" }}
            {...props}
        >
            <TabbedDatagrid />
        </List>
    );
};

export default OrderList;
