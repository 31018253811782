import React from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import NextIcon from "@material-ui/icons/ArrowRight";
import PrevIcon from "@material-ui/icons/ArrowLeft";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, makeStyles } from "@material-ui/core";
import { SaveButton, useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
    toolbar: {
        backgroundColor: theme.palette.type === "light" ?
            theme.palette.grey[100] : theme.palette.grey[900],
    },
    desktopToolbar: {
        marginTop: theme.spacing(2),
    },
    mobileToolbar: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "16px",
        width: "100%",
        boxSizing: "border-box",
        flexShrink: 0,
        zIndex: 2,
    },
    defaultToolbar: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
    },
    spacer: {
        [theme.breakpoints.down("xs")]: {
            height: "5em",
        },
    },
}), { name: "RaToolbar" });

const valueOrDefault = (value, defaultValue) =>
    typeof value === 'undefined' ? defaultValue : value;

const WizardToolbar = ({
    alwaysEnableSaveButton,
    basePath,
    children,
    className,
    error,
    handlePrev,
    handleSubmit,
    handleSubmitWithRedirect,
    isFirstStep,
    isLastStep,
    loading,
    nextLabel,
    prevLabel,
    pristine,
    record,
    redirect,
    resource,
    submitOnEnter,
    undoable,
    width,
    ...rest
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const disabled = loading || ! valueOrDefault(alwaysEnableSaveButton, ! pristine);

    return (
        <React.Fragment>
            <MuiToolbar
                role="toolbar"
                className={classnames(classes.toolbar, {
                    [classes.mobileToolbar]: width === "xs",
                    [classes.desktopToolbar]: width !== "xs",
                }, className)}
                {...rest}
            >
                {
                    React.Children.count(children) === 0 ? (
                        <div className={classes.defaultToolbar}>
                            <Button
                                disabled={isFirstStep || disabled}
                                type="button"
                                variant="contained"
                                onClick={handlePrev}
                            >
                                <PrevIcon />
                                {translate(prevLabel)}
                            </Button>
                            {
                                isLastStep ? (
                                    <SaveButton
                                        handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
                                        disabled={disabled}
                                        invalid={!! error}
                                        redirect={redirect}
                                        saving={loading}
                                        submitOnEnter={submitOnEnter}
                                    />
                                ) : (
                                    <Button
                                        color="primary"
                                        disabled={disabled}
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSubmit}
                                    >
                                        {translate(nextLabel)}
                                        <NextIcon />
                                    </Button>
                                )
                            }
                        </div>
                    ) : (
                        React.Children.map(children, button => {
                            if (! button || ! React.isValidElement(button)) {
                                return null;
                            }

                            const { props } = button;

                            return React.cloneElement(button, {
                                basePath: valueOrDefault(props.basePath, basePath),
                                handleSubmit: valueOrDefault(props.basePath, handleSubmit),
                                handleSubmitWithRedirect: valueOrDefault(props.basePath, handleSubmitWithRedirect),
                                error,
                                pristine,
                                record: valueOrDefault(props.record, record),
                                resource: valueOrDefault(props.record, resource),
                                loading,
                                submitOnEnter: valueOrDefault(props.submitOnEnter, submitOnEnter),
                                undoable: valueOrDefault(props.undoable, undoable),
                            });
                        })
                    )
                }
            </MuiToolbar>
            <div className={classes.spacer} />
        </React.Fragment>
    );
};

WizardToolbar.propTypes = {
    alwaysEnableSaveButton: PropTypes.bool,
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object,
    error: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleSubmitWithRedirect: PropTypes.func,
    isFirstStep: PropTypes.bool,
    isLastStep: PropTypes.bool,
    loading: PropTypes.bool,
    nextLabel: PropTypes.string,
    prevLabel: PropTypes.string,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    submitOnEnter: PropTypes.bool,
    undoable: PropTypes.bool,
    width: PropTypes.string,
};

WizardToolbar.defaultProps = {
    submitOnEnter: true,
};

export default WizardToolbar;
