import React from "react";
import { TextInput, useTranslate } from "react-admin";

const SearchInput = props => {
    const translate = useTranslate();

    return (
        <TextInput
            resettable
            source="keywords"
            label={translate("ra.action.search")}
            {...props}
        />
    );
};

export default SearchInput;
