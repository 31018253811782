import React from "react";
import CustomerDatagrid from "./CustomerDatagrid";
import CustomerSimpleList from "./CustomerSimpleList";
import CustomerTitle from "./CustomerTitle";
import { List, Responsive } from "react-admin";
import { ListActions } from "../../component/action";

const CustomerList = props => {
    return (
        <List
            actions={<ListActions />}
            title={<CustomerTitle />}
            {...props}
        >
            <Responsive
                small={<CustomerSimpleList />}
                medium={<CustomerDatagrid rowClick="show" />}
            />
        </List>
    );
};

export default CustomerList;
