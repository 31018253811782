import React from "react";
import { SimpleList } from "react-admin";

const CustomerSimpleList = props => {
    return (
        <SimpleList
            primaryText={record => record.username}
            secondaryText={record => record.id}
            tertiaryText={record => record.emailAddress}
            {...props}
        />
    );
};

export default CustomerSimpleList;
