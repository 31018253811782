import React from "react";
import IdentityForm from "./IdentityForm";
import IdentityTitle from "./IdentityTitle";
import { Edit } from "react-admin";

const IdentityEdit = (props) => (
    <Edit
        title={<IdentityTitle />}
        {...props}
    >
        <IdentityForm {...props} />
    </Edit>
);

export default IdentityEdit;
