import InvoiceIcon from "@material-ui/icons/Receipt";
import PropTypes from "prop-types";
import React from "react";
import { BoxField } from "../../../component/box";
import { Tooltip, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";
import config from "../../../config";

const BookingCardInvoice = ({ classes, dateTimeFormatter, record }) => {
    const translate = useTranslate();
    const { invoice } = record;

    if (! invoice) {
        return null;
    }

    const { createdAt, mediaType, number, url } = invoice;
    const { serviceURL } = config;
    const date = new Date(Date.parse(createdAt));

    return (
        <BoxField fullWidth label={
            <Typography color="textSecondary" variant="subtitle2">
                {translate("booking.label.invoice")}
            </Typography>
        }>
            <div className={classes.inlineContainer}>
                <Tooltip title={translate("booking.message.downloadInvoice")}>
                    <a
                        className={classes.inline}
                        component={HTMLAnchorElement}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={serviceURL + url}
                    >
                        <InvoiceIcon />
                    </a>
                </Tooltip>
                <div>
                    <Typography className={classes.bold} variant="body2">
                        {number}
                    </Typography>
                    <Typography variant="body2">
                        {dateTimeFormatter.format(date)}
                        {" • "}
                        {mediaType}
                    </Typography>
                </div>
            </div>
        </BoxField>
    );
};

BookingCardInvoice.propTypes = {
    classes: PropTypes.object,
    dateTimeFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardInvoice.defaultProps = {
    classes: {},
};

export default BookingCardInvoice;
