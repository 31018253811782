import React from "react";
import ProductFilter from "./ProductFilter";
import ProductTitle from "./ProductTitle";
import ProductSimpleList from "./ProductSimpleList";
import ProductDatagrid from "./ProductDatagrid";
import { List, Responsive } from "react-admin";
import { ListActions } from "../../component/action";

const ProductList = (props) => (
    <List
        actions={<ListActions />}
        filters={<ProductFilter />}
        title={<ProductTitle />}
        {...props}
    >
        <Responsive
            small={
                <ProductSimpleList />
            }
            medium={
                <ProductDatagrid rowClick="show" />
            }
        />
    </List>
);

export default ProductList;
