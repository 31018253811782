import BookingChangeCause from "./BookingChangeCause";

const bookingChangeCauseChoices = [
    { id: BookingChangeCause.CUSTOMER_REQUEST, name: "booking.change.customerRequest" },
    { id: BookingChangeCause.STOCK, name: "booking.change.stock" },
    { id: BookingChangeCause.WEATHER, name: "booking.change.weather" },
    { id: BookingChangeCause.OTHER, name: "booking.change.other" },
];

export {
    bookingChangeCauseChoices,
};
