import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import WizardToolbar from "./WizardToolbar";
import WizardStepper from "./WizardStepper";
import classnames from "classnames";
import { CardContent, useMediaQuery } from "@material-ui/core";
import { Form } from "react-final-form";
import { FormContextProvider, useCreate, useNotify, useRedirect, useTranslate } from "react-admin";

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const WizardForm = ({
    basePath,
    children,
    className,
    debug,
    failureMessage,
    initialValues,
    nextLabel,
    prevLabel,
    pristine,
    record,
    redirect,
    resource,
    stepLabel,
    stepper,
    submitOnEnter,
    successMessage,
    toolbar,
    undoable,
    ...rest
}) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        step: 0,
        values: initialValues,
    });

    const [create, { loading, error }] = useCreate(resource, record);

    const { step } = state;
    const steps = children.length;
    const activeStep = React.Children.toArray(children)[step];
    const isFirstStep = step === 0;
    const isLastStep = step === steps - 1;

    const stepLabels = React.Children.map(children, (currentStep, i) => {
        const { label } = currentStep.props;

        return label || translate(stepLabel, { step: i + 1, steps });
    });

    const handleCreate = useCallback(
        (values, redirect) => {
            create({
                payload: {
                    data: { ...values },
                },
            }, {
                mutationMode: "pessimistic",
                onFailure: error => notify(failureMessage, "warning", { ...error }),
                onSuccess: ({ data: newRecord }) => {
                    notify(successMessage, "info", { ...newRecord });

                    if (redirect) {
                        redirectTo(typeof redirect === "function" ? redirect(newRecord) : redirect, basePath, newRecord);
                    }
                },
            });
        },
        [basePath, create, notify, redirectTo, failureMessage, successMessage]
    );

    const handlePrev = () => {
        if (step > 0) {
            setState({ ...state, step: step - 1 });
        }
    };

    const handleNext = values => {
        if (step < steps - 1) {
            setState({ step: step + 1, values });
        }
    };

    // NOTE: Both validate and handleSubmit switching are implemented here
    // because Redux Final Form does not accept changes to those functions
    // once the form has been defined

    const validate = values => {
        if (! activeStep) {
            return {};
        }

        const { validate: validateStep } = activeStep.props;

        if (typeof validateStep !== "function") {
            return {};
        }

        return validateStep(values);
    };

    const submit = values => {
        if (isLastStep) {
            handleCreate(values, redirect);
        } else {
            handleNext(values);
        }
    };

    return (
        <FormContextProvider>
            <Form
                initialValues={initialValues}
                subscription={defaultSubscription}
                validate={validate}
                onSubmit={submit}
                {...rest}
                render={
                    ({ handleSubmit }) => (
                        <form
                            className={classnames("form-form", className)}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <CardContent>
                                {
                                    ! isSmall && stepper && React.cloneElement(stepper, {
                                        alternativeLabel: true,
                                        activeStep: step,
                                        stepLabels,
                                    })
                                }
                                {activeStep}
                            </CardContent>
                            {
                                toolbar && React.cloneElement(toolbar, {
                                    basePath,
                                    error,
                                    handlePrev,
                                    handleSubmit,
                                    isFirstStep,
                                    isLastStep,
                                    nextLabel,
                                    prevLabel,
                                    pristine,
                                    record,
                                    redirect,
                                    resource,
                                    loading,
                                    submitOnEnter,
                                    undoable,
                                })
                            }
                            {
                                debug && (
                                    <pre>{JSON.stringify(state, 0, 2)}</pre>
                                )
                            }
                        </form>
                    )
                }
            />
        </FormContextProvider>
    );
};

WizardForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    debug: PropTypes.bool,
    initialValues: PropTypes.object,
    nextLabel: PropTypes.string,
    prevLabel: PropTypes.string,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    stepper: PropTypes.element,
    toolbar: PropTypes.element,
    undoable: PropTypes.bool,
};

WizardForm.defaultProps = {
    debug: false,
    failureMessage: "ra.wizard.failure",
    initialValues: {},
    nextLabel: "ra.action.next",
    prevLabel: "ra.action.prev",
    stepLabel: "ra.wizard.step",
    stepper: <WizardStepper />,
    toolbar: <WizardToolbar />,
    submitOnEnter: true,
    successMessage: "ra.wizard.success",
};

export default WizardForm;
