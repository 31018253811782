import React from "react";
import CustomerForm from "./CustomerForm";
import CustomerTitle from "./CustomerTitle";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {};

const CustomerCreate = (props) => (
    <Create
        actions={<CreateActions />}
        title={<CustomerTitle />}
        {...props}
    >
        <CustomerForm initialValues={initialValues} {...props} />
    </Create>
);

export default CustomerCreate;
