import PropTypes from "prop-types";
import React from "react";
import StockAddEventButton from "./button/StockAddEventButton";
import { CloneButton, CreateButton, EditButton, ListButton, TopToolbar, useShowContext } from "react-admin";

const StockShowActions = ({ basePath }) => {
    const { record } = useShowContext();

    if (! record || ! record.id) {
        return null;
    }

    return (
        <TopToolbar>
            <StockAddEventButton record={record} />
            <EditButton basePath={basePath} record={record} />
            <CloneButton basePath={basePath} record={record} />
            <ListButton basePath={basePath} />
            <CreateButton basePath={basePath} variant="contained" style={{ marginLeft: "1em" }} />
        </TopToolbar>
    );
};

StockShowActions.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.object,
};

StockShowActions.defaultProps = {
    data: {},
};

export default StockShowActions;
