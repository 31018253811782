import React from "react";
import CalendarEvent from "./CalendarEvent";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import classnames from "classnames";
import get from "lodash/get";
import {
    DAY_GRID_DAY,
    DAY_GRID_MONTH,
    DAY_GRID_WEEK,
    LIST_DAY,
    LIST_MONTH,
    LIST_WEEK,
    TIME_GRID_DAY,
    TIME_GRID_WEEK,
    calendarViews,
} from "./calendarUtil";
import { IconButton, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { format } from "date-fns";
import { getColorForStatus, getTextColorForStatus } from "../../resource/order/orderUtils";
import { timeOptions } from "../../util/dates";
import { useListContext, useLocale, useRedirect } from "react-admin";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        "& .fc": {
            "& .fc-scroller": {
                overflow: "hidden",
            },
            "& .fc-timegrid-axis-frame": {
                alignItems: "flex-start",
                "& .fc-timegrid-axis-cushion": {
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    lineHeight: 1.57,
                    paddingTop: "2px",
                },
            },
            "& .fc-col-header-cell-cushion:hover": {
                textDecoration: "none",
            },
            "& .fc-daygrid-day-number": {
                height: theme.spacing(6),
                width: theme.spacing(6),
                padding: theme.spacing(0.5),
                "&:hover": {
                    textDecoration: "none",
                },
            },
            "& .fc-daygrid-week-number": {
                backgroundColor: "transparent",
                fontSize: "10px",
                "&:hover": {
                    textDecoration: "none",
                },
            },
            "& .fc-daygrid-event-harness": {
                marginBottom: theme.spacing(0.5),
            },
            "& .fc-daygrid-event": {
                backgroundColor: "#fff",
                borderColor: "rgb(3, 155, 229)",
                borderStyle: "solid",
                borderWidth: "1px",
                color: "rgb(3, 155, 229)",
                "&.fc-event-future": {
                    backgroundColor: "rgb(3, 155, 229)",
                    borderColor: "rgb(3, 155, 229)",
                    color: "#f5f5f5",
                },
                "&.fc-event-today": {
                    backgroundColor: "rgb(228, 196, 65)",
                    borderColor: "rgb(228, 196, 65)",
                    color: "#222",
                },
            },
            "& .fc-timegrid-slot-label": {
                border: 0,
                position: "relative",
                "& .fc-timegrid-slot-label-cushion": {
                    color: "#70757a",
                    fontSize: "10px",
                    left: 0,
                    position: "absolute",
                    top: "-4px",
                    webkitFontSmoothing: "subpixel-antialiased",
                    width: "100%",
                }
            },
            "& .fc-timegrid-event": {
                "&.fc-event-future": {
                    backgroundColor: "rgb(3, 155, 229)",
                    borderColor: "rgb(3, 155, 229)",
                    color: "#f5f5f5",
                },
                "&.fc-event-today": {
                    backgroundColor: "rgb(228, 196, 65)",
                    borderColor: "rgb(228, 196, 65)",
                    color: "#222",
                },
                "&.fc-event-past": {
                    backgroundColor: "#fff",
                    borderColor: "rgb(3, 155, 229)",
                    color: "rgb(3, 155, 229)",
                },
            },
        },
    },
    day: {
        lineHeight: "1",
    },
    left: {
        textAlign: "left",
    },
    past: {
        color: "rgba(0, 0, 0, 0.26)",
    },
    today: {
        backgroundColor: "#2196f3",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2196f3",
            color: "#fff",
        },
    },
    weekday: {},
    dayButton: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    bigDayButton: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
}));

const CalendarWrap = props => {
    const classes = useStyles();
    const locale = useLocale();
    const redirect = useRedirect();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dayFormatter = new Intl.DateTimeFormat(locale, { day: "numeric" });
    const weekdayFormatter = new Intl.DateTimeFormat(locale, { weekday: "short" });
    const {
        allDaySlot,
        identifier,
        calendar,
        calendarOptions,
        endAt,
        initialDate,
        initialView,
        resource,
        rowClick,
        showNonCurrentDates,
        slotMinTime,
        startAt,
        title,
        onChange,
        onCreate,
        onEventsSet,
    } = props;

    const { data, loaded } = useListContext();
    const events = loaded ? Object.values(data).map(record => ({
        id: get(record, identifier),
        backgroundColor: getColorForStatus(get(record, "status")),
        borderColor: getColorForStatus(get(record, "status")),
        textColor: getTextColorForStatus(get(record, "status")),
        editable: false,
        end: get(record, endAt),
        extendedProps: record,
        display: "auto",
        start: get(record, startAt),
        title: get(record, title) || get(record, identifier),
    })) : [];

    const handleEventClick = eventInfo => {
        const { event: { extendedProps: { locator } }} = eventInfo;

        if (typeof rowClick === "function") {
            rowClick({ id: locator });
        } else if (rowClick === "show") {
            redirect(`/${resource}/${locator}/show`);
        } else if (rowClick === "edit") {
            redirect(`/${resource}/${locator}`);
        } // else Noop
    };

    const renderDayCell = dateInfo => {
        const { date, isPast, isToday, view: { type }} = dateInfo;

        if (type === DAY_GRID_MONTH) {
            return (
                <IconButton
                    className={classnames(classes.dayButton, {
                        [classes.today]: isToday,
                    })}
                    disabled={isPast}
                >
                    <Typography
                        className={classnames(classes.day, {
                            [classes.past]: isPast,
                            [classes.today]: isToday,
                        })}
                        color="textPrimary"
                        component="p"
                        variant="body2"
                    >
                        {dayFormatter.format(date)}
                    </Typography>
                </IconButton>
            );
        }

        return undefined;
    };

    const renderDayHeader = dateInfo => {
        const { date, isPast, isToday, view: { type }} = dateInfo;

        const shouldRenderDayNumber = [
            TIME_GRID_DAY,
            TIME_GRID_WEEK,
            DAY_GRID_DAY,
            DAY_GRID_WEEK,
        ].indexOf(type) > -1;

        const shouldRenderDayNumberInline = [
            LIST_DAY,
            LIST_MONTH,
            LIST_WEEK,
        ].indexOf(type) > -1;

        return (
            <>
                <Typography
                    className={classnames(classes.weekday, {
                        [classes.left]: shouldRenderDayNumberInline,
                        [classes.past]: isPast,
                    })}
                    component="p"
                    variant="subtitle2"
                >
                    {weekdayFormatter.format(date)}
                    {shouldRenderDayNumberInline && " "}
                    {shouldRenderDayNumberInline && dayFormatter.format(date)}
                </Typography>
                {
                    shouldRenderDayNumber && (
                        <IconButton
                            className={classnames(classes.bigDayButton, {
                                [classes.today]: isToday,
                            })}
                            disabled={isPast}
                        >
                            <Typography
                                className={classnames(classes.day, {
                                    [classes.past]: isPast,
                                    [classes.today]: isToday,
                                })}
                                component="p"
                                variant="h5"
                            >
                                {dayFormatter.format(date)}
                            </Typography>
                        </IconButton>
                    )
                }
            </>
        );
    };

    return (
        <div className={classes.root}>
            <FullCalendar
                allDaySlot={allDaySlot}
                dateClick={onCreate}
                datesSet={onChange}
                dayCellContent={renderDayCell}
                dayHeaderContent={renderDayHeader}
                eventClick={handleEventClick}
                eventContent={
                    eventInfo => (
                        <CalendarEvent
                            eventInfo={eventInfo}
                            showTertiary={(/^list/).test(eventInfo.view.type)}
                        />
                    )
                }
                eventsSet={onEventsSet}
                eventTimeFormat={timeOptions}
                events={events}
                firstDay={1}
                headerToolbar={{
                    left: "",
                    center: isSmall ? "title" : "",
                    right: "",
                }}
                initialDate={initialDate}
                initialView={initialView}
                locale={locale}
                navLinks
                nowIndicator
                ref={calendar}
                plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    timeGridPlugin,
                ]}
                showNonCurrentDates={showNonCurrentDates}
                slotLabelFormat={timeOptions}
                slotMinTime={slotMinTime}
                validRange={{
                    start: format(new Date(), "Y-M-d"),
                }}
                weekNumbers
                weekNumberCalculation="ISO"
                {...calendarOptions}
            />
        </div>
    );
};

CalendarWrap.propTypes = {
    allDaySlot: PropTypes.bool,
    calendar: PropTypes.object.isRequired,
    calendarOptions: PropTypes.object,
    identifier: PropTypes.string,
    endAt: PropTypes.string,
    duration: PropTypes.string,
    initialDate: PropTypes.object,
    initialView: PropTypes.oneOf(calendarViews),
    resource: PropTypes.string,
    rowClick: PropTypes.oneOf(["edit", "show"]),
    showNonCurrentDates: PropTypes.bool,
    slotMinTime: PropTypes.string,
    startAt: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    onEventsSet: PropTypes.func,
};

CalendarWrap.defaultProps = {
    allDaySlot: false,
    calendarOptions: {},
    identifier: "id",
    initialDate: new Date(),
    startAt: "checkInAt",
    endAt: "checkOutAt",
    duration: "duration",
    rowClick: "show",
    showNonCurrentDates: true,
    slotMinTime: "06:00:00",
    title: "locator",
    timeFormat: "hh:mm",
    total: 0,
    onChange: () => {},
    onCreate: () => {},
    onEventsSet: () => {},
};

export default CalendarWrap;
