import config from "../../config";
import fingerprintProvider from "../fingerprintProvider";

const KEY_JWT = "adminSessionToken";

const parseToken = token => {
    if (! token) {
        return null;
    }

    const [headerBase64, payloadBase64, signature] = token.split(".");
    const header = JSON.parse(atob(headerBase64));
    const payload = JSON.parse(atob(payloadBase64));

    return { ...header, ...payload, signature };
};

const invalidCredentialsError = () => {
    const error = new Error();
    error.name = "SignInFailed";
    error.message = "ra.signIn.invalid";

    return error;
};

const missingSessionTokenError = () => {
    const error = new Error();
    error.name = "MissingSessionToken";
    error.message = "ra.signIn.missingToken";

    throw error;
};

const jwtAuthProvider = () => {
    const { serviceURL, accessToken } = config;

    let identity = parseToken(localStorage.getItem(KEY_JWT));
    let profile = null;

    return {
        checkAuth: async () => {
            if (! identity) {
                await this.logout();

                // When checkAuth() returns a rejected promise,
                // react-admin redirects to the "/login" page
                throw new Error("ra.auth.check");
            }
        },
        checkError: async (response) => {
            const { status } = response;

            // Other errors don't require to log-out
            if (status === 401 || status === 403) {
                await this.logout();

                // When checkError() returns a rejected promise,
                // react-admin redirects to the "/login" page
                throw new Error("ra.auth.error");
            }
        },
        getPermissions: async () => identity ? identity.role : undefined,
        getIdentity: async () => {
            if (! identity) {
                return null;
            }

            if (! profile) {
                const jwt = localStorage.getItem(KEY_JWT);
                const fingerprint = await fingerprintProvider.getFingerprint();
                const response = await fetch(`${serviceURL}/profile/`, {
                    headers: new Headers({
                        "Authorization": `Bearer ${jwt}`,
                        "Fingerprint": fingerprint,
                        "Origin-Token": accessToken,
                    }),
                    method: "GET",
                    mode: "cors",
                });

                if (response.status !== 200) {
                    await this.logout();

                    throw new Error("ra.auth.error");
                }

                profile = await response.json();
            }

            const { sub } = identity;
            const { avatar, firstName, lastName } = profile;

            // If the identity details are empty (e.g. user has not
            // fill its profile yet), we use the username as fullName
            const fullName = firstName && lastName ? `${firstName} ${lastName}` : sub;

            return { avatar, fullName, id: sub };
        },
        login: async ({ username, password }) => {
            const fingerprint = await fingerprintProvider.getFingerprint();
            const response = await fetch(`${serviceURL}/.well-known/signin/`, {
                body: JSON.stringify({ username, password}),
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Fingerprint": fingerprint,
                    "Origin-Token": accessToken,
                }),
                method: "POST",
                mode: "cors",
            });

            if (response.status !== 200) {
                throw invalidCredentialsError();
            }

            const { token } = await response.json();

            if (! token) {
                throw missingSessionTokenError();
            }

            identity = parseToken(token);
            localStorage.setItem(KEY_JWT, token);

            return identity;
        },
        logout: async () => {
            localStorage.removeItem(KEY_JWT);
            identity = null;

            return undefined;
        },
    };
};

export default jwtAuthProvider;
