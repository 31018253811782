const OrderStatus = {
    PENDING_CHECK_IN: "pending-check-in",
    CANCELLED: "cancelled",
    REFUNDED: "refunded",
    DROPPED: "dropped",
    EXPIRED: "expired",
    CHECKED_IN: "checked-in",
    CHECKED_OUT: "checked-out",
};

export default OrderStatus;
