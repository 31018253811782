import CardWithIcon from "./CardWithIcon";
import React, { useCallback, useEffect, useState } from "react";
import {
    Avatar, Chip,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { SkiJetIcon } from "../icon";
import { dateOptions, timeOptions } from "../../util/dates";
import { useDataProvider, useLocale, useNotify, useTranslate, useVersion } from "react-admin";
import { getProductLabel } from "../../resource/product/productUtils";
import { getColorForStatus, getTextColorForStatus } from "../../resource/order/orderUtils";

const useStyles = makeStyles({
    listItemText: {},
});

const primaryLabel = (record, locale) => {
    const { product } = record;

    if (! product) {
        return record.locator;
    }

    return getProductLabel(product, locale);
};

const secondaryLabel = (record, translate, dateFormatter, timeFormatter) => {
    const {
        checkInAt,
        duration,
        emailAddress,
        firstName,
        lastName,
        maxDuration,
        minDuration,
        status,
    } = record;
    const checkIn = new Date(Date.parse(checkInAt));

    return (
        <>
            <div>
                <Typography
                    color="textPrimary"
                    component="span"
                    variant="body2"
                >
                    {firstName}
                    {" "}
                    {lastName}
                </Typography>
                {" • "}
                {emailAddress}
            </div>
            <div>
                <Typography
                    color="textPrimary"
                    component="span"
                    variant="body2"
                >
                    {dateFormatter.format(checkIn)}
                    {" @ "}
                    {timeFormatter.format(checkIn)}
                </Typography>
                {" • "}
                {
                    minDuration === maxDuration ?
                        translate("booking.message.duration", { duration }) :
                        translate("booking.message.durationRange", { maxDuration, minDuration })
                }
            </div>
            <div style={{ marginTop: "16px" }}>
                <span style={{
                    background: getColorForStatus(status),
                    borderRadius: "3px",
                    color: getTextColorForStatus(status),
                    padding: "8px",
                }}>
                    {status}
                </span>
            </div>
        </>
    );
};

const NextOrders = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const locale = useLocale();
    const notify = useNotify();
    const translate = useTranslate();
    const version = useVersion();
    const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);
    const timeFormatter = new Intl.DateTimeFormat(locale, timeOptions);
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = useCallback(
        async () => {
            try {
                const { data } = await dataProvider.getList("booking", { path: "next/" });

                setBookings(data);
            } catch (e) {
                notify("booking.message.nextFailure", "warning");
                setBookings([]);
                setError(e);
            }

        }, [dataProvider, notify]
    );

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]);

    return (
        <CardWithIcon
            icon={SkiJetIcon}
            subtitle={bookings.length}
            title={translate("booking.message.nextTitle")}
            to="/booking"
        >
            {
                error ? null : bookings.length ? (
                    <List>
                        {
                            bookings.map((record, offset) => (
                                <div>
                                    {
                                        offset ? <Divider /> : null
                                    }
                                    <ListItem
                                        alignItems="flex-start"
                                        button
                                        component={Link}
                                        key={record.id}
                                        to={`/booking/${record.locator}/show`}
                                    >
                                        <ListItemAvatar>
                                            <Avatar src="/asset/or1.png" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            className={classes.listItemText}
                                            primary={primaryLabel(record, locale)}
                                            secondary={secondaryLabel(record, translate, dateFormatter, timeFormatter)}
                                        />
                                        <ListItemSecondaryAction>
                                            <Chip label={record.locator} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </div>
                            ))
                        }
                    </List>
                ) : (
                    <List>
                        <ListItem
                            alignItems="flex-start"
                            button
                            component={Link}
                            to={`/booking/create`}
                        >
                            <ListItemText
                                className={classes.listItemText}
                                primary={translate("booking.message.nextEmpty")}
                            />
                        </ListItem>
                    </List>
                )
            }
        </CardWithIcon>
    );
};

export default NextOrders;
