import React from "react";
import CalendarDatagrid from "./CalendarDatagrid";
import CalendarFilter from "./CalendarFilter";
import CalendarSimpleList from "./CalendarSimpleList";
import CalendarTitle from "./CalendarTitle";
import { List, Responsive } from "react-admin";
import { ListActions } from "../../component/action";

const CalendarList = props => {
    return (
        <List
            actions={<ListActions />}
            filters={<CalendarFilter />}
            title={<CalendarTitle />}
            {...props}
        >
            <Responsive
                small={
                    <CalendarSimpleList />
                }
                medium={
                    <CalendarDatagrid rowClick="show" />
                }
            />
        </List>
    );
};

export default CalendarList;
