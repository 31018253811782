import React from "react";
import StockForm from "./StockForm";
import StockTitle from "./StockTitle";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {
    available: true,
};

const StockCreate = props => (
    <Create
        actions={<CreateActions />}
        title={<StockTitle />}
        {...props}
    >
        <StockForm initialValues={initialValues} />
    </Create>
);

export default StockCreate;
