import React from "react";
import { useTranslate } from "react-admin";

const ProductTitle = ({ record }) => {
    const translate = useTranslate();

    return (
        <span>
            {
                ! record ?    translate("product.message.list") :
                ! record.id ? translate("product.message.new") :
                              translate("product.message.instance", { ...record })
            }
        </span>
    );
}

export default ProductTitle;
