import React from "react";
import { Datagrid, DateField, EmailField, TextField, useTranslate } from "react-admin";
import { WarningField } from "../../component/field";
import { dateTimeOptions } from "../../util/dates";

const OrderDatagrid = props => {
    const translate = useTranslate();

    return (
        <Datagrid
            rowClick={(id, basePath, record) => `/booking/${record.locator}/show` }
            {...props}
        >
            <DateField
                label={translate("order.label.checkInAt")}
                source="checkInAt"
                options={dateTimeOptions}
            />
            <TextField
                label={translate("order.label.locator")}
                source="locator"
            />
            <EmailField
                label={translate("order.label.emailAddress")}
                source="emailAddress"
            />
            <TextField
                label={translate("order.label.phoneNumber")}
                source="phoneNumber"
            />
            <WarningField
                label={translate("order.label.warning")}
                source="warning"
                tooltip="warningMessage"
            />
        </Datagrid>
    );
};

export default OrderDatagrid;
