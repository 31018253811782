import React from "react";
import { Filter } from "react-admin";
import { SearchInput } from "../../component/input";

const StockFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn />
    </Filter>
);

export default StockFilter;
