import React from "react";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CustomerCardOrderIterator from "./CustomerCardOrderIterator";
import PhoneIcon from "@material-ui/icons/Phone";
import StarIcon from "@material-ui/icons/Star";
import { Box, Card, CardContent, Divider, Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { BoxField } from "../../../component/box";
import { ReferenceManyField, useLocale, useTranslate } from "react-admin";
import { dateTimeOptions } from "../../../util/dates";

const useStyles = makeStyles(theme => ({
    divider: {},
    spacing: {
        "& > *": {
            marginRight: theme.spacing(1),
        },
    },
    right: {
        marginLeft: "auto",
    },
    sup: {
        color: theme.palette.text.secondary,
        fontSize: "0.5em",
    },
}));

const CustomerCard = ({ record }) => {
    const classes = useStyles();
    const locale = useLocale();
    const translate = useTranslate();
    const dateFormatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const {
        emailAddress,
        firstName,
        lastName,
        numOrders,
        numOrdersCancelled,
        numOrdersChanged,
        numOrdersChecked,
        numOrdersDropped,
        phoneNumbers,
    } = record;

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">
                    {firstName}
                    {" "}
                    {lastName}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                    {emailAddress}
                </Typography>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("customer.label.stats")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                            {numOrders}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {translate("customer.label.numOrders")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="h5">
                            {numOrdersChecked}
                            <sup className={classes.sup}>/{numOrders}</sup>
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {translate("customer.label.numOrdersChecked")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="h5">
                            {numOrdersChanged}
                            <sup className={classes.sup}>/{numOrders}</sup>
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {translate("customer.label.numOrdersChanged")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="h5">
                            {numOrdersCancelled}
                            <sup className={classes.sup}>/{numOrders}</sup>
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {translate("customer.label.numOrdersCancelled")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography variant="h5">
                            {numOrdersDropped}
                            <sup className={classes.sup}>/{numOrders}</sup>
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {translate("customer.label.numOrdersDropped")}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("customer.label.phoneNumbers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            phoneNumbers.map((phoneNumber, i) => {
                                const {
                                    carrier,
                                    country,
                                    displayForm,
                                    lastUsedAt,
                                    preferred,
                                    uri,
                                    valid,
                                } = phoneNumber;

                                return (
                                    <BoxField key={`phonenumber-${i}`} dense fullWidth>
                                        <Box className={classes.spacing} display="flex" alignItems="center">
                                            <Tooltip title={translate("ra.action.callTo", { carrier, country })}>
                                                <a href={uri}>
                                                    <IconButton>
                                                        <PhoneIcon />
                                                    </IconButton>
                                                </a>
                                            </Tooltip>
                                            <Typography color="textPrimary" variant="body2">
                                                {displayForm}
                                            </Typography>
                                            {
                                                valid ? (
                                                    <CheckCircleIcon color="action" fontSize="small" />
                                                ) : (
                                                    <CancelSharpIcon color="error" fontSize="small" />
                                                )
                                            }
                                            <Typography color="textSecondary" variant="body2">
                                                {translate("customer.message.lastUsedAt", {
                                                    date: dateFormatter.format(lastUsedAt),
                                                })}
                                            </Typography>
                                            {
                                                preferred && (
                                                    <StarIcon className={classes.right} />
                                                )
                                            }
                                        </Box>
                                    </BoxField>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("customer.label.orders")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceManyField
                            reference="order"
                            sort={{ field: "checkInAt", order: "desc" }}
                            target="customer.id"
                        >
                            <CustomerCardOrderIterator classes={classes} />
                        </ReferenceManyField>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("customer.label.notes")}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CustomerCard;
