import StockType from "./StockType";

const stockTypeChoices = [
    { id: StockType.PADDLE, name: "stock.type.paddle" },
    { id: StockType.SKI_JET, name: "stock.type.skiJet" },
];

export {
    stockTypeChoices,
};
