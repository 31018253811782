import React from "react";
import IdentityForm from "./IdentityForm";
import IdentityTitle from "./IdentityTitle";
import { Create } from "react-admin";
import { CreateActions } from "../../component/action";

const initialValues = {};

const IdentityCreate = (props) => (
    <Create
        actions={<CreateActions />}
        title={<IdentityTitle />}
        {...props}
    >
        <IdentityForm initialValues={initialValues} {...props} />
    </Create>
);

export default IdentityCreate;
