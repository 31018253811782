import React from "react";
import PropTypes from "prop-types";

const Logo = function({ className, color }) {
    return (
        <svg
            className={className}
            width="300"
            height="69.760002"
            viewBox="0 0 79.375116 18.457334"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(0.16857078,0,0,0.16857078,-8.0960094,-28.12255)">
                <path style={{ fill: color, stroke: color }} d="m 114.16,170.32 c -8.66,-4.25 -17.9,-4.53 -27.72,-0.86 -9.82,3.68 -18.4,10.26 -25.73,19.75 -7.32,9.5 -11.54,20.89 -12.63,34.19 -0.22,4.64 0.25,8.46 1.41,11.45 1.16,3 2.62,5.2 4.37,6.6 -1.23,6.43 -1.12,12.95 0.32,19.55 0.18,1.41 0.55,2.83 1.12,4.27 0,0.04 0.02,0.11 0.07,0.2 0,0.09 0.02,0.17 0.06,0.26 h 0.07 c 2.19,5.25 6.03,8.62 11.55,10.11 16.27,3.1 33.35,-9.56 51.25,-38 7.35,-13.3 10.96,-26.28 10.83,-38.95 -0.14,-12.66 -5.12,-22.19 -14.97,-28.57 z m -6.23,64.37 c -4.9,7.44 -10.4,13.84 -16.5,19.19 -6.11,5.36 -12,8.84 -17.69,10.44 -5.69,1.6 -10.39,0.49 -14.11,-3.32 -0.92,-5.25 1.16,-12.55 6.24,-21.91 2.31,-1.32 4.92,-2.69 7.8,-4.14 3.94,-2.01 7.64,-4.03 11.09,-6.07 3.46,-2.03 5.73,-3.92 6.83,-5.67 -0.09,-1.18 -0.22,-1.93 -0.39,-2.23 -0.18,-0.31 -0.51,-0.38 -0.99,-0.2 l -18.83,9.12 c 3.8,-5.86 8.55,-12.47 14.24,-19.82 0.74,-1.4 0.93,-2.96 0.56,-4.69 -0.38,-1.73 -1.01,-3.37 -1.91,-4.92 -0.89,-1.55 -1.83,-2.77 -2.79,-3.64 -0.96,-0.88 -1.7,-1.14 -2.23,-0.79 -10.06,11.81 -17.32,22.95 -21.78,33.4 -0.09,-8.92 2.61,-17.78 8.1,-26.58 5.49,-8.79 12.47,-15.67 20.93,-20.63 8.47,-4.97 17.1,-6.23 25.89,-3.78 8.01,3.68 11.5,10.3 10.47,19.85 -1.03,9.56 -6.01,21.69 -14.93,36.39 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 160.5,240.85 c -1.58,2.81 -3.17,5.43 -4.77,7.87 -3.15,4.85 -6.33,8.96 -9.51,12.32 -4.77,5.06 -9.24,8.57 -13.38,10.53 -1.78,-2.7 -2.02,-6.96 -0.73,-12.78 3.78,-13.81 9.21,-22.39 16.28,-25.72 -0.19,1.04 -0.67,2.58 -1.47,4.64 -0.8,2.05 -1.6,3.99 -2.42,5.8 -0.81,1.82 -1.33,2.89 -1.55,3.23 0.11,0 0.22,0.02 0.33,0.05 1.37,0.37 2.75,0.54 4.14,0.5 1.39,-0.03 2.71,-0.71 3.97,-2.03 1.26,-1.31 2.41,-3.69 3.45,-7.13 0.7,-2.86 0.41,-5.12 -0.86,-6.78 -1.28,-1.67 -2.85,-3.11 -4.7,-4.34 -0.48,-0.33 -0.95,-0.68 -1.39,-1.05 -2.93,-1.78 -6.93,-0.32 -12.03,4.39 -5.09,4.7 -9.17,11.18 -12.25,19.44 -2.52,7.89 -2.69,14.04 -0.53,18.45 2.17,4.41 5.66,7.07 10.48,8 6.19,0.29 12.89,-5.12 20.07,-16.22 2.92,-4.49 5.91,-9.92 8.98,-16.28 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 193.23,241.01 c -1.63,2.72 -3.26,5.29 -4.91,7.7 -3.2,4.68 -6.44,8.79 -9.73,12.31 -4.98,5.33 -9.55,8.94 -13.7,10.83 -0.03,-0.11 -0.09,-0.22 -0.16,-0.33 -0.74,-1.3 -1.39,-3.2 -1.95,-5.7 -0.55,-2.5 -0.31,-5.71 0.72,-9.64 0.34,1.3 1.28,2.42 2.84,3.36 1.55,0.95 3.22,1.38 5,1.31 1.96,-0.41 4.12,-1.87 6.47,-4.39 2.35,-2.52 4.3,-5.59 5.83,-9.22 1.54,-3.63 2.1,-7.33 1.67,-11.09 -0.43,-3.76 -2.42,-7.1 -5.97,-10.02 -1.78,-1.23 -4.16,-0.99 -7.14,0.72 -2.98,1.7 -5.98,4.46 -9,8.28 -3.02,3.81 -5.47,8.25 -7.36,13.33 -0.04,0.09 -0.08,0.17 -0.11,0.26 -1.68,3.87 -2.38,7.64 -2.1,11.3 0.01,0.27 0.04,0.55 0.07,0.83 0.46,4 1.78,7.41 3.94,10.22 2.17,2.82 4.83,4.56 7.97,5.22 4.23,0.26 9,-2.54 14.31,-8.41 1.55,-1.71 3.09,-3.61 4.61,-5.71 3.7,-5.09 7.32,-11.31 10.87,-18.66 z m -21.5,-0.63 c 2.96,-3.99 5.66,-6.53 8.11,-7.64 -0.22,2.59 -1.59,6.18 -4.11,10.77 -2.6,4.34 -4.82,7.38 -6.67,9.14 -1.85,1.76 -3.3,1.99 -4.33,0.7 1.7,-4.67 4.03,-8.99 7,-12.97 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 234.51,240.85 c -0.41,0.55 -0.8,1.11 -1.17,1.66 -0.38,0.54 -0.76,1.08 -1.13,1.61 -6.91,9.81 -13.47,18.37 -19.65,25.67 1.26,-4.07 2.53,-7.63 3.81,-10.66 1.27,-3.04 2.91,-6.6 4.92,-10.67 0.18,-0.33 0.35,-0.69 0.49,-1.06 0.86,-1.96 1.01,-3.64 0.48,-5.05 -0.54,-1.41 -1.35,-2.72 -2.42,-3.95 -0.81,-1.11 -1.69,-1.67 -2.64,-1.69 -0.94,-0.02 -1.79,0.75 -2.53,2.3 -1.4,3.3 -2.64,6.13 -3.72,8.5 -1.07,2.45 -2.06,4.78 -2.94,7 -5.78,7.78 -10.21,12.27 -13.28,13.45 -1.08,-1.59 -1.02,-4.55 0.16,-8.86 1.19,-4.32 3.17,-8.72 5.95,-13.22 2.78,-4.5 6.02,-7.87 9.72,-10.09 3.7,-2.22 7.52,-2.05 11.45,0.5 0.59,-4.44 -0.24,-7.52 -2.5,-9.22 -2.26,-1.7 -5.13,-2.23 -8.62,-1.58 -3.48,0.64 -6.7,2.26 -9.66,4.86 -4,3.7 -7.45,8.13 -10.34,13.28 -0.96,1.72 -1.82,3.41 -2.57,5.08 -1.51,3.33 -2.59,6.58 -3.26,9.72 -0.29,1.35 -0.46,2.59 -0.53,3.74 -0.17,2.91 0.33,5.2 1.53,6.9 1.85,1.85 4.09,3.78 6.72,5.78 4.23,2.48 8.39,0.8 12.5,-5.06 1.11,2.64 3.11,4.71 6.01,6.23 1.66,0.74 3.78,-0.34 6.35,-3.22 2.58,-2.89 5.42,-6.88 8.53,-11.95 0.34,-0.55 0.68,-1.11 1.02,-1.67 2.78,-4.59 5.63,-9.48 8.54,-14.67 0.18,-0.25 0.35,-0.53 0.5,-0.83 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 274.29,240.57 -19.39,29.22 c 0.59,-3.33 1.29,-6.5 2.11,-9.5 2.11,-6.85 4.72,-13.22 7.83,-19.11 0.63,-1.85 0.71,-3.44 0.23,-4.78 -0.49,-1.33 -1.15,-2.57 -2.01,-3.72 -0.11,-0.15 -0.2,-0.31 -0.27,-0.5 -0.67,-1.15 -1.17,-1.65 -1.5,-1.5 -2.15,0.89 -4.94,2.4 -8.36,4.53 -3.43,2.13 -6.68,5.08 -9.76,8.86 1.3,-2.93 2.65,-5.83 4.06,-8.72 0.63,-1.74 0.66,-3.23 0.08,-4.47 -0.57,-1.25 -1.36,-2.42 -2.36,-3.53 -0.33,-0.37 -0.65,-0.76 -0.94,-1.17 -1.56,-1.37 -2.95,-0.89 -4.17,1.44 -2.9,5.41 -5.44,10.9 -7.63,16.5 -1.95,4.94 -3.62,9.97 -5.02,15.06 -0.5,1.82 -0.97,3.66 -1.4,5.5 -0.97,4.34 -0.1,7.93 2.6,10.78 1.41,1.15 2.56,1.15 3.45,0 3.63,-8.37 6.93,-14.35 9.89,-17.94 2.96,-3.6 6.17,-6.67 9.61,-9.23 0.41,-0.37 0.83,-0.72 1.28,-1.05 -2.37,5.92 -3.96,11.68 -4.78,17.28 -0.18,1.96 0,3.72 0.56,5.27 1.33,2.64 3.13,4.69 5.39,6.17 1.51,0.74 3.42,-0.33 5.72,-3.22 2.29,-2.89 4.83,-6.86 7.61,-11.92 2.78,-5.06 5.63,-10.47 8.56,-16.25 0.14,-0.26 0.29,-0.54 0.44,-0.83 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 359.95,237.09 c 5.68,-3.04 11.13,-8.48 16.34,-16.31 6.99,-12.91 9.21,-23.66 6.62,-32.25 -2.58,-8.6 -7.48,-14.69 -14.7,-18.28 -9.01,-4.24 -18.62,-4.54 -28.84,-0.89 -10.21,3.66 -19.12,10.25 -26.74,19.79 -7.61,9.54 -12.01,20.95 -13.19,34.25 -0.22,5.47 0.51,9.81 2.17,13.03 1.66,3.21 3.61,5.31 5.84,6.3 2.23,0.98 4.07,0.88 5.51,-0.3 1.23,-1.22 2.44,-3.04 3.64,-5.44 1.21,-2.41 2.3,-4.9 3.28,-7.48 0.99,-2.58 1.72,-4.73 2.2,-6.43 0.48,-1.71 0.61,-2.48 0.4,-2.3 l -12.14,15.35 c -2.19,-9.27 -0.17,-19.23 6.07,-29.89 6.23,-10.65 14.28,-19.14 24.15,-25.46 9.86,-6.32 19.17,-7.64 27.92,-3.97 2.14,0.92 3.94,3.03 5.41,6.33 1.47,3.31 1.66,7.86 0.59,13.65 -1.07,5.8 -4.34,12.94 -9.81,21.43 -3.72,5.56 -7.33,9.25 -10.83,11.09 -3.5,1.84 -6.76,2.41 -9.77,1.71 3.89,-8.71 7.39,-16.21 10.5,-22.51 0.91,-1.93 1,-3.81 0.26,-5.65 -0.75,-1.83 -1.77,-3.65 -3.09,-5.44 -0.43,-0.61 -0.85,-1.25 -1.24,-1.91 -0.62,-0.69 -1.21,-0.85 -1.78,-0.45 -0.56,0.39 -1.15,1.09 -1.77,2.09 -4.37,7.57 -8.72,16.61 -13.06,27.11 -1.96,-2.58 -3.06,-4.86 -3.28,-6.83 0.09,4.03 0.68,7.53 1.78,10.5 -5.91,15.01 -10.37,28.2 -13.39,39.57 -0.7,4.11 1.05,7.02 5.25,8.73 2.58,-5.78 5.51,-12.47 8.79,-20.08 1.71,-3.85 3.44,-7.79 5.19,-11.81 3.1,8.97 6.89,16.47 11.35,22.51 4.46,6.03 8.91,9.17 13.35,9.41 4.44,0.24 8.17,-3.86 11.19,-12.3 -8.97,3.59 -18.94,-4.51 -29.92,-24.28 4.81,1.31 10.06,0.45 15.75,-2.59 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 410.37,212.15 c -0.72,-1.61 -1.8,-3.17 -3.25,-4.69 -0.89,-0.89 -1.61,-1.33 -2.17,-1.31 -0.55,0.02 -0.98,0.34 -1.27,0.97 -2.56,3.74 -4.21,6.57 -4.95,8.48 -0.74,1.9 -0.68,3.71 0.17,5.41 2.44,3.67 4.41,4.99 5.89,3.95 l 5.61,-8.11 c 0.7,-1.52 0.69,-3.09 -0.03,-4.7 z m -0.66,29.03 -0.14,-0.22 -21.84,28.83 c 1,-4.66 2.82,-9.96 5.45,-15.89 2.63,-5.92 5.4,-11.29 8.33,-16.11 0.78,-1.81 0.89,-3.39 0.33,-4.72 -0.55,-1.33 -1.33,-2.57 -2.33,-3.72 -0.15,-0.19 -0.28,-0.35 -0.39,-0.5 -1.52,-2.26 -3.24,-2.06 -5.17,0.61 -3.77,5.37 -6.86,11.21 -9.25,17.53 -2.38,6.31 -4,12.16 -4.86,17.53 -0.26,2.55 0.22,4.8 1.42,6.75 1.2,1.94 2.93,3.51 5.19,4.69 1.67,0.74 3.79,-0.33 6.37,-3.22 2.57,-2.89 5.4,-6.86 8.5,-11.92 0.33,-0.54 0.66,-1.08 0.99,-1.63 2.78,-4.59 5.62,-9.46 8.53,-14.62 0.15,-0.26 0.3,-0.54 0.45,-0.83 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 457.79,206.57 c 0.67,-2.04 0.5,-3.79 -0.5,-5.25 -1,-1.46 -2.2,-2.77 -3.61,-3.92 -1.56,-1.37 -2.96,-0.89 -4.22,1.45 -3.26,6.37 -7.36,15.85 -12.28,28.44 -2.26,-1.85 -5.16,-2.47 -8.7,-1.86 -3.53,0.61 -6.84,2.25 -9.91,4.92 -3.35,3.1 -6.31,6.71 -8.86,10.83 -0.5,0.79 -0.98,1.61 -1.45,2.45 -2.87,5.14 -4.81,10.08 -5.81,14.8 -0.05,0.26 -0.1,0.51 -0.14,0.76 -0.81,4.35 -0.43,7.64 1.14,9.88 1.86,1.85 4.1,3.78 6.73,5.78 4.48,2.63 8.9,0.58 13.28,-6.17 0.14,1.56 0.68,2.97 1.61,4.22 0.48,0.86 1.16,1.64 2.05,2.34 0.22,0.19 0.47,0.37 0.72,0.55 0.15,0.15 0.36,0.28 0.62,0.39 0.25,0.11 0.53,0.15 0.83,0.11 2.66,-0.14 6.09,-3.38 10.28,-9.69 1.98,-2.99 4.02,-6.22 6.12,-9.68 2.33,-3.85 4.74,-7.98 7.21,-12.41 0.15,-0.25 0.32,-0.53 0.5,-0.83 l -1.78,-2.83 -0.71,0.93 -16.29,21.51 c 3.34,-9.4 7.16,-19.54 11.47,-30.42 4.32,-10.87 8.22,-19.63 11.7,-26.3 z m -28.89,42.89 c -3.22,5.11 -6.4,9.32 -9.53,12.64 -3.13,3.31 -5.54,5.27 -7.25,5.86 -1.11,-2 -0.64,-5.55 1.42,-10.67 2.05,-5.11 4.99,-9.98 8.8,-14.61 3.82,-4.63 7.78,-7.24 11.89,-7.83 -1.92,5.07 -3.7,9.94 -5.33,14.61 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 485.29,241.01 c -0.44,0.74 -0.89,1.47 -1.34,2.19 -4.35,7.03 -8.78,12.97 -13.3,17.82 -4.98,5.33 -9.55,8.94 -13.69,10.83 -0.04,-0.11 -0.1,-0.22 -0.17,-0.33 -0.74,-1.3 -1.39,-3.2 -1.95,-5.7 -0.55,-2.5 -0.31,-5.71 0.73,-9.64 0.33,1.3 1.27,2.42 2.83,3.36 1.56,0.95 3.22,1.38 5,1.31 1.96,-0.41 4.12,-1.87 6.47,-4.39 2.35,-2.52 4.3,-5.59 5.84,-9.22 1.53,-3.63 2.09,-7.33 1.66,-11.09 -0.42,-3.76 -2.41,-7.1 -5.97,-10.02 -1.78,-1.23 -4.16,-0.99 -7.14,0.72 -2.98,1.7 -5.98,4.46 -9,8.28 -1.61,2.04 -3.07,4.25 -4.35,6.65 -1.13,2.09 -2.13,4.31 -3.01,6.68 -1.28,2.88 -2.02,5.7 -2.21,8.46 -0.11,1.33 -0.08,2.63 0.07,3.93 0.46,4 1.78,7.41 3.95,10.22 2.16,2.82 4.82,4.56 7.97,5.22 4.22,0.26 8.99,-2.54 14.3,-8.41 4.99,-5.5 9.83,-12.98 14.54,-22.45 0.31,-0.63 0.63,-1.27 0.94,-1.92 z m -21.5,-0.63 c 2.96,-3.99 5.67,-6.53 8.11,-7.64 -0.22,2.59 -1.59,6.18 -4.11,10.77 -2.59,4.34 -4.81,7.38 -6.67,9.14 -1.85,1.76 -3.29,1.99 -4.33,0.7 1.7,-4.67 4.04,-8.99 7,-12.97 z" />
                <path style={{ fill: color, strokeColor: color }} d="m 517.18,240.85 -21.83,29 c 1,-4.67 2.81,-9.98 5.44,-15.95 2.63,-5.96 5.41,-11.37 8.34,-16.22 0.77,-1.81 0.87,-3.4 0.3,-4.75 -0.57,-1.35 -1.36,-2.6 -2.36,-3.75 -0.11,-0.15 -0.22,-0.31 -0.33,-0.5 -0.67,-0.93 -1.37,-1.44 -2.12,-1.56 -0.92,-0.14 -2.32,-0.09 -4.19,0.17 -1.87,0.26 -3.71,0.59 -5.53,1 -1.81,0.41 -3.15,0.71 -4,0.89 -0.33,0.11 -0.52,0.17 -0.55,0.17 -0.04,-0.89 -0.29,-2.09 -0.75,-3.59 -0.47,-1.5 -1.08,-2.82 -1.84,-3.97 -0.76,-1.15 -1.62,-1.65 -2.58,-1.5 -3.3,1.04 -5.15,3.37 -5.56,7 -0.18,2.08 0.04,3.89 0.67,5.45 0.63,1.55 1.98,2.63 4.06,3.22 0.81,0.18 1.68,0.26 2.61,0.22 -0.97,2.6 -1.97,4.94 -3.01,7.02 -0.07,0.15 -0.14,0.29 -0.21,0.43 l 2.78,1.8 0.05,0.03 c 1.63,-3.07 2.98,-6.26 4.05,-9.56 1.75,-0.29 3.47,-0.63 5.17,-1 0.85,-0.14 1.67,-0.29 2.45,-0.44 -2.82,4.92 -5.13,10.04 -6.95,15.33 -1.81,5.3 -3.09,10.23 -3.83,14.78 -0.26,2.56 0.21,4.81 1.41,6.75 1.21,1.95 2.94,3.53 5.2,4.75 1.7,0.74 3.83,-0.34 6.39,-3.25 2.55,-2.9 5.37,-6.9 8.44,-11.97 3.08,-5.07 6.24,-10.52 9.5,-16.34 0.15,-0.29 0.32,-0.59 0.5,-0.88 z" />
            </g>
        </svg>
    )
};

Logo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

Logo.defaultProps = {
    color: "#3f51b5",
};

export default Logo;
