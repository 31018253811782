import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fingerprintProvider = () => {
    let result;

    return {
        getFingerprint: async () => {
            if (! result) {
                const fp = await FingerprintJS.load();
                result = await fp.get();
            }

            // Retrieve the visitor identifier
            const { visitorId } = result;

            return visitorId;
        },
    };
};

export default fingerprintProvider();
