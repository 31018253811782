import React from "react";
import LabelArrayField from "../label/LabelArrayField";
import ProductAvatarField from "./ProductAvatarField";
import ProductPosterField from "./ProductPosterField";
import ProductTitle from "./ProductTitle";
import { BooleanField, Labeled, NumberField, SimpleShowLayout, Show, useTranslate } from "react-admin";
import { CurrencyField, PercentageField } from "../../component/field";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { ShowActions } from "../../component/action";

const useStyles = makeStyles(theme => ({
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const ProductShow = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Show
            actions={<ShowActions {...props} />}
            title={<ProductTitle />}
            {...props}
        >
            <SimpleShowLayout>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ProductAvatarField />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.passengers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.maxPassengers")}>
                            <NumberField source="maxPassengers" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.minPassengers")}>
                            <NumberField source="minPassengers" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.numSkiJets")}>
                            <NumberField source="numSkiJets" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.numPaddles")}>
                            <NumberField source="numPaddles" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.details")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.minDuration")}>
                            <NumberField source="minDuration" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.maxDuration")}>
                            <NumberField source="maxDuration" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.distance")}>
                            <NumberField source="distance" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.conditions")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.onlyIfFavorableWeather")}>
                            <BooleanField source="onlyIfFavorableWeather" looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.instructorRequired")}>
                            <BooleanField source="instructorRequired" looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.refillRequired")}>
                            <BooleanField source="refillRequired" looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.cancellation")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.cancellable")}>
                            <BooleanField source="cancellable" looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.cancellationLimit")}>
                            <NumberField source="cancellationLimit" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.cancellationFee")}>
                            <NumberField source="cancellationFee" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.changes")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.changeable")}>
                            <BooleanField source="changeable" looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.changeLimit")}>
                            <NumberField source="changeLimit" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.extras")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.includesBasicNavigation")}>
                            <BooleanField source={"includesBasicNavigation"} looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.includesLifeJacket")}>
                            <BooleanField source={"includesLifeJacket"} looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.includesLockerRoom")}>
                            <BooleanField source={"includesLockerRoom"} looseValue={false} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.fees")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.residentFee")}>
                            <CurrencyField source="residentFee" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.domesticFee")}>
                            <CurrencyField source="domesticFee" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.foreignFee")}>
                            <CurrencyField source="foreignFee" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.picturesFee")}>
                            <CurrencyField source="picturesFee" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.taxes")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.taxes")}>
                            <PercentageField source="taxes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.portTaxes")}>
                            <CurrencyField source="portTaxes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.additionalTaxes")}>
                            <CurrencyField source="additionalTaxes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" variant="subtitle2">
                            {translate("product.message.vouchers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled label={translate("product.label.allowRedeemVoucher")}>
                            <BooleanField source="allowRedeemVoucher" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" gutterBottom variant="subtitle2">
                            {translate("product.message.posters")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProductPosterField
                            fullWidth
                            label={translate("product.label.posterHiRes")}
                            source="posterHiRes"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProductPosterField
                            fullWidth
                            label={translate("product.label.posterLoRes")}
                            source="posterLoRes"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" gutterBottom variant="subtitle2">
                            {translate("product.message.labels")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelArrayField fullWidth source="labels" />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" gutterBottom variant="subtitle2">
                            {translate("product.message.descriptions")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelArrayField fullWidth source="descriptions" />
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default ProductShow;
