import React from "react";
import ProfileEditToolbar from "./ProfileEditToolbar";
import { DateInput, FileInput, ImageField, SimpleForm, TextInput, required, useTranslate } from "react-admin";
import { Grid, Divider, Typography, makeStyles } from "@material-ui/core";
import { dateOptions } from "../../util/dates";

const useStyles = makeStyles(theme => ({
    divider: {
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
    },
}));

const validate = [required()];

const ProfileEditForm = props => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <SimpleForm
            toolbar={<ProfileEditToolbar />}
            {...props}
        >
            <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("profile.message.avatar")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileInput
                        accept=".jpg,.jpeg,.png,.webp"
                        fullWidth
                        label={translate("profile.label.avatar")}
                        maxSize={5000000}
                        source="avatar"
                    >
                        <ImageField source="url" title="ref" />
                    </FileInput>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("profile.message.details")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("profile.label.firstName")}
                        source="firstName"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        label={translate("profile.label.lastName")}
                        source="lastName"
                        validate={validate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateInput
                        fullWidth
                        label={translate("profile.label.dateOfBirth")}
                        source="dateOfBirth"
                        options={dateOptions}
                    />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default ProfileEditForm;
