import React from "react";
import { SelectInput } from "react-admin";
import { bookingDropCauseChoices } from "./bookingDropCauseChoices";

const BookingDropCauseInput = props => (
    <SelectInput
        choices={bookingDropCauseChoices}
        {...props}
    />
);

export default BookingDropCauseInput;
