import React from "react";
import { SimpleList } from "react-admin";

const ProductSimpleList = props => {
    return (
        <SimpleList
            primaryText={record => record.ref}
            secondaryText={record => `${record.duration} min`}
            tertiaryText={record => `${record.residentFee}/${record.domesticFee}/${record.foreignFee}` }
            {...props}
        />
    );
};

export default ProductSimpleList;
