import React from "react";
import { SimpleList } from "react-admin";

const IdentitySimpleList = props => {
    return (
        <SimpleList
            primaryText={record => record.identity}
            secondaryText={record => record.email}
            tertiaryText={record => record.identity}
            {...props}
        />
    );
};

export default IdentitySimpleList;
