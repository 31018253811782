import React from "react";
import PropTypes from "prop-types";
import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import {useLocale, useTranslate} from "react-admin";
import {dateTimeOptions} from "../../../util/dates";
import {BoxField} from "../../../component/box";
import { getBookingDeadline } from "../bookingUtils";

const BookingCardConditions = ({ record }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const formatter = new Intl.DateTimeFormat(locale, dateTimeOptions);
    const { cancellableUntil, cancellation, changeableUntil, checkInAt, product = {}} = record;
    const { cancellable, cancellationLimit, changeLimit, changeable } = product;
    const checkInDate = new Date(checkInAt);
    const cancellableUntilDate = cancellableUntil ? new Date(cancellableUntil) : getBookingDeadline(checkInDate, cancellationLimit);
    const changeableUntilDate = changeableUntil ? new Date(changeableUntil) : getBookingDeadline(checkInDate, changeLimit);

    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="subtitle2">
                        {translate("booking.message.conditions")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <BoxField label={
                        <Typography color="textSecondary" variant="body2">
                            {translate("booking.message.cancellation")}
                        </Typography>
                    }>
                        {
                            ! cancellable || ! cancellation || ! cancellableUntil ?
                                translate("booking.message.notCancellable") :
                                translate("booking.message.cancellableUntil", {
                                    date: formatter.format(cancellableUntilDate),
                                })
                        }
                    </BoxField>
                    <BoxField label={
                        <Typography color="textSecondary" variant="body2">
                            {translate("booking.message.changes")}
                        </Typography>
                    }>
                        {
                            ! changeable && ! changeableUntil ?
                                translate("booking.message.notChangeable") :
                                translate("booking.message.changeableUntil", {
                                    date: formatter.format(changeableUntilDate),
                                })
                        }
                    </BoxField>
                </Grid>
            </Grid>
        </CardContent>
    );
};

BookingCardConditions.propTypes = {
    classes: PropTypes.object,
    currencyFormatter: PropTypes.object,
    record: PropTypes.object.isRequired,
};

BookingCardConditions.defaultProps = {
    classes: {},
};

export default BookingCardConditions;
